import { Button, Flex, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import DutchieSync from 'components/segments/DutchieSync';
import useProducts from 'hooks/useProducts';
import List from './List';

const Products = () => {
  const { getProductList } = useProducts();
  const navigate = useNavigate();
  return (
    <Flex flexDirection='column'>
      <Flex justifyContent='space-between'>
        <Heading>Products</Heading>
        <Flex alignItems='flex-end' gap='10px'>
          <Button onClick={() => navigate(`/products/report`)}>Report</Button>
          <DutchieSync type='products' label='Product Data' successAction={getProductList} />
        </Flex>
      </Flex>
      <List />
    </Flex>
  );
};

export default Products;
