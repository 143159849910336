import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  getCustomers,
  updateCustomerStatus,
  deleteCustomer as _deleteCustomer
} from 'redux/actions/customers';

const useCustomers = () => {
  const dispatch = useDispatch();

  const customers = useSelector(state => state.customers?.customers, shallowEqual);
  const isRequesting = useSelector(state => state.customers?.isRequesting);
  const lastUpdateError = useSelector(state => state.customers?.lastUpdateError);
  const customerUpdating = useSelector(state => state.customers?.customerUpdating);
  const currentPage = useSelector(state => state.customers?.page, shallowEqual);
  const pages = useSelector(state => state.customers?.pages, shallowEqual);
  const count = useSelector(state => state.customers?.count, shallowEqual);

  const getCustomerList = useCallback(
    (params = {}) => {
      const { page, filter, sort } = params;
      dispatch(getCustomers({ page, filter, sort }));
    },
    [dispatch]
  );

  const updateStatus = useCallback(
    ({ customer, status }) => {
      dispatch(updateCustomerStatus({ customer, status }));
    },
    [dispatch]
  );

  const deleteCustomer = useCallback(
    ({ customer }) => {
      dispatch(_deleteCustomer({ customer }));
    },
    [dispatch]
  );

  return {
    getCustomerList,
    updateStatus,
    deleteCustomer,
    isRequesting,
    lastUpdateError,
    customerUpdating,
    customers,
    currentPage,
    pages,
    count
  };
};

export default useCustomers;
