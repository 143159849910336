import { Button, Flex, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import List from './List';

const SiteContent = () => {
  const navigate = useNavigate();
  return (
    <Flex flexDirection='column'>
      <Flex justifyContent='space-between'>
        <Heading>Site Content Blocks</Heading>
        <Flex alignItems='flex-end' gap='10px'>
          <Button onClick={() => navigate('/site-content/add')}>+ Add New Content Block</Button>
        </Flex>
      </Flex>
      <List />
    </Flex>
  );
};

export default SiteContent;
