import { Button, Flex, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import List from './List';

const News = () => {
  const navigate = useNavigate();
  return (
    <Flex flexDirection='column'>
      <Flex justifyContent='space-between'>
        <Heading>News Articles</Heading>
        <Flex alignItems='flex-end' gap='10px'>
          <Button onClick={() => navigate('/news/add')}>+ Add New Article</Button>
        </Flex>
      </Flex>
      <List />
    </Flex>
  );
};

export default News;
