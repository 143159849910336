import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  getLocations,
  getLocationById,
  updateLocationStatus,
  saveData
  // deleteLocation as _deleteLocation
} from 'redux/actions/locations';

const useLocations = () => {
  const dispatch = useDispatch();

  const locations = useSelector(state => state.locations?.list, shallowEqual);
  const isRequesting = useSelector(state => state.locations?.isRequesting);
  const isUpdating = useSelector(state => state.locations?.isUpdating);
  const lastGetError = useSelector(state => state.locations?.lastGetError);
  const lastUpdateError = useSelector(state => state.locations?.lastUpdateError);
  const currentPage = useSelector(state => state.locations?.page, shallowEqual);
  const pages = useSelector(state => state.locations?.pages, shallowEqual);
  const count = useSelector(state => state.locations?.count, shallowEqual);

  const getLocationList = useCallback(
    (params = {}) => {
      if (!isRequesting) {
        // only show a single page of locations, limit=100
        const { page, limit = 100, filter, sort } = params;
        dispatch(getLocations({ page, limit, filter, sort }));
      }
    },
    [dispatch, isRequesting]
  );

  const getLocation = useCallback(
    id => {
      if (id && !isRequesting) {
        const myLocation = locations?.find(loc => loc._id === id);
        if (!myLocation) {
          dispatch(getLocationById(id));
        }
      }
    },
    [dispatch, isRequesting, locations]
  );

  const updateStatus = useCallback(
    ({ location, status }) => {
      dispatch(updateLocationStatus({ location, status }));
    },
    [dispatch]
  );

  const saveLocation = useCallback(
    ({ data, location }) => {
      dispatch(saveData({ data, location }));
    },
    [dispatch]
  );

  // const deleteLocation = useCallback(
  //   ({ location }) => {
  //     dispatch(_deleteLocation({ location }));
  //   },
  //   [dispatch]
  // );

  return {
    getLocationList,
    getLocation,
    updateStatus,
    saveLocation,
    // deleteLocation,
    isRequesting,
    isUpdating,
    lastGetError,
    lastUpdateError,
    locations,
    currentPage,
    pages,
    count
  };
};

export default useLocations;
