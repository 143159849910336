import React, { useEffect } from 'react';
import {
  Pagination,
  usePagination,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
  PaginationContainer,
  PaginationPageGroup
} from '@ajna/pagination';

const PaginationComponent = props => {
  const { numPages = 1, page = 1, pageChangeHandler } = props;
  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: numPages,
    initialState: { currentPage: page },
    limits: {
      inner: 8,
      outer: 5
    }
  });

  const updatePage = page => {
    setCurrentPage(page);
    pageChangeHandler && pageChangeHandler(page);
  };

  useEffect(() => {
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  }, [page, currentPage, setCurrentPage]);

  //   console.log('Pagination stuff', { numPages, page, currentPage, pagesCount, pages });

  //   <Text>
  //     pages: {pages}, page: {currentPage}
  //   </Text>

  return (
    <Pagination pagesCount={pagesCount} currentPage={currentPage} onPageChange={updatePage}>
      <PaginationContainer align='center' justify='center'>
        <PaginationPrevious mr='1em'>Previous</PaginationPrevious>
        <PaginationPageGroup>
          {pages.map(page => (
            <PaginationPage
              key={`pagination_page_${page}`}
              w={7}
              page={page}
              fontSize='sm'
              _hover={{
                bg: 'green.300'
              }}
              _current={{
                bg: 'green.300',
                fontSize: 'sm',
                w: 7
              }}
            />
          ))}
        </PaginationPageGroup>
        <PaginationNext ml='1em'>Next</PaginationNext>
      </PaginationContainer>
    </Pagination>
  );
};

export default PaginationComponent;
