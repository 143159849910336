export const getQueryFilter = filter => {
  if (
    !filter ||
    (filter instanceof Array && !filter.length) ||
    (typeof filter === 'object' && !Object.keys(filter).length)
  ) {
    return undefined;
  }
  try {
    return JSON.stringify(filter);
  } catch (error) {
    console.error('error getting query filter!', error, filter);
  }
};
