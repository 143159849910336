import React from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, Text, Button } from '@chakra-ui/react';
import useAuth from 'hooks/useAuth';

import { PLC_DARKGREEN_BG, PLC_HIGHLIGHT_GREEN } from 'constants/styles';

const TopRightBox = styled(Box)`
  position: absolute;
  top: 0;
  right: 8px;
  text-align: right;
`;

const NameAndLogout = ({ float = false }) => {
  const { user, logout } = useAuth();
  const Wrapper = float ? TopRightBox : Box;

  return (
    <Wrapper>
      <Text as='span' fontSize='md' mb={0}>
        Hello, {user.firstName}
      </Text>
      <Box>
        <Button
          size='xs'
          onClick={logout}
          my={0}
          backgroundColor={PLC_HIGHLIGHT_GREEN}
          color={PLC_DARKGREEN_BG}
        >
          Logout
        </Button>
      </Box>
    </Wrapper>
  );
};

export default NameAndLogout;
