import axios from 'axios';

import {
  PRODUCTS_LIST_REQUEST,
  PRODUCTS_LIST_RESPONSE,
  PRODUCTS_LIST_ERROR,
  PRODUCT_GET_REQUEST,
  PRODUCT_GET_RESPONSE,
  PRODUCT_GET_ERROR,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_RESPONSE,
  PRODUCT_UPDATE_ERROR
  // PRODUCT_DELETE_REQUEST,
  // PRODUCT_DELETE_RESPONSE,
  // PRODUCT_DELETE_ERROR
} from 'redux/reducers/products';

export const getProducts = ({
  page = 1,
  limit,
  filter,
  sort = { field: 'dutchieData.name', dir: 1 }
}) => {
  return async dispatch => {
    // dispatch, getState
    dispatch({ type: PRODUCTS_LIST_REQUEST });
    try {
      const response = await axios.get('/api/data/list/products', {
        params: { page, limit, filter, sort: JSON.stringify(sort) }
      });
      dispatch({
        type: PRODUCTS_LIST_RESPONSE,
        data: response?.data?.list,
        pages: response?.data?.pages || 0,
        count: response?.data?.count || 0,
        // response,
        page
      });
    } catch (error) {
      dispatch({ type: PRODUCTS_LIST_ERROR, data: error.message });
    }
  };
};

export const getProductById = id => {
  return async dispatch => {
    // dispatch, getState
    dispatch({ type: PRODUCT_GET_REQUEST });
    try {
      const response = await axios.get(`/api/data/get/products/${id}`);
      dispatch({
        type: PRODUCT_GET_RESPONSE,
        data: response?.data
      });
    } catch (error) {
      dispatch({ type: PRODUCT_GET_ERROR, data: error.message });
    }
  };
};

export const updateProductStatus = ({ product, status }) => {
  return async (dispatch, getState) => {
    if (!product || typeof status === 'undefined') {
      console.error('error, missing product or status, cannot update');
      return;
    }
    const products = getState()?.products.list;
    const myProduct = products.find(c => c._id === product);
    dispatch({ type: PRODUCT_UPDATE_REQUEST, product });
    try {
      const response = await axios.post('/api/data/update/products', {
        id: product,
        data: { enabled: status }
      });
      if (response?.data?.result) {
        myProduct.enabled = status;
      }
      dispatch({ type: PRODUCT_UPDATE_RESPONSE, data: myProduct });
    } catch (error) {
      dispatch({ type: PRODUCT_UPDATE_ERROR, data: error });
    }
  };
};

export const saveData = ({ data, product }) => {
  return async (dispatch, getState) => {
    if (!data) {
      console.error('error, missing data, cannot update');
      return;
    }

    if (!product) {
      console.error(
        'error, missing product id for saveData ... add product not supported (driven by Dutchie data at this time)'
      );
      return;
    }

    // const products = getState()?.products.list;
    // const myProduct = products.find(c => c._id === product);
    dispatch({ type: PRODUCT_UPDATE_REQUEST, product });
    try {
      const response = await axios.post('/api/data/update/products', {
        id: product,
        doc: true,
        data
      });
      if (response?.data?.result) {
        dispatch({ type: PRODUCT_UPDATE_RESPONSE, data: response?.data?.doc });
      } else {
        dispatch({ type: PRODUCT_UPDATE_ERROR, data: 'failed to update the product' });
      }
    } catch (error) {
      dispatch({
        type: PRODUCT_UPDATE_ERROR,
        data: error?.response?.data?.message || 'unknown error'
      });
    }
  };
};

// export const deleteProduct = ({ product }) => {
//   return async (dispatch, getState) => {
//     if (!product) {
//       console.error('error, missing product, cannot delete');
//       return;
//     }
//     const products = getState()?.products.list;
//     let myProduct = products.find(c => c._id === product);
//     if (!myProduct) {
//       console.error('error, product not found, cannot delete');
//       return;
//     }
//     console.log('ok try to delete product', product);
//     dispatch({ type: PRODUCT_DELETE_REQUEST, product });
//     try {
//       const response = await axios.post('/data/products/delete', { product });
//       console.log('delete product response', response?.data, myProduct);
//       if (!response?.data?.result) {
//         myProduct = null;
//       }
//       dispatch({ type: PRODUCT_DELETE_RESPONSE, data: myProduct?._id, response });
//     } catch (error) {
//       console.warn('error deleting, dispatch the error', error);
//       dispatch({ type: PRODUCT_DELETE_ERROR, data: error });
//     }
//   };
// };
