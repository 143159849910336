import axios from 'axios';

import {
  CMS_USERS_LIST_REQUEST,
  CMS_USERS_LIST_RESPONSE,
  CMS_USERS_LIST_ERROR,
  CMS_USERS_GET_REQUEST,
  CMS_USERS_GET_RESPONSE,
  CMS_USERS_GET_ERROR,
  CMS_USERS_ADD_REQUEST,
  CMS_USERS_ADD_RESPONSE,
  CMS_USERS_ADD_ERROR,
  CMS_USERS_UPDATE_REQUEST,
  CMS_USERS_UPDATE_RESPONSE,
  CMS_USERS_UPDATE_ERROR
  // CMS_USERS_DELETE_REQUEST,
  // CMS_USERS_DELETE_RESPONSE,
  // CMS_USERS_DELETE_ERROR
} from 'redux/reducers/cmsUsers';

import { getQueryFilter } from 'utils/request';

export const getCmsUsersList = ({ page = 1, limit, filter = {}, sort }) => {
  return async dispatch => {
    // dispatch, getState
    dispatch({ type: CMS_USERS_LIST_REQUEST });
    try {
      const params = { page, limit, filter: getQueryFilter(filter) };
      if (sort) {
        params.sort = JSON.stringify(sort);
      }
      const response = await axios.get('/api/data/list/admins', {
        params
      });
      dispatch({
        type: CMS_USERS_LIST_RESPONSE,
        data: response?.data?.list,
        pages: response?.data?.pages || 0,
        count: response?.data?.count || 0,
        // response,
        page
      });
    } catch (error) {
      dispatch({ type: CMS_USERS_LIST_ERROR, data: error.message });
    }
  };
};

export const getCmsUserById = id => {
  return async dispatch => {
    // dispatch, getState
    dispatch({ type: CMS_USERS_GET_REQUEST });
    try {
      const response = await axios.get(`/api/data/get/admins/${id}`);
      dispatch({
        type: CMS_USERS_GET_RESPONSE,
        data: response?.data
      });
    } catch (error) {
      dispatch({ type: CMS_USERS_GET_ERROR, data: error.message });
    }
  };
};

export const saveData = ({ data, cmsUser }) => {
  return async dispatch => {
    if (!data) {
      console.error('error, missing data, cannot update');
      return;
    }

    const params = {
      doc: true,
      data
    };
    let apiPathAction = 'add',
      requestAction = CMS_USERS_ADD_REQUEST,
      responseAction = CMS_USERS_ADD_RESPONSE,
      errorAction = CMS_USERS_ADD_ERROR;
    if (cmsUser) {
      params.id = cmsUser;
      apiPathAction = 'update';
      requestAction = CMS_USERS_UPDATE_REQUEST;
      responseAction = CMS_USERS_UPDATE_RESPONSE;
      errorAction = CMS_USERS_UPDATE_ERROR;
    }

    dispatch({ type: requestAction, cmsUser });

    if (
      !data.firstName ||
      !data.lastName ||
      !data.email ||
      (!cmsUser && !data.password) ||
      (data.password && data.password !== data.passwordv)
    ) {
      // race condition with this error dispatch??? timeout=1 gives a tick
      setTimeout(() => {
        dispatch({
          type: errorAction,
          data: 'Missing required properties, make sure to fill in the form completely.'
        });
      }, 1);
      return;
    }

    try {
      const response = await axios.post(`/api/data/${apiPathAction}/admins`, params);
      if (response?.data?.result) {
        dispatch({ type: responseAction, data: response?.data?.doc });
      } else {
        dispatch({ type: errorAction, data: 'failed to save the cmsUser' });
      }
    } catch (error) {
      dispatch({
        type: errorAction,
        data: error?.response?.data?.message || 'unknown error'
      });
    }
  };
};

// export const deleteCmsUser = ({ id }) => {
//   return async dispatch => {
//     if (!id) {
//       console.error('error, missing id, cannot delete');
//       return;
//     }
//     console.log('ok try to delete cmsUser', id);
//     dispatch({ type: CMS_USERS_DELETE_REQUEST, id });
//     try {
//       const response = await axios.delete(`/api/data/delete/admins/${id}`);
//       console.log('delete cmsUsers response', response?.data);
//       dispatch({ type: CMS_USERS_DELETE_RESPONSE, id, data: response });
//     } catch (error) {
//       console.warn('error deleting, dispatch the error', error);
//       dispatch({ type: CMS_USERS_DELETE_ERROR, data: error });
//     }
//   };
// };
