import { useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Button,
  Heading,
  Link,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import useLocations from 'hooks/useLocations';
import { sortArrayByProp } from 'utils/sort';

import { TableBox } from 'components/segments/StyledComponents';

const List = () => {
  const listOnLoad = useRef();
  const { locations, isRequesting, getLocationList } = useLocations();

  const navigate = useNavigate();

  useEffect(() => {
    if (!listOnLoad.current) {
      listOnLoad.current = true;
      getLocationList();
    }
  }, [getLocationList, locations]);

  const errorDisplay = useMemo(() => {
    if (!locations?.length) {
      const message =
        !listOnLoad.current || isRequesting ? 'Loading locations...' : 'Sorry, no locations found';
      return <Heading size='lg'>{message}</Heading>;
    }
    return null;
  }, [locations?.length, isRequesting]);

  return (
    <Box>
      {errorDisplay}
      {!errorDisplay && (
        <TableBox my='12px' border='1px solid #000'>
          <Table size='sm'>
            <Thead>
              <Tr>
                <Th>
                  Name{' '}
                  <em
                    style={{ textTransform: 'lowercase', letterSpacing: '0', fontWeight: 'normal' }}
                  >
                    (click to visit live shop)
                  </em>
                </Th>
                <Th>Slug</Th>
                <Th>Phone</Th>
                <Th>City</Th>
                <Th>Enabled</Th>
                <Th>Delivery Only Location?</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {locations
                .sort(sortArrayByProp(l => l.name || l.dutchieData.name.trim()))
                .map((l, idx) => {
                  const data = l.dutchieData;
                  return (
                    <Tr key={idx}>
                      <Td>
                        <Link href={`http://localhost:3000/menu/${data.slug}`} target='_new'>
                          {l.name || l.dutchieData.name}
                        </Link>
                      </Td>
                      <Td>{l.slug || l.dutchieData.slug}</Td>
                      <Td>{l.phone}</Td>
                      <Td>{data.addressObject.city}</Td>
                      <Td style={{ textAlign: 'center' }}>
                        {l.enabled ? (
                          <Text
                            as='strong'
                            p='2px 4px'
                            borderRadius='3px'
                            backgroundColor='green'
                            color='white'
                          >
                            Yes
                          </Text>
                        ) : (
                          <Text
                            as='strong'
                            p='2px 4px'
                            borderRadius='3px'
                            backgroundColor='red'
                            color='white'
                          >
                            No
                          </Text>
                        )}
                      </Td>
                      <Td style={{ textAlign: 'center' }}>
                        {l.hideFromMultiLocation ? (
                          <Text
                            as='strong'
                            p='2px 4px'
                            borderRadius='3px'
                            backgroundColor='green'
                            color='white'
                          >
                            Yes
                          </Text>
                        ) : (
                          <Text
                            as='strong'
                            p='2px 4px'
                            borderRadius='3px'
                            backgroundColor='red'
                            color='white'
                          >
                            No
                          </Text>
                        )}
                      </Td>
                      {/* <Td>{l.enabled ?? 'none'}</Td> */}
                      <Td>
                        <Button size='xs' onClick={() => navigate(`/locations/edit/${l._id}`)}>
                          Edit
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </TableBox>
      )}
    </Box>
  );
};

export default List;
