import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  getContactSubmissions,
  getContactSubmissionById,
  saveData,
  deleteContactSubmission as _deleteContactSubmission
} from 'redux/actions/contactSubmissions';

const useContactSubmission = ({ type }) => {
  const dispatch = useDispatch();

  const list = useSelector(state => state.contactSubmissions?.list[type], shallowEqual);
  const isRequesting = useSelector(state => state.contactSubmissions?.isRequesting);
  const isUpdating = useSelector(state => state.contactSubmissions?.isUpdating);
  const lastGetError = useSelector(state => state.contactSubmissions?.lastGetError);
  const lastUpdateError = useSelector(state => state.contactSubmissions?.lastUpdateError);
  const currentPage = useSelector(state => state.contactSubmissions?.page[type], shallowEqual);
  const pages = useSelector(state => state.contactSubmissions?.pages[type], shallowEqual);
  const count = useSelector(state => state.contactSubmissions?.count[type], shallowEqual);

  const getContactSubmissionList = useCallback(
    (params = {}) => {
      if (!isRequesting) {
        const { page, limit = 25, filter = {}, sort } = params;
        filter.type = type;
        dispatch(getContactSubmissions({ page, limit, filter, sort }));
      }
    },
    [dispatch, isRequesting, type]
  );

  const getContactSubmission = useCallback(
    id => {
      if (id && !isRequesting) {
        const myContactSubmission = list?.find(art => art._id === id);
        if (!myContactSubmission) {
          dispatch(getContactSubmissionById(id));
        }
      }
    },
    [dispatch, isRequesting, list]
  );

  const saveContactSubmission = useCallback(
    ({ data, contactSubmission }) => {
      data.type = type;
      dispatch(saveData({ data, contactSubmission }));
    },
    [dispatch, type]
  );

  const deleteContactSubmission = useCallback(
    ({ contactSubmission }) => {
      dispatch(_deleteContactSubmission({ contactSubmission, type }));
    },
    [dispatch, type]
  );

  return {
    getContactSubmissionList,
    getContactSubmission,
    saveContactSubmission,
    deleteContactSubmission,
    isRequesting,
    isUpdating,
    lastGetError,
    lastUpdateError,
    list,
    currentPage,
    pages,
    count
  };
};

export const useGeneralContactSubmissions = () => {
  return useContactSubmission({ type: 'general' });
};

export const useCareerContactSubmissions = () => {
  return useContactSubmission({ type: 'career' });
};

export const useEducationContactSubmissions = () => {
  return useContactSubmission({ type: 'education' });
};
