export const CONTACTSUBMISSIONS_LIST_REQUEST = 'CONTACTSUBMISSIONS_LIST_REQUEST';
export const CONTACTSUBMISSIONS_LIST_RESPONSE = 'CONTACTSUBMISSIONS_LIST_RESPONSE';
export const CONTACTSUBMISSIONS_LIST_ERROR = 'CONTACTSUBMISSIONS_LIST_ERROR';
export const CONTACTSUBMISSIONS_LIST_RESET = 'CONTACTSUBMISSIONS_LIST_RESET';
export const CONTACTSUBMISSION_GET_REQUEST = 'CONTACTSUBMISSION_GET_REQUEST';
export const CONTACTSUBMISSION_GET_RESPONSE = 'CONTACTSUBMISSION_GET_RESPONSE';
export const CONTACTSUBMISSION_GET_ERROR = 'CONTACTSUBMISSION_GET_ERROR';
export const CONTACTSUBMISSION_UPDATE_REQUEST = 'CONTACTSUBMISSION_UPDATE_REQUEST';
export const CONTACTSUBMISSION_UPDATE_RESPONSE = 'CONTACTSUBMISSION_UPDATE_RESPONSE';
export const CONTACTSUBMISSION_UPDATE_ERROR = 'CONTACTSUBMISSION_UPDATE_ERROR';
export const CONTACTSUBMISSION_DELETE_REQUEST = 'CONTACTSUBMISSION_DELETE_REQUEST';
export const CONTACTSUBMISSION_DELETE_RESPONSE = 'CONTACTSUBMISSION_DELETE_RESPONSE';
export const CONTACTSUBMISSION_DELETE_ERROR = 'CONTACTSUBMISSION_DELETE_ERROR';

const initialState = {
  isUpdating: false,
  isRequesting: false,
  lastListResponse: null,
  lastListError: null,
  lastGetResponse: null,
  lastGetError: null,
  lastUpdateResponse: null,
  lastUpdateError: null,
  page: {
    general: 1,
    career: 1,
    education: 1
  },
  count: {
    general: 0,
    career: 0,
    education: 0
  },
  pages: {
    general: 0,
    career: 0,
    education: 0
  },
  list: {
    news: [],
    blog: []
  }
};

const contactSubmissions = (state = initialState, action) => {
  let list = { ...state.list };
  let page = { ...state.page };
  let pages = { ...state.pages };
  let count = { ...state.count };
  switch (action?.type) {
    case CONTACTSUBMISSIONS_LIST_REQUEST:
      return {
        ...state,
        isRequesting: true,
        lastListResponse: null,
        lastListError: null
      };
    case CONTACTSUBMISSIONS_LIST_RESPONSE: {
      const { contactSubmissionType } = action;
      if (contactSubmissionType) {
        list[contactSubmissionType] = action?.data;
        page[contactSubmissionType] = action?.page || 1;
        pages[contactSubmissionType] = action?.pages || 0;
        count[contactSubmissionType] = action?.count || 0;
        return {
          ...state,
          list,
          page,
          pages,
          count,
          isRequesting: false,
          lastListResponse: action?.response,
          lastListError: null
        };
      }
      return state;
    }
    case CONTACTSUBMISSIONS_LIST_ERROR: {
      const { contactSubmissionType } = action;
      if (contactSubmissionType) {
        list[contactSubmissionType] = [];
        page[contactSubmissionType] = 1;
        pages[contactSubmissionType] = 0;
        count[contactSubmissionType] = 0;
        return {
          ...state,
          list: [],
          page,
          pages,
          count,
          isRequesting: false,
          lastListResponse: null,
          lastListError: action?.data
        };
      }
      return state;
    }
    case CONTACTSUBMISSIONS_LIST_RESET: {
      const { contactSubmissionType } = action;
      if (contactSubmissionType) {
        list[contactSubmissionType] = [];
        page[contactSubmissionType] = 1;
        pages[contactSubmissionType] = 0;
        count[contactSubmissionType] = 0;
        return {
          ...state,
          isRequesting: false,
          lastListResponse: null,
          lastListError: null,
          list,
          page,
          pages,
          count
        };
      }
      return state;
    }
    case CONTACTSUBMISSION_GET_REQUEST:
      return {
        ...state,
        isRequesting: true,
        lastGetResponse: null,
        lastGetError: null
      };
    case CONTACTSUBMISSION_GET_RESPONSE: {
      const contactSubmission = action?.data;
      const { type } = contactSubmission;
      const existsIndex = list[type]?.findIndex(loc => loc._id === contactSubmission.id);
      if (!list[type]) {
        list[type] = [];
      }
      if (existsIndex > -1) {
        list[type][existsIndex] = contactSubmission;
      } else {
        list[type].push(contactSubmission);
        count[type] += 1;
      }
      return {
        ...state,
        list,
        count,
        isRequesting: false,
        lastGetResponse: contactSubmission,
        lastGetError: null
      };
    }
    case CONTACTSUBMISSION_GET_ERROR:
      return {
        ...state,
        isRequesting: false,
        lastGetResponse: null,
        lastGetError: action?.data
      };

    case CONTACTSUBMISSION_UPDATE_REQUEST:
    case CONTACTSUBMISSION_DELETE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        lastUpdateResponse: null,
        lastUpdateError: null
      };
    case CONTACTSUBMISSION_UPDATE_RESPONSE:
      const updatedContactSubmission = action?.data;
      if (updatedContactSubmission) {
        const { type } = updatedContactSubmission;
        list[type] = list[type].map(contactSubmission => {
          if (contactSubmission._id === updatedContactSubmission._id) {
            return updatedContactSubmission;
          }
          return contactSubmission;
        });
      }
      return {
        ...state,
        list,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case CONTACTSUBMISSION_DELETE_RESPONSE:
      const { id: deletedContactSubmission, contactSubmissionType } = action;
      if (deletedContactSubmission) {
        list[contactSubmissionType] = list[contactSubmissionType].filter(
          contactSubmission => contactSubmission._id !== deletedContactSubmission
        );
        count[contactSubmissionType] -= 1;
        if (count[contactSubmissionType] < 0) {
          count[contactSubmissionType] = 0;
        }
      } else {
        console.error(
          'delete response received, but no contactSubmission in action.data??',
          action
        );
      }
      return {
        ...state,
        list,
        count,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case CONTACTSUBMISSION_UPDATE_ERROR:
    case CONTACTSUBMISSION_DELETE_ERROR:
      return {
        ...state,
        isUpdating: false,
        lastUpdateResponse: null,
        lastUpdateError: action?.data
      };
    default:
      return state;
  }
};

export default contactSubmissions;
