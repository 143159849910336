import { useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  Select,
  ButtonGroup,
  Spinner
} from '@chakra-ui/react';

import useEditor from 'hooks/useEditor';
import useProductFilters from 'hooks/useProductFilters';
import SaveCancelDialogue from 'components/segments/CancelDialogue';
import FileUploadSingleImage from 'components/segments/FileUploadSingleImage';

const getInitialData = () => {
  return {
    // useDefaultHours: true
  };
};

const productFilterTypes = [
  ['Category', 'category'],
  ['Sub-Category', 'subCategory'],
  ['Effect', 'effect'],
  ['Strain Type', 'strainType'],
  ['Feature', 'productFeature']
];

const getTypeNameFromValue = value => {
  const ftype = productFilterTypes.find(t => t[1] === value);
  return ftype?.[0] || value;
};

const Editor = () => {
  const { id } = useParams();
  const {
    productFilters,
    isRequesting,
    isUpdating,
    lastGetError,
    lastUpdateError,
    getProductFilterById,
    saveProductFilter
  } = useProductFilters();
  const navigate = useNavigate();
  const hasSetData = useRef();
  const isGettingData = useRef();

  const {
    data,
    changesDetected,
    handleUpdateEditorData,
    handleUpdateEditorDataDirect,
    handleCheckboxClick,
    handleFileUpload,
    setFormData,
    handleSave,
    handleStartCancel
  } = useEditor({
    initialData: getInitialData(),
    isUpdating,
    lastUpdateError,
    saveFunc: data => saveProductFilter({ data, productFilter: id }),
    saveLabel: 'Product Filter',
    isEditing: !!id
  });

  useEffect(() => {
    if (id && !hasSetData.current && !isRequesting) {
      const editNews = productFilters?.find(l => l._id === id);
      if (editNews) {
        hasSetData.current = true;
        isGettingData.current = false;
        console.log('setting form data to:', editNews);
        setFormData(editNews);
      } else {
        if (!lastGetError && !isGettingData.current) {
          isGettingData.current = true;
          getProductFilterById(id);
        }
      }
    }
  }, [id, setFormData, isRequesting, lastGetError, productFilters, getProductFilterById]);

  if (isRequesting) {
    return (
      <Box>
        <Heading mb='20px' size='lg'>
          Loading, one moment please...
        </Heading>
        <Spinner size='xl' />
      </Box>
    );
  }

  const mainPageOK = data?.type === 'category' || data?.type === 'subCategory';

  return (
    <Box maxW='800px'>
      <Heading mb='20px' size='lg'>
        {id ? `Editing filter: ${data.name}` : 'Add a new filter'}
      </Heading>

      <Flex flex='1' gap='20px'>
        <Box flex='0.75' align='flex-start'>
          <FormControl>
            <FormLabel mb='0' fontSize='sm'>
              Name
            </FormLabel>
            <Input
              type='text'
              name='name'
              value={data?.name || ''}
              onChange={handleUpdateEditorData}
              size='sm'
              placeholder='Filter name'
            />
          </FormControl>
          <FormControl>
            <FormLabel mb='0' fontSize='sm'>
              Enabled?
            </FormLabel>
            <Checkbox
              name='enabled'
              isChecked={!!data?.enabled}
              value='true'
              onChange={handleCheckboxClick}
            >
              Yes
            </Checkbox>
          </FormControl>
          {data?.isDutchieFilter ? (
            <Text fontSize='sm'>
              <Text as='strong'>Value:</Text> {data?.value || 'no value set from dutchie'}
            </Text>
          ) : (
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Value
              </FormLabel>
              <Input
                type='text'
                name='value'
                value={data?.value || ''}
                onChange={handleUpdateEditorData}
                size='sm'
                placeholder='Filter Value'
              />
            </FormControl>
          )}
          {data?.isDutchieFilter ? (
            <Text fontSize='sm'>
              <Text as='strong'>Type:</Text> {getTypeNameFromValue(data.type)}
            </Text>
          ) : (
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Type
              </FormLabel>
              <Select
                name='type'
                placeholder='choose'
                onChange={handleUpdateEditorData}
                value={data?.type || ''}
                size='sm'
              >
                {productFilterTypes.map((type, idx) => {
                  return (
                    <option key={idx} value={type[1]}>
                      {type[0]}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          )}
          {mainPageOK ? (
            <Box mt='12px'>
              <FormControl>
                <FormLabel mb='0' fontSize='sm'>
                  Use in homepage list?
                </FormLabel>
                <Checkbox
                  name='isMainCategory'
                  isChecked={!!data?.isMainCategory}
                  value='true'
                  onChange={handleCheckboxClick}
                >
                  Yes
                </Checkbox>
              </FormControl>
              {data?.isMainCategory ? (
                <FormControl>
                  <FormLabel mb='0' fontSize='sm'>
                    Homepage Sort Order
                  </FormLabel>
                  <Input
                    type='text'
                    name='sortOrder'
                    value={data?.sortOrder || ''}
                    onChange={handleUpdateEditorData}
                    size='sm'
                    placeholder='Set to number 1-100'
                  />
                </FormControl>
              ) : null}
            </Box>
          ) : null}
          <Box mt='40px'>
            <ButtonGroup>
              <Button onClick={handleSave} disabled={!changesDetected}>
                Save
              </Button>
              <SaveCancelDialogue
                handleSave={handleSave}
                handleStartCancel={handleStartCancel}
                handleDiscard={() => navigate(-1)}
                cancelLabel='Back'
              />
            </ButtonGroup>
          </Box>
        </Box>
        <Box minW='40%'>
          {mainPageOK && data?.isMainCategory ? (
            <>
              <Heading as='h2' size='md' mt='12px'>
                Main Category Icon:
              </Heading>
              <FileUploadSingleImage
                data={data}
                handleUpdateEditorDataDirect={handleUpdateEditorDataDirect}
                handleFileUpload={handleFileUpload}
                maxPreviewWidth='300px'
                promptLabel='Select category icon'
                selectedFileLabel='category icon'
                actionLabel='Select image'
              />

              <FormControl>
                <Heading as='h2' size='md' mt='12px'>
                  External URL link:
                </Heading>
                <Input
                  type='text'
                  name='externalUrl'
                  value={data?.externalUrl || ''}
                  onChange={handleUpdateEditorData}
                  size='sm'
                  placeholder='External Url (optional)'
                />
              </FormControl>
            </>
          ) : null}
        </Box>
      </Flex>
    </Box>
  );
};

export default Editor;
