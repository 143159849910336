import { useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
  useGeneralContactSubmissions,
  useCareerContactSubmissions,
  useEducationContactSubmissions
} from 'hooks/useContactSubmissions';
import { DATETIME_FORMAT_STRING } from 'constants';

import PaginationComponent from 'components/segments/Pagination';
import ErrorDialogue from 'components/segments/ErrorDialogue';
import DeleteDialogue from 'components/segments/DeleteDialogue';
import { TableBox } from 'components/segments/StyledComponents';

const useHook = type => {
  // eslint-disable-next-line default-case
  switch (type) {
    case 'general':
      return useGeneralContactSubmissions;
    case 'career':
      return useCareerContactSubmissions;
    case 'education':
      return useEducationContactSubmissions;
  }
  throw new Error('unsupported contact submission type!');
};

// TODO: make good status labels
const statusLabel = status => {
  return status;
};

const List = ({ type }) => {
  const listOnLoad = useRef();
  const contactSubHook = useHook(type);
  const [lastType, setLastType] = useState('');

  const {
    list,
    currentPage,
    pages,
    // count,
    isRequesting,
    lastUpdateError,
    getContactSubmissionList,
    // updateStatus,
    deleteContactSubmission
  } = contactSubHook();

  const navigate = useNavigate();

  useEffect(() => {
    if (!listOnLoad.current || lastType !== type) {
      listOnLoad.current = true;
      setLastType(type);
      getContactSubmissionList({ page: currentPage || 1, sort: { field: 'created', dir: -1 } });
    }
  }, [getContactSubmissionList, currentPage, lastType, type]);

  const errorDisplay = useMemo(() => {
    if (!list?.length) {
      const message =
        !listOnLoad.current || isRequesting
          ? 'Loading submissions...'
          : `Sorry, no ${type} submissions found`;
      return <Heading size='lg'>{message}</Heading>;
    }
    return null;
  }, [list?.length, isRequesting, type]);

  const pageChangeHandler = page => {
    getContactSubmissionList({ page, sort: { field: 'created', dir: -1 } });
  };

  const handleDelete = contactSubmission => {
    if (contactSubmission) {
      deleteContactSubmission({ contactSubmission });
    }
  };

  const shortenMessage = msg => {
    if (msg?.length > 120) {
      return `${msg.substring(0, 120)}...`;
    }
    return msg;
  };

  return (
    <Box>
      {errorDisplay}
      {!errorDisplay && (
        <TableBox my='12px' border='1px solid #000'>
          <Table size='sm'>
            <Thead>
              <Tr>
                <Th>Created</Th>
                <Th>Updated</Th>
                <Th>From</Th>
                <Th>Email</Th>
                <Th>Phone</Th>
                <Th>Message</Th>
                <Th>Status</Th>
                <Th>Activity Count</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {list.map((l, idx) => {
                return (
                  <Tr key={idx}>
                    <Td vAlign='top'>{dayjs(l.created).format(DATETIME_FORMAT_STRING)}</Td>
                    <Td vAlign='top'>
                      {l.updated
                        ? dayjs(l.updated).format(DATETIME_FORMAT_STRING)
                        : 'not updated yet'}
                    </Td>
                    <Td vAlign='top'>{`${l.firstName} ${l.lastName}`}</Td>
                    <Td vAlign='top'>{l.email}</Td>
                    <Td vAlign='top'>{l.phone}</Td>
                    <Td vAlign='top'>{shortenMessage(l.message)}</Td>
                    <Td vAlign='top'>{statusLabel(l.status)}</Td>
                    <Td vAlign='top'>
                      {l.events?.length ? `${l.events.length} events` : 'no activity yet'}
                    </Td>
                    <Td vAlign='top'>
                      <ButtonGroup spacing='3' size='xs'>
                        {/* <Button onClick={() => navigate(`/contact/${type}/edit/${l._id}`)}>Edit</Button> */}
                        <Button onClick={() => navigate(`/contact/${type}/${l._id}`)}>
                          View / Manage
                        </Button>
                        <DeleteDialogue
                          label='Delete'
                          deleteConfirmLabel={`Delete ${type} Submission`}
                          handleDelete={() => handleDelete(l._id)}
                        />
                      </ButtonGroup>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableBox>
      )}
      <ErrorDialogue error={lastUpdateError} />
      {pages > 1 && (
        <PaginationComponent
          numPages={pages}
          page={currentPage}
          pageChangeHandler={pageChangeHandler}
        />
      )}
    </Box>
  );
};

export default List;
