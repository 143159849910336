import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  getArticles,
  getArticleById,
  saveData,
  deleteArticle as _deleteArticle
} from 'redux/actions/articles';

const useBlog = () => {
  const dispatch = useDispatch();

  const blog = useSelector(state => state.articles?.list.blog, shallowEqual);
  const isRequesting = useSelector(state => state.articles?.isRequesting);
  const isUpdating = useSelector(state => state.articles?.isUpdating);
  const lastGetError = useSelector(state => state.articles?.lastGetError);
  const lastUpdateError = useSelector(state => state.articles?.lastUpdateError);
  const currentPage = useSelector(state => state.articles?.page.blog, shallowEqual);
  const pages = useSelector(state => state.articles?.pages.blog, shallowEqual);
  const count = useSelector(state => state.articles?.count.blog, shallowEqual);

  const getBlogList = useCallback(
    (params = {}) => {
      if (!isRequesting) {
        const { page, limit = 25, filter = {}, sort } = params;
        filter.type = 'blog';
        dispatch(getArticles({ page, limit, filter, sort }));
      }
    },
    [dispatch, isRequesting]
  );

  const getBlog = useCallback(
    id => {
      if (id && !isRequesting) {
        const myArticle = blog?.find(art => art._id === id);
        if (!myArticle) {
          dispatch(getArticleById(id));
        }
      }
    },
    [dispatch, isRequesting, blog]
  );

  const saveBlog = useCallback(
    ({ data, article }) => {
      data.type = 'blog';
      dispatch(saveData({ data, article }));
    },
    [dispatch]
  );

  const deleteBlog = useCallback(
    ({ article }) => {
      dispatch(_deleteArticle({ article, type: 'blog' }));
    },
    [dispatch]
  );

  return {
    getBlogList,
    getBlog,
    saveBlog,
    deleteBlog,
    isRequesting,
    isUpdating,
    lastGetError,
    lastUpdateError,
    blog,
    currentPage,
    pages,
    count
  };
};

export default useBlog;
