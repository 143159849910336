import React from 'react';
import { toast, Toaster, ToastBar } from 'react-hot-toast';
import { Box, Flex, Heading } from '@chakra-ui/react';

import AppRoutes from 'components/routing/AppRoutes';
import MainNav from 'components/routing/MainNav';
import UserAuth from 'components/user/UserAuth';
import NameAndLogout from 'components/user/NameAndLogout';

import 'styles/ckeditor.css';

import { PLC_DARKGREEN_BG, PLC_HIGHLIGHT_GREEN, PLC_MID_GREEN } from 'constants/styles';

function App() {
  return (
    <Box fontSize='xl'>
      <Toaster
        position='top-center'
        reverseOrder={true}
        toastOptions={{ style: { border: `2px solid ${PLC_MID_GREEN}` } }}
      >
        {t => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <Flex alignItems='flex-start'>
                <Box mt={2}>{icon}</Box>
                {message}
                {t.type !== 'loading' && (
                  <Box role='button' color='#aaa' onClick={() => toast.dismiss(t.id)}>
                    x
                  </Box>
                )}
              </Flex>
            )}
          </ToastBar>
        )}
      </Toaster>
      <Flex minH='100vh' gap={3}>
        {/*<ColorModeSwitcher justifySelf='flex-end' />*/}
        <UserAuth unauthorized='signin'>
          <Box
            maxW='180px'
            backgroundColor={PLC_DARKGREEN_BG}
            color='white'
            boxShadow='4px 0 4px #00000044'
            px={2}
          >
            <Heading
              size='med'
              fontWeight='normal'
              lineHeight='1'
              mt={1}
              color={PLC_HIGHLIGHT_GREEN}
            >
              Plantlife Cannabis Admin Portal
            </Heading>
            <Box
              my={2}
              py={2}
              borderBottom={`1px solid ${PLC_MID_GREEN}`}
              borderTop={`1px solid ${PLC_MID_GREEN}`}
            >
              <NameAndLogout float={false} />
            </Box>
            <MainNav />
          </Box>
          <Box flex='1' p={5}>
            <AppRoutes />
          </Box>
        </UserAuth>
      </Flex>
    </Box>
  );
}

export default App;
