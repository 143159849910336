import { Search2Icon } from '@chakra-ui/icons';
import { Box, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useState } from 'react';

import useProductSearch from 'hooks/useProductSearch';
import { debounce } from 'utils/debounce';

import SearchResults from './SearchResults';

const SearchWidget = ({ selectProduct, setSearchTerm, maxW = '500px' }) => {
  const [searchVal, setSearchVal] = useState('');

  const {
    products,
    isRequesting,
    // lastGetError,
    count,
    doProductSearch,
    clearSearchList
  } = useProductSearch();

  const debouncedSearch = debounce(searchVal => {
    if (searchVal?.length > 2) {
      if (selectProduct) {
        doProductSearch(searchVal);
      }
    }
    if (setSearchTerm) {
      setSearchTerm(searchVal);
    }
  }, 500);

  const handleSearchChange = e => {
    const {
      target: { value }
    } = e;
    setSearchVal(value);
    debouncedSearch(value);
  };

  const clearSearch = () => {
    setSearchVal('');
    clearSearchList();
  };

  return (
    <Box position='relative' maxW={maxW}>
      <InputGroup>
        <Input
          placeholder='start typing to search by name'
          borderRadius='8px'
          backgroundColor='white'
          variant='outline'
          color='gray.800'
          boxShadow='0 8px 12px rgba(0,0,0,0.25)'
          _placeholder={{
            color: 'gray.600',
            fontSize: '11px'
          }}
          _focus={{
            border: '0',
            outline: '0',
            borderColor: 'inherit'
          }}
          // width='260px'
          size='sm'
          value={searchVal}
          onChange={handleSearchChange}
        />
        <InputRightElement mt='-4px'>
          <Search2Icon width='18px' height='18px' fill='green' />
        </InputRightElement>
      </InputGroup>
      <SearchResults
        top='36px'
        left='0'
        width='100%'
        showResults={count > 0}
        searching={isRequesting}
        products={products}
        numResults={count}
        hideResults={clearSearch}
        selectProduct={selectProduct}
      />
    </Box>
  );
};

export default SearchWidget;
