import { useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import useNews from 'hooks/useNews';

import PaginationComponent from 'components/segments/Pagination';
import ErrorDialogue from 'components/segments/ErrorDialogue';
import DeleteDialogue from 'components/segments/DeleteDialogue';
import { TableBox } from 'components/segments/StyledComponents';

const List = () => {
  const listOnLoad = useRef();
  const {
    news,
    currentPage,
    pages,
    // count,
    isRequesting,
    lastUpdateError,
    getNewsList,
    // updateStatus,
    deleteNews
  } = useNews();

  const navigate = useNavigate();

  useEffect(() => {
    if (!listOnLoad.current) {
      listOnLoad.current = true;
      getNewsList({ page: currentPage || 1, sort: { field: 'created', dir: -1 } });
    }
  }, [currentPage, getNewsList, news]);

  const errorDisplay = useMemo(() => {
    if (!news?.length) {
      const message =
        !listOnLoad.current || isRequesting ? 'Loading news...' : 'Sorry, no news articles found';
      return <Heading size='lg'>{message}</Heading>;
    }
    return null;
  }, [news?.length, isRequesting]);

  const pageChangeHandler = page => {
    getNewsList({ page, sort: { field: 'created', dir: -1 } });
  };

  const handleDelete = article => {
    if (article) {
      deleteNews({ article });
    }
  };

  return (
    <Box>
      {errorDisplay}
      {!errorDisplay && (
        <TableBox my='12px' border='1px solid #000'>
          <Table size='sm'>
            <Thead>
              <Tr>
                <Th>Title</Th>
                <Th>Created</Th>
                <Th>Published</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {news.map((l, idx) => {
                return (
                  <Tr key={idx}>
                    <Td>{l.title}</Td>
                    <Td>{l.created}</Td>
                    <Td>{l.published}</Td>
                    <Td>
                      <ButtonGroup spacing='3' size='xs'>
                        <Button onClick={() => navigate(`/news/edit/${l._id}`)}>Edit</Button>
                        <Button onClick={() => navigate(`/news/${l._id}`)}>View</Button>
                        <DeleteDialogue
                          label='Delete'
                          deleteConfirmLabel='Delete News Article'
                          handleDelete={() => handleDelete(l._id)}
                        />
                      </ButtonGroup>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableBox>
      )}
      <ErrorDialogue error={lastUpdateError} />
      {pages > 1 && (
        <PaginationComponent
          numPages={pages}
          page={currentPage}
          pageChangeHandler={pageChangeHandler}
        />
      )}
    </Box>
  );
};

export default List;
