import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Flex, Spinner, Text } from '@chakra-ui/react';

const RESET_FORM_DATA = 'RESET_FORM_DATA';
const SET_FORM_DATA = 'SET_FORM_DATA';
const UPDATE_EDITOR_FIELD = 'UPDATE_EDITOR_FIELD';
const UPDATE_EDITOR_FIELDS = 'UPDATE_EDITOR_FIELDS';

const reducer = (state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case UPDATE_EDITOR_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.field]: action.payload.value
        }
      };
    case UPDATE_EDITOR_FIELDS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      };
    case RESET_FORM_DATA:
      return {
        ...state,
        data: {}
      };
    case SET_FORM_DATA:
      return {
        ...state,
        data: action.payload
      };
  }
};

const initialState = {
  data: {}
};

const useEditor = (params = {}) => {
  const navigate = useNavigate();
  const {
    initialData = {},
    lastUpdateError,
    isUpdating,
    saveFunc,
    saveLabel = 'Item',
    noRedirectOnSave = false
    // isEditing = false
  } = params;
  const [editorState, dispatch] = useReducer(reducer, { ...initialState, data: initialData });
  const [changesDetected, setChangesDetected] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);
  const [saveToastId, setSaveToastId] = useState();
  const richTextUpdateFirstChange = useRef(false);

  const handleUpdateEditorData = e => {
    const { name: field, value } = e.target;
    dispatch({ type: UPDATE_EDITOR_FIELD, payload: { field, value } });
    setChangesDetected(true);
  };

  const handleUpdateEditorDataDirect = data => {
    dispatch({ type: UPDATE_EDITOR_FIELDS, payload: data });
    setChangesDetected(true);
  };

  const handleRichContentBlockChange = (name, data, event) => {
    dispatch({ type: UPDATE_EDITOR_FIELD, payload: { field: name, value: data } });
    // suppress false positive on rich text ckeditor init
    if (!richTextUpdateFirstChange.current) {
      richTextUpdateFirstChange.current = true;
      return;
    }
    setChangesDetected(true);
  };

  // toggle the checkbox value:
  const handleCheckboxClick = e => {
    const { name: field } = e.target;
    dispatch({ type: UPDATE_EDITOR_FIELD, payload: { field, value: !editorState.data[field] } });
    setChangesDetected(true);
  };

  const handleFileUpload = (name, data, error) => {
    if (name && data) {
      // dispatch(updateCareerInfo(name, data));
      dispatch({ type: UPDATE_EDITOR_FIELD, payload: { field: name, value: data } });
      setChangesDetected(true);
    } else {
      // return dispatch(
      //   careerError(error || 'Sorry, there was an error. Check your selected file.')
      // );
      toast.error(error || 'Sorry, there was an error. Check your selected file.', {
        duration: 5000,
        style: { fontSize: '12px', color: 'red' }
      });
    }
  };

  const resetFormData = () => dispatch({ type: RESET_FORM_DATA });

  const setFormData = data => dispatch({ type: SET_FORM_DATA, payload: data });

  const handleSave = () => {
    const toastId = toast(
      <Flex alignItems='center' gap='12px'>
        <Spinner size='xs' />
        <Text>Saving... one moment please</Text>
      </Flex>,
      { duration: 5000 }
    );
    // const toastId = toast('Saving... one moment please', { icon: '⏳' });
    setSaveToastId(toastId);
    saveFunc(editorState.data);
  };

  useEffect(() => {
    // console.log('running update USE E', { isUpdating, isSavingData, saveToastId, lastUpdateError });
    if (isUpdating && !isSavingData) {
      setIsSavingData(true);
    } else if (isSavingData) {
      if (saveToastId) {
        toast.dismiss(saveToastId);
        setSaveToastId();
      }
      if (!isUpdating) {
        if (lastUpdateError) {
          toast.error(`Error saving data: ${lastUpdateError}`, {
            duration: 5000,
            style: { fontSize: '12px', color: 'red' }
          });
        } else {
          // save success
          setChangesDetected(false);
          toast.success(`${saveLabel} updated`, { duration: 3000 });
          !noRedirectOnSave && navigate(-1);
        }
        setIsSavingData(false);
      }
      // } else if (lastUpdateError) {
      //   toast.dismiss(saveToastId);
      //   setSaveToastId();
      //   toast.error(`Error saving data: ${lastUpdateError}`);
      //   setIsSavingData(false);
    }
  }, [
    isSavingData,
    isUpdating,
    lastUpdateError,
    navigate,
    noRedirectOnSave,
    saveLabel,
    saveToastId,
    setChangesDetected
  ]);

  const handleStartCancel = useCallback(
    openDialogueFunc => {
      if (changesDetected) {
        openDialogueFunc();
      } else {
        navigate(-1);
      }
    },
    [navigate, changesDetected]
  );

  // ** tags handling

  const [currentTag, setCurrentTag] = useState('');

  const handleAddEditorTag = () => {
    if (currentTag) {
      const tags = [...(editorState.data.tags || [])];
      tags.push(currentTag);
      dispatch({ type: UPDATE_EDITOR_FIELD, payload: { field: 'tags', value: tags } });
      setChangesDetected(true);
      setCurrentTag('');
    }
  };

  const handleRemoveEditorTag = value => {
    const tags = [...(editorState.data.tags || [])];
    dispatch({
      type: UPDATE_EDITOR_FIELD,
      payload: { field: 'tags', value: tags.filter(tag => tag !== value) }
    });
    setChangesDetected(true);
  };

  const handleUpdateEditorTag = e => {
    const { value } = e.target;
    setCurrentTag(value);
  };

  return {
    data: editorState.data,
    changesDetected,

    handleUpdateEditorData,
    handleUpdateEditorDataDirect,
    handleCheckboxClick,
    handleFileUpload,

    resetFormData,
    setFormData,
    handleSave,
    handleStartCancel,

    handleRichContentBlockChange,

    currentTag,
    handleAddEditorTag,
    handleRemoveEditorTag,
    handleUpdateEditorTag
  };
};

export default useEditor;
