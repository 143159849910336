export const DEFAULT_LIST_LIMIT = 30;

export const DATETIME_FORMAT_STRING = 'MMM D, YYYY • h:mm A';

export const specialsTypes = [
  ['Deals On Green', 'dog'],
  ['Popular Products', 'popular']
];

export const getSpecialsLabel = type => {
  let label = '';
  specialsTypes.forEach(tup => {
    if (tup[1] === type) {
      label = tup[0];
    }
  });
  return label;
};
