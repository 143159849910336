import { Alert, AlertIcon, AlertTitle, AlertDescription /*, CloseButton*/ } from '@chakra-ui/react';

const ErrorDialogue = ({
  error,
  errorTitle = 'Sorry, something went wrong',
  status = 'error',
  fontSize = 'lg',
  titleFontSize = 'lg'
}) => {
  if (!error) {
    return null;
  }

  const errorMessage =
    typeof error === 'string' ? error : error?.response?.data?.message || 'Unknown error detected';

  return (
    <Alert
      status={status}
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      textAlign='center'
      maxWidth='sm'
      mx='auto'
      borderRadius='5px'
    >
      <AlertIcon />
      {errorTitle ? (
        <AlertTitle mt={4} mb={1} fontSize={titleFontSize}>
          {errorTitle}
        </AlertTitle>
      ) : null}
      <AlertDescription maxWidth='sm' fontSize={fontSize}>
        {errorMessage}
      </AlertDescription>
      {/* <CloseButton position='absolute' right='8px' top='8px' /> */}
    </Alert>
  );
};

export default ErrorDialogue;
