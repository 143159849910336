import { useParams } from 'react-router-dom';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';

const Viewer = () => {
  const { id } = useParams();

  return (
    <Box>
      <Heading>View tag, id: {id}</Heading>
    </Box>
  );
};

export default Viewer;
