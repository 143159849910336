import { useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  ButtonGroup
} from '@chakra-ui/react';

import useEditor from 'hooks/useEditor';
import useNews from 'hooks/useNews';
import SaveCancelDialogue from 'components/segments/CancelDialogue';

const getInitialData = () => {
  return {
    // useDefaultHours: true
  };
};

const Editor = () => {
  const { id } = useParams();
  const { news, isRequesting, isUpdating, lastGetError, lastUpdateError, getNews, saveNews } =
    useNews();
  const navigate = useNavigate();
  const hasSetData = useRef();
  const isGettingData = useRef();

  const {
    data,
    changesDetected,
    handleUpdateEditorData,
    setFormData,
    handleSave,
    handleStartCancel
  } = useEditor({
    initialData: getInitialData(),
    isUpdating,
    lastUpdateError,
    saveFunc: data => saveNews({ data, news: id }),
    saveLabel: 'News Article',
    isEditing: !!id
  });

  useEffect(() => {
    if (id && !hasSetData.current && !isRequesting) {
      const editNews = news?.find(l => l._id === id);
      if (editNews) {
        hasSetData.current = true;
        isGettingData.current = false;
        console.log('setting form data to:', editNews);
        setFormData(editNews);
      } else {
        if (!lastGetError && !isGettingData.current) {
          isGettingData.current = true;
          getNews(id);
        }
      }
    }
  }, [id, news, getNews, setFormData, isRequesting, lastGetError]);

  return (
    <Box>
      <Heading mb='20px' size='lg'>
        {id ? `Editing article: ${data.title}` : 'Add a new article'}
      </Heading>

      <Flex gap='20px'>
        <Flex flex='1' gap='20px'>
          <Box flex='0.75' align='flex-start'>
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Publish Date
              </FormLabel>
              <Input
                type='datetime-local'
                name='publishDate'
                value={data?.publishDate || ''}
                onChange={handleUpdateEditorData}
                size='sm'
              />
            </FormControl>
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Title
              </FormLabel>
              <Input
                type='text'
                name='title'
                value={data?.title || ''}
                onChange={handleUpdateEditorData}
                size='sm'
                placeholder='Article Title'
              />
            </FormControl>
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Subtitle
              </FormLabel>
              <Input
                type='text'
                name='subtitle'
                value={data?.subtitle || ''}
                onChange={handleUpdateEditorData}
                size='sm'
                placeholder='Subtitle'
              />
            </FormControl>
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Short Blurb
              </FormLabel>
              <Input
                type='text'
                name='summary'
                value={data?.summary || ''}
                onChange={handleUpdateEditorData}
                size='sm'
                placeholder='Short Blurb / Summary'
              />
            </FormControl>
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Article Content
              </FormLabel>
              <Input
                type='text'
                name='body'
                value={data?.body || ''}
                onChange={handleUpdateEditorData}
                size='sm'
                placeholder='Article Content'
              />
            </FormControl>

            <Box mt='40px'>
              <ButtonGroup>
                <Button onClick={handleSave} disabled={!changesDetected}>
                  Save
                </Button>
                <SaveCancelDialogue
                  handleSave={handleSave}
                  handleStartCancel={handleStartCancel}
                  handleDiscard={() => navigate(-1)}
                  cancelLabel='Back'
                />
              </ButtonGroup>
            </Box>
          </Box>
        </Flex>
        <Box>
          <Heading as='h2' size='md'>
            News Article Images:
          </Heading>
          <Box fontSize='sm'>
            <Text as='em'>(coming soon...)</Text>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default Editor;
