import { useState } from 'react';
import { Box, Button, Heading, Select, Text } from '@chakra-ui/react';
import displayStoreHours from 'utils/displayStoreHours';

const SetHours = ({ locations, setLocationHours }) => {
  const [locationId, setLocationId] = useState('');

  const handleClick = () => {
    if (locationId) {
      const location = locations.find(s => s._id === locationId);
      if (location) {
        const locationHours = {};
        Object.keys(location).forEach(k => {
          if (k.includes('hrs_')) {
            locationHours[k] = location[k];
          }
        });
        setLocationHours(locationHours);
      }
    }
  };

  if (!locations?.length) {
    return null;
  }

  return (
    <Box maxWidth='300px'>
      <Heading as='h3' size='xs'>
        Use hours from another location
      </Heading>
      <Text fontSize='sm'>Pick a location from the list to preview the hours.</Text>
      <Select
        onChange={e => setLocationId(e.target.value)}
        value={locationId}
        size='sm'
        my='10px'
        placeholder='pick a store to see hours'
      >
        {locations.map((location, idx) => {
          return (
            <option key={idx} value={location._id}>
              {location.name}
            </option>
          );
        })}
      </Select>
      <Box
        m='4px'
        p='4px 8px'
        borderRadius='8px'
        backgroundColor='#ddd'
        fontSize='14px'
        display='inline-block'
        width='100%'
        minH='18px'
      >
        {locationId ? displayStoreHours(locations.find(loc => loc._id === locationId)) : null}
      </Box>
      <br />
      <Button onClick={handleClick} disabled={!locationId} size='sm' variant='outline'>
        Use these hours
      </Button>
    </Box>
  );
};

export default SetHours;
