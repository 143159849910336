import { useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  Input,
  ButtonGroup,
  Spinner
} from '@chakra-ui/react';

import useEditor from 'hooks/useEditor';
import useBlog from 'hooks/useBlog';
import SaveCancelDialogue from 'components/segments/CancelDialogue';
import TagsManager from 'components/segments/TagsManager';
import RichContentBlock from 'components/segments/RichContentBlock';
import useTags from 'hooks/useTags';
import FileUploadSingleImage from 'components/segments/FileUploadSingleImage';

const getInitialData = () => {
  return {
    enabled: true
  };
};

const Editor = () => {
  const { id } = useParams();
  const { blog, isRequesting, isUpdating, lastGetError, lastUpdateError, getBlog, saveBlog } =
    useBlog();

  const { tags, isRequesting: tagsLoading, getTagsList, getTagLabelById } = useTags();

  const navigate = useNavigate();
  const hasSetData = useRef();
  const isGettingData = useRef();

  useEffect(() => {
    if (!tags?.length && !tagsLoading) {
      console.log('GO GET TAGS', tags, tagsLoading);
      getTagsList({ limit: 1000 });
    }
  }, [getTagsList, tags, tagsLoading]);

  const {
    data,
    changesDetected,
    handleUpdateEditorData,
    handleUpdateEditorDataDirect,
    handleRichContentBlockChange,
    handleFileUpload,
    setFormData,
    handleSave,
    handleStartCancel,
    handleCheckboxClick,
    currentTag,
    handleAddEditorTag,
    handleRemoveEditorTag,
    handleUpdateEditorTag
  } = useEditor({
    initialData: getInitialData(),
    isUpdating,
    lastUpdateError,
    saveFunc: data => saveBlog({ data, article: id }),
    saveLabel: 'Blog Post',
    isEditing: !!id
  });

  useEffect(() => {
    if (id && !hasSetData.current && !isRequesting) {
      const editBlog = blog?.find(l => l._id === id);
      if (editBlog) {
        hasSetData.current = true;
        isGettingData.current = false;
        console.log('setting form data to:', editBlog);
        setFormData(editBlog);
      } else {
        if (!lastGetError && !isGettingData.current) {
          isGettingData.current = true;
          getBlog(id);
        }
      }
    }
  }, [id, blog, getBlog, setFormData, isRequesting, lastGetError]);

  if (isRequesting) {
    return (
      <Box>
        <Heading mb='20px' size='lg'>
          Loading, one moment please...
        </Heading>
        <Spinner size='xl' />
      </Box>
    );
  }

  return (
    <Box>
      <Heading mb='20px' size='lg'>
        {id ? `Editing post: ${data.title}` : 'Add a new post'}
      </Heading>

      <Flex gap='20px'>
        <Flex flex='1' gap='20px'>
          <Box flex='0.75' align='flex-start'>
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Title
              </FormLabel>
              <Input
                type='text'
                name='title'
                value={data?.title || ''}
                onChange={handleUpdateEditorData}
                size='sm'
                placeholder='Post Title'
              />
            </FormControl>
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Subtitle
              </FormLabel>
              <Input
                type='text'
                name='subtitle'
                value={data?.subtitle || ''}
                onChange={handleUpdateEditorData}
                size='sm'
                placeholder='Subtitle'
              />
            </FormControl>
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Publish Date
              </FormLabel>
              <Input
                type='date'
                name='publishDate'
                value={data?.publishDate || ''}
                onChange={handleUpdateEditorData}
                size='sm'
              />
            </FormControl>
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Show post on site?
              </FormLabel>
              <Checkbox
                name='enabled'
                isChecked={!!data?.enabled}
                value='true'
                onChange={handleCheckboxClick}
              >
                Yes
              </Checkbox>
            </FormControl>
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Short Blurb
              </FormLabel>
              <Input
                type='text'
                name='summary'
                value={data?.summary || ''}
                onChange={handleUpdateEditorData}
                size='sm'
                placeholder='Short Blurb / Summary'
              />
            </FormControl>
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Body Content
              </FormLabel>
              <RichContentBlock
                data={data?.body}
                name='body'
                onChange={handleRichContentBlockChange}
              />
              {/* <Input
                type='text'
                name='body'
                value={data?.body || ''}
                onChange={handleUpdateEditorData}
                size='sm'
                placeholder='Post Content'
              /> */}
            </FormControl>

            <Box mt='40px'>
              <ButtonGroup>
                <Button onClick={handleSave} disabled={!changesDetected}>
                  Save
                </Button>
                <SaveCancelDialogue
                  handleSave={handleSave}
                  handleStartCancel={handleStartCancel}
                  handleDiscard={() => navigate(-1)}
                  cancelLabel='Back'
                />
              </ButtonGroup>
            </Box>
          </Box>
        </Flex>
        <Box flex='1'>
          <Box>
            <FormControl>
              <Heading as='h2' size='md'>
                Tags:
              </Heading>
              <TagsManager
                currentTag={currentTag}
                handleUpdateEditorTag={handleUpdateEditorTag}
                handleAddEditorTag={handleAddEditorTag}
                handleRemoveEditorTag={handleRemoveEditorTag}
                tags={data?.tags}
                tagSet={tags}
                labelResolver={getTagLabelById}
                addLabel='+ Add Tag'
                placeholder='add tag'
              />
            </FormControl>
          </Box>

          <Heading as='h2' size='md' mt='12px'>
            Blog Image:
          </Heading>
          <FileUploadSingleImage
            data={data}
            handleUpdateEditorDataDirect={handleUpdateEditorDataDirect}
            handleFileUpload={handleFileUpload}
            maxPreviewWidth='300px'
            maxFileSize={1024 * 1024 * 2}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default Editor;
