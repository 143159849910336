import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  getTagsList as getList,
  getTagById as getById,
  deleteTag as deleteFilter,
  saveData
} from 'redux/actions/tags';
import { DEFAULT_LIST_LIMIT } from 'constants';

const useTags = () => {
  const dispatch = useDispatch();

  const tags = useSelector(state => state.tags?.list, shallowEqual);
  const isRequesting = useSelector(state => state.tags?.isRequesting);
  const isUpdating = useSelector(state => state.tags?.isUpdating);
  const lastGetError = useSelector(state => state.tags?.lastGetError);
  const lastUpdateError = useSelector(state => state.tags?.lastUpdateError);
  const currentPage = useSelector(state => state.tags?.page, shallowEqual);
  const pages = useSelector(state => state.tags?.pages, shallowEqual);
  const count = useSelector(state => state.tags?.count, shallowEqual);

  const getTagsList = useCallback(
    (params = {}) => {
      if (!isRequesting) {
        const { page, limit = DEFAULT_LIST_LIMIT, filter, sort } = params;
        dispatch(getList({ page, limit, filter, sort }));
      }
    },
    [dispatch, isRequesting]
  );

  const getTagById = useCallback(
    id => {
      if (id && !isRequesting) {
        const myTag = tags?.find(loc => loc._id === id);
        if (!myTag) {
          dispatch(getById(id));
        }
      }
    },
    [dispatch, isRequesting, tags]
  );

  const saveTag = useCallback(
    ({ data, tag }) => {
      dispatch(saveData({ data, tag }));
    },
    [dispatch]
  );

  const deleteTag = useCallback(
    ({ tag }) => {
      dispatch(deleteFilter({ id: tag }));
    },
    [dispatch]
  );

  const getTagLabelById = id => {
    if (tags?.length) {
      const pf = tags.find(pf => pf._id === id);
      return pf?.name || id;
    }
    return id;
  };

  return {
    getTagsList,
    getTagById,
    saveTag,
    deleteTag,
    getTagLabelById,
    isRequesting,
    isUpdating,
    lastGetError,
    lastUpdateError,
    tags,
    currentPage,
    pages,
    count
  };
};

export default useTags;
