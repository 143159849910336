import axios from 'axios';

import {
  ARTICLES_LIST_REQUEST,
  ARTICLES_LIST_RESPONSE,
  ARTICLES_LIST_ERROR,
  ARTICLE_GET_REQUEST,
  ARTICLE_GET_RESPONSE,
  ARTICLE_GET_ERROR,
  ARTICLE_ADD_REQUEST,
  ARTICLE_ADD_RESPONSE,
  ARTICLE_ADD_ERROR,
  ARTICLE_UPDATE_REQUEST,
  ARTICLE_UPDATE_RESPONSE,
  ARTICLE_UPDATE_ERROR,
  ARTICLE_DELETE_REQUEST,
  ARTICLE_DELETE_RESPONSE,
  ARTICLE_DELETE_ERROR
} from 'redux/reducers/articles';

import { getQueryFilter } from 'utils/request';

export const getArticles = ({ page = 1, limit, filter, sort }) => {
  return async dispatch => {
    dispatch({ type: ARTICLES_LIST_REQUEST });
    try {
      const params = { page, limit, filter: getQueryFilter(filter) };
      if (sort) {
        params.sort = JSON.stringify(sort);
      }
      const response = await axios.get('/api/data/list/articles', {
        params
      });
      if (response?.data?.list?.length) {
        dispatch({
          type: ARTICLES_LIST_RESPONSE,
          articleType: filter.type,
          data: response?.data?.list,
          pages: response?.data?.pages || 0,
          count: response?.data?.count || 0,
          // response,
          page
        });
      } else {
        dispatch({
          type: ARTICLES_LIST_ERROR,
          articleType: filter.type,
          data: 'No news articles found'
        });
      }
    } catch (error) {
      dispatch({ type: ARTICLES_LIST_ERROR, articleType: filter.type, data: error.message });
    }
  };
};

export const getArticleById = id => {
  return async dispatch => {
    // dispatch, getState
    dispatch({ type: ARTICLE_GET_REQUEST });
    try {
      const response = await axios.get(`/api/data/get/articles/${id}`);
      dispatch({
        type: ARTICLE_GET_RESPONSE,
        data: response?.data
      });
    } catch (error) {
      dispatch({ type: ARTICLE_GET_ERROR, data: error.message });
    }
  };
};

export const updateArticleStatus = ({ article, status }) => {
  return async (dispatch, getState) => {
    if (!article || typeof status === 'undefined') {
      console.error('error, missing article or status, cannot update');
      return;
    }
    const articles = getState()?.articles.list;
    const myArticle = articles.find(c => c._id === article);
    dispatch({ type: ARTICLE_UPDATE_REQUEST, article });
    try {
      const response = await axios.post('/api/data/update/articles', {
        id: article,
        data: { enabled: status }
      });
      if (response?.data?.result) {
        myArticle.enabled = status;
      }
      dispatch({ type: ARTICLE_UPDATE_RESPONSE, data: myArticle });
    } catch (error) {
      dispatch({ type: ARTICLE_UPDATE_ERROR, data: error });
    }
  };
};

export const saveData = ({ data, article }) => {
  return async (dispatch, getState) => {
    if (!data) {
      console.error('error, missing data, cannot update');
      return;
    }

    if (!data.authorId) {
      data.authorId = getState().adminUser.user._id;
    }

    const params = {
      doc: true,
      data
    };
    let apiPathAction = 'add',
      requestAction = ARTICLE_ADD_REQUEST,
      responseAction = ARTICLE_ADD_RESPONSE,
      errorAction = ARTICLE_ADD_ERROR;
    if (article) {
      params.id = article;
      apiPathAction = 'update';
      requestAction = ARTICLE_UPDATE_REQUEST;
      responseAction = ARTICLE_UPDATE_RESPONSE;
      errorAction = ARTICLE_UPDATE_ERROR;
    }

    dispatch({ type: requestAction, article });

    if (!data.title || !data.body) {
      // race condition with this error dispatch??? timeout=1 gives a tick
      setTimeout(() => {
        dispatch({
          type: errorAction,
          data: 'Missing required properties, make sure to add at least a title and body for the post.'
        });
      }, 1);
      return;
    }

    try {
      const response = await axios.post(`/api/data/${apiPathAction}/articles`, params);
      if (response?.data?.result) {
        dispatch({ type: responseAction, data: response?.data?.doc });
      } else {
        dispatch({ type: errorAction, data: 'failed to save the article' });
      }
    } catch (error) {
      dispatch({
        type: errorAction,
        data: error?.response?.data?.message || 'unknown error'
      });
    }
  };
};

export const deleteArticle = ({ article, type }) => {
  return async dispatch => {
    if (!article) {
      console.error('error, missing article, cannot delete');
      return;
    }
    console.log('ok try to delete article', article);
    dispatch({ type: ARTICLE_DELETE_REQUEST, article });
    try {
      const response = await axios.delete(`/api/data/delete/articles/${article}`);
      console.log('delete article response', response?.data);
      dispatch({ type: ARTICLE_DELETE_RESPONSE, id: article, articleType: type, data: response });
    } catch (error) {
      console.warn('error deleting, dispatch the error', error);
      dispatch({ type: ARTICLE_DELETE_ERROR, data: error });
    }
  };
};
