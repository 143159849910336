import { Flex, Input, Text } from '@chakra-ui/react';
import { useEffect } from 'react';

const DEFAULT_OPEN = '10:00';
const DEFAULT_CLOSE = '23:00';

const SetLocationHours = ({ baseName, data, handler, defaultInit }) => {
  const openField = `${baseName}_open`;
  const closeField = `${baseName}_close`;
  const openValue = data?.[openField];
  const closeValue = data?.[closeField];

  useEffect(() => {
    if (!openValue && defaultInit) {
      console.log('auto-pop the openValue', data);
      handler({ target: { name: openField, value: DEFAULT_OPEN } });
    }
  }, [data, defaultInit, handler, openField, openValue]);

  useEffect(() => {
    if (!closeValue && defaultInit) {
      console.log('auto-pop the closeValue', data);
      handler({ target: { name: closeField, value: DEFAULT_CLOSE } });
    }
  }, [closeField, closeValue, data, defaultInit, handler]);

  return (
    <Flex gap='10px' height='30px' alignItems='center'>
      <Input
        name={openField}
        value={openValue || ''}
        onChange={handler}
        size='xs'
        placeholder='Open time'
        mb='0'
        type='time'
      />
      <Text fontSize='xs' as='em'>
        to
      </Text>
      <Input
        name={closeField}
        value={closeValue || ''}
        onChange={handler}
        size='xs'
        placeholder='Close time'
        mb='0'
        type='time'
      />
    </Flex>
  );
};

export default SetLocationHours;
