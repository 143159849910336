import { Flex, Heading } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import List from './List';

const contactTypes = ['general', 'career', 'education'];

const ContactSubmissions = () => {
  // const navigate = useNavigate();
  const params = useParams();
  const { type } = params;

  if (!contactTypes.includes(type)) {
    return <Heading>Error: {type} Contact Submissions are invalid</Heading>;
  }

  return (
    <Flex flexDirection='column'>
      <Flex justifyContent='space-between'>
        <Heading>{type} Contact Submissions</Heading>
      </Flex>
      <List type={type} />
    </Flex>
  );
};

export default ContactSubmissions;
