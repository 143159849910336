import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import store from './redux/store';
import theme from 'utils/chakra-theme';

// fun dark/light mode toy:
// import { ColorModeSwitcher } from './ColorModeSwitcher';
// import { Logo } from './Logo';
import App from './App';

const AppContainer = () => {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ChakraProvider>
    </Provider>
  );
};

export default AppContainer;
