import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react';

const SearchResults = ({
  showResults,
  hideResults,
  numResults,
  searching,
  products,
  brands,
  top,
  left,
  width,
  selectProduct
}) => {
  if (!showResults) {
    return null;
  }

  const getBrandById = id => {
    if (brands?.length) {
      return brands.find(b => b._id === id);
    }

    return null;
  };

  return (
    <>
      <Box
        position='absolute'
        zIndex='200'
        p='5px 4px'
        width={width}
        backgroundColor='white'
        borderRadius='3px'
        maxHeight='600px'
        overflow='hidden'
        top={top}
      >
        {searching && (
          <Box m='4px'>
            <Spinner size='md' mx='auto' />
          </Box>
        )}
        {products?.map((prod, idx) => {
          const brand = getBrandById(prod.brand);
          return (
            <Flex
              gap='8px'
              key={idx}
              justifyContent='space-between'
              borderBottom={`2px solid #eee`}
              pb='4px'
              mb='4px'
              _last={{ borderBottom: '0' }}
              cursor='pointer'
              onClick={() => {
                hideResults();
                selectProduct(prod);
              }}
            >
              <Text fontSize='12px' color='#447744' noOfLines={1} maxW='95%'>
                {prod.name} ({prod.variants.map(v => `${v.option}: ${v.price}`).join(', ')})
                {brand?.name ? ` - ${brand.name}` : ''}
              </Text>
              <Button
                size='xs'
                onClick={e => {
                  e.preventDefault();
                  hideResults();
                  selectProduct(prod);
                }}
              >
                + Add
              </Button>
            </Flex>
          );
        })}
        <Flex
          gap='8px'
          justifyContent='flex-end'
          borderBottom={`2px solid #eee`}
          pb='4px'
          _last={{ borderBottom: '0' }}
        >
          <Text fontSize='12px' textAlign='right'>
            Showing {products.length} of {numResults} products, refine search term for better
            results
          </Text>
          <Button
            size='sm'
            fontWeight='bold'
            onClick={e => {
              e.preventDefault();
              hideResults();
            }}
          >
            Clear results
          </Button>
        </Flex>
      </Box>
      <Box
        position='absolute'
        zIndex='190'
        onClick={hideResults}
        width='150vw'
        height='130vh'
        top={top}
        left={left}
      />
    </>
  );
};

export default SearchResults;
