import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  getProducts,
  getProductById,
  updateProductStatus,
  saveData
  // deleteProduct as _deleteProduct
} from 'redux/actions/products';
import { DEFAULT_LIST_LIMIT } from 'constants';

const useProducts = () => {
  const dispatch = useDispatch();

  const products = useSelector(state => state.products?.list, shallowEqual);
  const isRequesting = useSelector(state => state.products?.isRequesting);
  const isUpdating = useSelector(state => state.products?.isUpdating);
  const lastGetError = useSelector(state => state.products?.lastGetError);
  const lastUpdateError = useSelector(state => state.products?.lastUpdateError);
  const currentPage = useSelector(state => state.products?.page, shallowEqual);
  const pages = useSelector(state => state.products?.pages, shallowEqual);
  const count = useSelector(state => state.products?.count, shallowEqual);

  const getProductList = useCallback(
    (params = {}) => {
      if (!isRequesting) {
        const { page, limit = DEFAULT_LIST_LIMIT, filter, sort } = params;
        dispatch(getProducts({ page, limit, filter, sort }));
      }
    },
    [dispatch, isRequesting]
  );

  const getProduct = useCallback(
    id => {
      if (id && !isRequesting) {
        const myProduct = products?.find(loc => loc._id === id);
        if (!myProduct) {
          dispatch(getProductById(id));
        }
      }
    },
    [dispatch, isRequesting, products]
  );

  const updateStatus = useCallback(
    ({ product, status }) => {
      dispatch(updateProductStatus({ product, status }));
    },
    [dispatch]
  );

  const saveProduct = useCallback(
    ({ data, product }) => {
      dispatch(saveData({ data, product }));
    },
    [dispatch]
  );

  // const deleteProduct = useCallback(
  //   ({ product }) => {
  //     dispatch(_deleteProduct({ product }));
  //   },
  //   [dispatch]
  // );

  return {
    getProductList,
    getProduct,
    updateStatus,
    saveProduct,
    // deleteProduct,
    isRequesting,
    isUpdating,
    lastGetError,
    lastUpdateError,
    products,
    currentPage,
    pages,
    count
  };
};

export default useProducts;
