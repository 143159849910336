import { useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  // Link,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import useProducts from 'hooks/useProducts';
import { sortArrayByProp } from 'utils/sort';

// import PaginationComponent from 'components/segments/Pagination';
// import ErrorDialogue from 'components/segments/ErrorDialogue';
import { TableBox } from 'components/segments/StyledComponents';

const Report = () => {
  const listOnLoad = useRef();
  const { products, currentPage, isRequesting, getProductList } = useProducts();

  const navigate = useNavigate();

  useEffect(() => {
    if (!listOnLoad.current) {
      listOnLoad.current = true;
      getProductList({ limit: 2000 });
    }
  }, [currentPage, getProductList, products]);

  const errorDisplay = useMemo(() => {
    if (!products?.length) {
      const message =
        !listOnLoad.current || isRequesting ? 'Loading products...' : 'Sorry, no products found';
      return <Heading size='lg'>{message}</Heading>;
    }
    return null;
  }, [products?.length, isRequesting]);

  // const pageChangeHandler = page => {
  //   getProductList({ page, sort: { field: 'dutchieData.name', dir: 1 } });
  // };

  const report = useMemo(() => {
    const report = {
      byName: {}
    };
    if (products?.length) {
      products?.sort(sortArrayByProp(l => l.name || l.dutchieData.name.trim())).forEach(p => {
        if (!report.byName[p.name]) {
          report.byName[p.name] = [];
        }
        report.byName[p.name].push(p);
      });
    }

    return report;
  }, [products]);

  let duplicates = 0;

  return (
    <Box>
      {errorDisplay}
      {!errorDisplay && (
        <>
          <Heading>Duplicates By Name</Heading>

          <TableBox my='12px' border='1px solid #000'>
            <Table size='sm'>
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Count</Th>
                  <Th>IDs</Th>
                  <Th>Dutchie IDs</Th>
                  <Th>Variants</Th>
                </Tr>
              </Thead>
              <Tbody>
                {Object.entries(report.byName)
                  // .sort(sortArrayByProp(l => l.name || l.dutchieData.name.trim()))
                  .map(([name, prods], idx) => {
                    if (prods.length === 1) {
                      return null;
                    }
                    duplicates += 1;
                    return (
                      <Tr key={idx}>
                        <Td>{name}</Td>
                        <Td>{prods.length}</Td>
                        <Td>
                          {prods.map((p, idx) => {
                            return <Text margin='0'>{p._id}</Text>;
                          })}
                        </Td>
                        <Td>
                          {prods.map((p, idx) => {
                            return <Text margin='0'>{p.dutchieData.id}</Text>;
                          })}
                        </Td>
                        <Td>
                          {prods.map((p, idx) => {
                            return (
                              <Text margin='0'>
                                {p.variants
                                  .map(
                                    v =>
                                      v.option +
                                      ': ' +
                                      v.price +
                                      (v.special ? ' (' + v.special + ')' : '')
                                  )
                                  .join(', ')}
                              </Text>
                            );
                          })}
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </TableBox>
          <Text>Duplicates: {duplicates}</Text>
        </>
      )}
      {/* <ErrorDialogue error={lastUpdateError} /> */}
      {/* {pages > 1 && (
        <PaginationComponent
          numPages={pages}
          page={currentPage}
          pageChangeHandler={pageChangeHandler}
        />
      )} */}
    </Box>
  );
};

export default Report;
