import { Box, Button, Image } from '@chakra-ui/react';

import FileUpload from 'components/segments/FileUpload';

const FileUploadSingleImage = ({
  data,
  handleUpdateEditorDataDirect,
  handleFileUpload,
  maxPreviewWidth = '300px',
  promptLabel = 'Select Blog image',
  selectedFileLabel = 'Image to upload:',
  actionLabel = 'Select Image',
  warnFileSize = 1024 * 1024 * 0.9, // 900k
  maxFileSize = 1024 * 1024 // 1mb
}) => {
  if (data?.imageId && !data?._unlinkImage) {
    return (
      <Box textAlign='center'>
        {/* <Text fontSize='sm'>image Id: {data.imageId} (preview image here)</Text> */}
        <Image
          alt='preview stored file'
          src={`/api/image/serve/${data.imageId}`}
          height='auto'
          maxWidth={maxPreviewWidth}
          mx='auto'
        />
        <Button
          onClick={() =>
            handleUpdateEditorDataDirect({
              _uploadImage: '',
              _unlinkImage: true
            })
          }
          size='xs'
        >
          Change Image
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      <FileUpload
        name='_uploadImage'
        promptLabel={promptLabel}
        selectedFileLabel={selectedFileLabel}
        actionLabel={actionLabel}
        acceptedFileTypes={['.jpg', '.jpeg', '.gif', '.png', '.svg']}
        selectedFile={data?._uploadImage?.meta}
        selectedImageData={data?._uploadImage?.fileData}
        isHandlingImage={true}
        maxPreviewWidth={maxPreviewWidth}
        warnFileSize={warnFileSize}
        maxFileSize={maxFileSize}
        onFileSelect={(name, meta, fileData) => {
          console.log('file selected data', name, meta, fileData?.length);
          if (meta?.name && fileData) {
            handleFileUpload('_uploadImage', { meta, fileData });
            if (data?.imageId) {
              handleUpdateEditorDataDirect({
                _unlinkImage: true
              });
            }
          } else {
            handleUpdateEditorDataDirect({
              _uploadImage: ''
            });
          }
        }}
        onError={error => handleFileUpload(undefined, undefined, error)}
      />
      {data?.imageId && data?._unlinkImage ? (
        <Box mt='12px' pt='12px' borderTop='1px solid #ddd' textAlign='center'>
          {/* <Text fontSize='sm'>old image Id: {data.imageId} (preview image here)</Text> */}
          <Button
            onClick={() =>
              handleUpdateEditorDataDirect({
                _uploadImage: '',
                _unlinkImage: false
              })
            }
            size='xs'
          >
            Undo / Keep Current Image
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};

export default FileUploadSingleImage;
