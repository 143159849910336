import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { searchProducts, clearSearchResults } from 'redux/actions/productSearch';

const useProductSearch = () => {
  const dispatch = useDispatch();

  const products = useSelector(state => state.productSearch.list);
  const isRequesting = useSelector(state => state.productSearch?.isRequesting);
  const lastSearchError = useSelector(state => state.productSearch?.lastGetError);
  const count = useSelector(state => state.productSearch?.count, shallowEqual);

  const doProductSearch = searchTerm => {
    dispatch(searchProducts(searchTerm));
  };

  const clearSearchList = () => {
    dispatch(clearSearchResults());
  };

  return {
    products,
    isRequesting,
    lastSearchError,
    count,
    doProductSearch,
    clearSearchList
  };
};

export default useProductSearch;
