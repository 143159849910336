const convertTimeToFriendlyDisplay = t => {
  if (!t) {
    return '';
  }

  const parts = t.split(':');
  let t1 = parseInt(parts[0]);
  const t2 = parseInt(parts[1]);
  const suffix = t1 > 11 && t1 < 24 ? 'pm' : 'am';
  const mins = t2 > 0 ? parts[1] : '';
  if (suffix === 'pm') {
    t1 = t1 - 12;
  }
  return `${t1}${mins ? `:${mins}` : ''}${suffix}`;
};

const addHoursSet = (hoursMap, stack, index1, index2) => {
  if (index2) {
    // double
    stack.push(
      `${hoursMap[index1][0]}-${hoursMap[index2][0]}: ${convertTimeToFriendlyDisplay(
        hoursMap[index1][1]
      )}-${convertTimeToFriendlyDisplay(hoursMap[index1][2])}`
    );
  } else {
    // single
    stack.push(
      `${hoursMap[index1][0]}: ${convertTimeToFriendlyDisplay(
        hoursMap[index1][1]
      )}-${convertTimeToFriendlyDisplay(hoursMap[index1][2])}`
    );
  }
};

const buildHoursMap = source => {
  const hoursMap = [
    ['M', source.hrs_mon_open, source.hrs_mon_close],
    ['Tu', source.hrs_tue_open, source.hrs_tue_close],
    ['W', source.hrs_wed_open, source.hrs_wed_close],
    ['Th', source.hrs_thu_open, source.hrs_thu_close],
    ['F', source.hrs_fri_open, source.hrs_fri_close],
    ['Sa', source.hrs_sat_open, source.hrs_sat_close],
    ['Su', source.hrs_sun_open, source.hrs_sun_close]
  ];
  return hoursMap;
};

const displayStoreHours = store => {
  if (!store) {
    return '';
  }

  // do this when we have store or default hours
  // const hoursMap = store.useDefaultHours ? buildHoursMap(settings) : buildHoursMap(store);
  const hoursMap = buildHoursMap(store);

  let currDayIndex = -1;
  let currDay = '';
  let currDayOpen = '';
  let currDayClose = '';
  let lastDay = '';
  let lastDayIndex = -1;
  const parts2 = [];

  for (let i = 0; i < hoursMap.length; i += 1) {
    if (currDayIndex === -1) {
      currDayIndex = i;
      currDay = hoursMap[i][0];
      currDayOpen = hoursMap[i][1];
      currDayClose = hoursMap[i][2];
    } else {
      if (currDayOpen !== hoursMap[i][1] || currDayClose !== hoursMap[i][2]) {
        if (i - currDayIndex === 1) {
          // single entry
          addHoursSet(hoursMap, parts2, currDayIndex);
          lastDay = hoursMap[currDayIndex][0];
          lastDayIndex = currDayIndex;
        } else {
          // double entry
          addHoursSet(hoursMap, parts2, currDayIndex, i - 1);
          lastDay = hoursMap[i - 1][0];
          lastDayIndex = i - 1;
        }
        currDayIndex = i;
        // eslint-disable-next-line no-unused-vars
        currDay = hoursMap[i][0];
        currDayOpen = hoursMap[i][1];
        currDayClose = hoursMap[i][2];
      }
      if (
        currDayIndex <= hoursMap.length - 1 &&
        i === hoursMap.length - 1 &&
        lastDay !== hoursMap[i][0]
      ) {
        if (i - lastDayIndex === 1) {
          // single entry
          addHoursSet(hoursMap, parts2, i);
        } else {
          // double entry
          addHoursSet(hoursMap, parts2, currDayIndex, i);
        }
      }
    }
  }

  if (parts2.length === 1) {
    // all hours the same
    return `Every day: ${convertTimeToFriendlyDisplay(
      hoursMap[0][1]
    )}-${convertTimeToFriendlyDisplay(hoursMap[0][2])}`;
  }

  return parts2.join(', ');
};

export default displayStoreHours;
