import { useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  Input,
  ButtonGroup,
  Spinner
} from '@chakra-ui/react';

import useEditor from 'hooks/useEditor';
import useTags from 'hooks/useTags';
import SaveCancelDialogue from 'components/segments/CancelDialogue';

const getInitialData = () => {
  return {
    // useDefaultHours: true
  };
};

const Editor = () => {
  const { id } = useParams();
  const { tags, isRequesting, isUpdating, lastGetError, lastUpdateError, getTagById, saveTag } =
    useTags();
  const navigate = useNavigate();
  const hasSetData = useRef();
  const isGettingData = useRef();

  const {
    data,
    changesDetected,
    handleUpdateEditorData,
    setFormData,
    handleSave,
    handleStartCancel
  } = useEditor({
    initialData: getInitialData(),
    isUpdating,
    lastUpdateError,
    saveFunc: data => saveTag({ data, tag: id }),
    saveLabel: 'Tag',
    isEditing: !!id
  });

  useEffect(() => {
    if (id && !hasSetData.current && !isRequesting) {
      const editNews = tags?.find(l => l._id === id);
      if (editNews) {
        hasSetData.current = true;
        isGettingData.current = false;
        console.log('setting form data to:', editNews);
        setFormData(editNews);
      } else {
        if (!lastGetError && !isGettingData.current) {
          isGettingData.current = true;
          getTagById(id);
        }
      }
    }
  }, [id, setFormData, isRequesting, lastGetError, tags, getTagById]);

  if (isRequesting) {
    return (
      <Box>
        <Heading mb='20px' size='lg'>
          Loading, one moment please...
        </Heading>
        <Spinner size='xl' />
      </Box>
    );
  }

  return (
    <Box maxW='400px'>
      <Heading mb='20px' size='lg'>
        {id ? `Editing tag: ${data.name}` : 'Add a new tag'}
      </Heading>

      <Flex flex='1' gap='20px'>
        <Box flex='0.75' align='flex-start'>
          <FormControl>
            <FormLabel mb='0' fontSize='sm'>
              Name
            </FormLabel>
            <Input
              type='text'
              name='name'
              value={data?.name || ''}
              onChange={handleUpdateEditorData}
              size='sm'
              placeholder='Tag name'
            />
          </FormControl>
          <FormControl>
            <FormLabel mb='0' fontSize='sm'>
              Value
            </FormLabel>
            <Input
              type='text'
              name='value'
              value={data?.value || ''}
              onChange={handleUpdateEditorData}
              size='sm'
              placeholder='Tag Value'
            />
          </FormControl>

          <Box mt='40px'>
            <ButtonGroup>
              <Button onClick={handleSave} disabled={!changesDetected}>
                Save
              </Button>
              <SaveCancelDialogue
                handleSave={handleSave}
                handleStartCancel={handleStartCancel}
                handleDiscard={() => navigate(-1)}
                cancelLabel='Back'
              />
            </ButtonGroup>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default Editor;
