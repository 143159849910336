import { useParams } from 'react-router-dom';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { DATETIME_FORMAT_STRING } from 'constants';
import {
  useGeneralContactSubmissions,
  useCareerContactSubmissions,
  useEducationContactSubmissions
} from 'hooks/useContactSubmissions';
import { useEffect, useMemo } from 'react';

const useHook = type => {
  // eslint-disable-next-line default-case
  switch (type) {
    case 'general':
      return useGeneralContactSubmissions;
    case 'career':
      return useCareerContactSubmissions;
    case 'education':
      return useEducationContactSubmissions;
  }
  throw new Error('unsupported contact submission type!');
};

const Viewer = () => {
  const { type, id } = useParams();
  const contactSubHook = useHook(type);

  const { list, isRequesting, getContactSubmission } = contactSubHook();

  const submissionData = useMemo(() => {
    const mySub = list?.find(item => item._id === id);
    return mySub;
  }, [id, list]);

  useEffect(() => {
    if (!submissionData) {
      getContactSubmission(id);
    }
  }, [getContactSubmission, id, submissionData]);

  if (isRequesting) {
    return (
      <Box>
        <Heading>{type} Submission</Heading>
        <Text>loading details, one moment...</Text>
      </Box>
    );
  }

  if (!submissionData) {
    return (
      <Box>
        <Heading>{type} Submission</Heading>
        <Text>nothing found for that submission, id={id}</Text>
      </Box>
    );
  }

  return (
    <Box>
      <Heading>{type} Submission</Heading>
      <Flex flexDirection='column'>
        <Flex flexDirection='row' gap='10px'>
          <Text width='180px' textAlign='right'>
            Date / Time:
          </Text>
          <Text flex='1'>
            {submissionData.created
              ? dayjs(submissionData.created).format(DATETIME_FORMAT_STRING)
              : 'no timestamp found'}
          </Text>
        </Flex>
        <Flex flexDirection='row' gap='10px'>
          <Text width='180px' textAlign='right'>
            From:
          </Text>
          <Text flex='1'>{`${submissionData.firstName} ${submissionData.lastName}`}</Text>
        </Flex>
        <Flex flexDirection='row' gap='10px'>
          <Text width='180px' textAlign='right'>
            Email:
          </Text>
          <Text flex='1'>{submissionData.email || 'no email provided'}</Text>
        </Flex>
        <Flex flexDirection='row' gap='10px'>
          <Text width='180px' textAlign='right'>
            Phone:
          </Text>
          <Text flex='1'>{submissionData.phone || 'no phone provided'}</Text>
        </Flex>
        <Flex flexDirection='row' gap='10px'>
          <Text width='180px' textAlign='right'>
            Message:
          </Text>
          <Text flex='1'>{submissionData.message || 'no message found'}</Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Viewer;
