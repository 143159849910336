import axios from 'axios';

import {
  LOCATIONS_LIST_REQUEST,
  LOCATIONS_LIST_RESPONSE,
  LOCATIONS_LIST_ERROR,
  LOCATION_GET_REQUEST,
  LOCATION_GET_RESPONSE,
  LOCATION_GET_ERROR,
  LOCATION_UPDATE_REQUEST,
  LOCATION_UPDATE_RESPONSE,
  LOCATION_UPDATE_ERROR
  // LOCATION_DELETE_REQUEST,
  // LOCATION_DELETE_RESPONSE,
  // LOCATION_DELETE_ERROR
} from 'redux/reducers/locations';

export const getLocations = ({ page = 1, limit, filter, sort }) => {
  return async dispatch => {
    // dispatch, getState
    dispatch({ type: LOCATIONS_LIST_REQUEST });
    try {
      const response = await axios.get('/api/data/list/locations', {
        params: { page, limit, filter, sort }
      });
      dispatch({
        type: LOCATIONS_LIST_RESPONSE,
        data: response?.data?.list,
        pages: response?.data?.pages || 0,
        count: response?.data?.count || 0,
        // response,
        page
      });
    } catch (error) {
      dispatch({ type: LOCATIONS_LIST_ERROR, data: error.message });
    }
  };
};

export const getLocationById = id => {
  return async dispatch => {
    // dispatch, getState
    dispatch({ type: LOCATION_GET_REQUEST });
    try {
      const response = await axios.get(`/api/data/get/locations/${id}`);
      dispatch({
        type: LOCATION_GET_RESPONSE,
        data: response?.data
      });
    } catch (error) {
      dispatch({ type: LOCATION_GET_ERROR, data: error.message });
    }
  };
};

export const updateLocationStatus = ({ location, status }) => {
  return async (dispatch, getState) => {
    if (!location || typeof status === 'undefined') {
      console.error('error, missing location or status, cannot update');
      return;
    }
    const locations = getState()?.locations.list;
    const myLocation = locations.find(c => c._id === location);
    dispatch({ type: LOCATION_UPDATE_REQUEST, location });
    try {
      const response = await axios.post('/api/data/update/locations', {
        id: location,
        data: { enabled: status }
      });
      if (response?.data?.result) {
        myLocation.enabled = status;
      }
      dispatch({ type: LOCATION_UPDATE_RESPONSE, data: myLocation });
    } catch (error) {
      dispatch({ type: LOCATION_UPDATE_ERROR, data: error });
    }
  };
};

export const saveData = ({ data, location }) => {
  return async (dispatch, getState) => {
    if (!data) {
      console.error('error, missing data, cannot update');
      return;
    }

    if (!location) {
      console.error(
        'error, missing location id for saveData ... add location not supported (driven by Dutchie data at this time)'
      );
      return;
    }

    // const locations = getState()?.locations.list;
    // const myLocation = locations.find(c => c._id === location);
    dispatch({ type: LOCATION_UPDATE_REQUEST, location });
    try {
      const response = await axios.post('/api/data/update/locations', {
        id: location,
        doc: true,
        data
      });
      if (response?.data?.result) {
        dispatch({ type: LOCATION_UPDATE_RESPONSE, data: response?.data?.doc });
      } else {
        dispatch({ type: LOCATION_UPDATE_ERROR, data: 'failed to update the location' });
      }
    } catch (error) {
      dispatch({
        type: LOCATION_UPDATE_ERROR,
        data: error?.response?.data?.message || 'unknown error'
      });
    }
  };
};

// export const deleteLocation = ({ location }) => {
//   return async (dispatch, getState) => {
//     if (!location) {
//       console.error('error, missing location, cannot delete');
//       return;
//     }
//     const locations = getState()?.locations.list;
//     let myLocation = locations.find(c => c._id === location);
//     if (!myLocation) {
//       console.error('error, location not found, cannot delete');
//       return;
//     }
//     console.log('ok try to delete location', location);
//     dispatch({ type: LOCATION_DELETE_REQUEST, location });
//     try {
//       const response = await axios.post('/data/locations/delete', { location });
//       console.log('delete location response', response?.data, myLocation);
//       if (!response?.data?.result) {
//         myLocation = null;
//       }
//       dispatch({ type: LOCATION_DELETE_RESPONSE, data: myLocation?._id, response });
//     } catch (error) {
//       console.warn('error deleting, dispatch the error', error);
//       dispatch({ type: LOCATION_DELETE_ERROR, data: error });
//     }
//   };
// };
