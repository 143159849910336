import { useEffect, useMemo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  Input,
  ButtonGroup,
  Spinner,
  Text
} from '@chakra-ui/react';

import useEditor from 'hooks/useEditor';
import useCmsUsers from 'hooks/useCmsUsers';
import SaveCancelDialogue from 'components/segments/CancelDialogue';

const getInitialData = () => {
  return {
    // useDefaultHours: true
  };
};

const Editor = () => {
  const { id } = useParams();
  const {
    cmsUsers,
    isRequesting,
    isUpdating,
    lastGetError,
    lastUpdateError,
    getCmsUserById,
    saveCmsUser
  } = useCmsUsers();
  const navigate = useNavigate();
  const hasSetData = useRef();
  const isGettingData = useRef();

  const {
    data,
    changesDetected,
    handleUpdateEditorData,
    setFormData,
    handleSave,
    handleStartCancel,
    handleCheckboxClick
  } = useEditor({
    initialData: getInitialData(),
    isUpdating,
    lastUpdateError,
    saveFunc: data => saveCmsUser({ data, cmsUser: id }),
    saveLabel: 'CmsUser',
    isEditing: !!id
  });

  useEffect(() => {
    if (id && !hasSetData.current && !isRequesting) {
      const editNews = cmsUsers?.find(l => l._id === id);
      if (editNews) {
        hasSetData.current = true;
        isGettingData.current = false;
        console.log('setting form data to:', editNews);
        setFormData(editNews);
      } else {
        if (!lastGetError && !isGettingData.current) {
          isGettingData.current = true;
          getCmsUserById(id);
        }
      }
    }
  }, [id, setFormData, isRequesting, lastGetError, cmsUsers, getCmsUserById]);

  const okToSave = useMemo(
    () =>
      changesDetected &&
      ((!data?.password && id) || (data?.password && data.password === data.passwordv)),
    [changesDetected, data, id]
  );

  if (isRequesting) {
    return (
      <Box>
        <Heading mb='20px' size='lg'>
          Loading, one moment please...
        </Heading>
        <Spinner size='xl' />
      </Box>
    );
  }

  return (
    <Box maxW='800px'>
      <Heading mb='20px' size='lg'>
        {id ? `Editing cms user: ${data.firstName} ${data.lastName}` : 'Add a new cms user'}
      </Heading>

      <Flex flex='1' gap='20px'>
        <Box flex='0.75' align='flex-start'>
          <Flex gap='10px'>
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                First Name
              </FormLabel>
              <Input
                type='text'
                name='firstName'
                value={data?.firstName || ''}
                onChange={handleUpdateEditorData}
                size='sm'
                placeholder='first name'
              />
            </FormControl>
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Last Name
              </FormLabel>
              <Input
                type='text'
                name='lastName'
                value={data?.lastName || ''}
                onChange={handleUpdateEditorData}
                size='sm'
                placeholder='last name'
              />
            </FormControl>
          </Flex>
          <Flex gap='10px'>
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Email
              </FormLabel>
              <Input
                type='email'
                name='email'
                value={data?.email || ''}
                onChange={handleUpdateEditorData}
                size='sm'
                placeholder='email'
              />
            </FormControl>
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Phone
              </FormLabel>
              <Input
                type='phone'
                name='phone'
                value={data?.phone || ''}
                onChange={handleUpdateEditorData}
                size='sm'
                placeholder='phone'
              />
            </FormControl>
          </Flex>
          <FormControl>
            <FormLabel mb='0' fontSize='sm'>
              Enabled?
            </FormLabel>
            <Checkbox
              name='enabled'
              isChecked={!!data?.enabled}
              value='true'
              onChange={handleCheckboxClick}
            >
              Yes
            </Checkbox>
          </FormControl>
          {id ? (
            <Text fontSize='sm' textAlign='center' mb='1em'>
              <Text as='em'>
                Leave the password fields blank
                <br />
                if you don't want to update the password for this user.
              </Text>
            </Text>
          ) : null}
          <Flex gap='10px'>
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Password
              </FormLabel>
              <Input
                type='password'
                name='password'
                value={data?.password || ''}
                onChange={handleUpdateEditorData}
                size='sm'
                placeholder='password'
              />
            </FormControl>
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Verify Password
              </FormLabel>
              <Input
                type='password'
                name='passwordv'
                value={data?.passwordv || ''}
                onChange={handleUpdateEditorData}
                size='sm'
                placeholder='type password again'
              />
            </FormControl>
          </Flex>
          <Box mt='40px'>
            <ButtonGroup>
              <Button onClick={handleSave} disabled={!okToSave}>
                Save
              </Button>
              <SaveCancelDialogue
                handleSave={okToSave ? handleSave : undefined}
                handleStartCancel={handleStartCancel}
                handleDiscard={() => navigate(-1)}
                cancelLabel='Back'
              />
            </ButtonGroup>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default Editor;
