import React from 'react';
import SignIn from 'components/segments/SignIn';
import useAuthRedirect from 'hooks/useAuthRedirect';
import useAuth from 'hooks/useAuth';

// simple wrapper to provide protection for components / anything that needs user auth
// future: can expand this to accept an auth level, in addition to logged in / not logged in
const UserAuth = props => {
  const { children, unauthorized, redirectTo } = props;
  const { setRedirect } = useAuthRedirect();

  if (redirectTo) {
    setRedirect(redirectTo);
  }

  const { user } = useAuth();

  if (!user) {
    switch (unauthorized) {
      case 'signin':
        return <SignIn redirectTo={redirectTo} />;
      default:
        return null;
    }
  }

  return <>{children}</>;
};

// UserAuth.defaultProps = {};

export default UserAuth;
