import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import {
  // Alert,
  // AlertIcon,
  // AlertTitle,
  // AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  // CloseButton,
  Flex,
  Heading,
  HStack,
  Input,
  Select,
  Spacer,
  Spinner,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure
} from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import styled from '@emotion/styled';
import useCustomers from 'hooks/useCustomers';
import locationCodes from 'constants/location-codes.json';
import ErrorDialogue from 'components/segments/ErrorDialogue';
import PaginationComponent from 'components/segments/Pagination';
import { TableBox } from 'components/segments/StyledComponents';
import { DATETIME_FORMAT_STRING } from 'constants';

const SORT_INDICATOR_COLOR = '#eee';

const PendingButton = styled(Button)`
  background-color: #eeeeff;
  color: #000;
  display: block;
  margin: 0 auto;
`;

const ActiveButton = styled(Button)`
  background-color: #3e8b30;
  color: #bbff69;
  display: block;
  margin: 0 auto;
`;

const StatusToggle = props => {
  const { data, customerUpdating, toggle, isRequesting } = props;
  const cta = data.enabled ? 'Active' : 'Pending';
  const UseButton = data.enabled ? ActiveButton : PendingButton;

  const handleClick = () => {
    toggle({ customer: data._id, status: !data.enabled });
  };

  if (isRequesting && customerUpdating === data._id) {
    return (
      <Box m='0 auto' align='center'>
        <Spinner size='sm' />
      </Box>
    );
  }

  return (
    <UseButton size='xs' onClick={handleClick} disabled={isRequesting}>
      {cta}
    </UseButton>
  );
};

const DeleteCustomer = props => {
  const { data, customerUpdating, onDelete, isRequesting } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const handleDelete = () => {
    onClose();
    onDelete({ customer: data._id });
  };

  if (isRequesting && customerUpdating === data._id) {
    return (
      <Box m='0 auto' align='center'>
        <Spinner size='sm' />
      </Box>
    );
  }

  return (
    <>
      <Button size='xs' colorScheme='red' onClick={onOpen}>
        Delete
      </Button>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Customer
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure? You can't undo this action afterwards.</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const locationName = code => {
  const myLoc = locationCodes.find(loc => loc.code === code);
  return myLoc || { locationName: 'unmatched location?' };
};

const locationOptions = locationCodes
  .sort((a, b) => {
    const ap = a.locationName || 0;
    const bp = b.locationName || 0;
    return ap > bp ? 1 : bp > ap ? -1 : 0;
  })
  .map((loc, idx) => {
    return (
      <option value={loc.code} key={idx}>
        {loc.code} - {loc.locationName}
        {loc.metroName ? ` (${loc.metroName})` : ''}
      </option>
    );
  });

const defaultSearchParams = {
  searchType: '',
  searchTerm: '',
  isExact: false,
  customerStatus: '',
  preferredLocation: ''
};

const defaultSortField = { field: 'name', dir: 1 };

const CustomerList = () => {
  const listOnLoad = useRef();
  const hasPerformedFilterSearch = useRef();
  const {
    customers,
    customerUpdating,
    currentPage,
    pages,
    count,
    isRequesting,
    lastUpdateError,
    getCustomerList,
    updateStatus,
    deleteCustomer
  } = useCustomers();
  const [searchFilterParams, setSearchFilterParams] = useState({ ...defaultSearchParams });
  const [sortField, setSortField] = useState({ ...defaultSortField });

  const updateSearchFilter = e => {
    const { name, value } = e.target;
    console.log('updateSearchFilter', name, value);
    let newValue;
    switch (name) {
      case 'isExact':
        newValue = !searchFilterParams[name];
        break;
      default:
        newValue = value;
        break;
    }
    const newParams = {
      ...searchFilterParams,
      [name]: newValue
    };
    hasPerformedFilterSearch.current = false;
    setSearchFilterParams(newParams);
  };

  const getFilterParams = useCallback(
    (isPageChange = false) => {
      if (
        (!searchFilterParams.searchType || !searchFilterParams.searchTerm) &&
        hasPerformedFilterSearch.current &&
        !isPageChange
      ) {
        return null;
      }
      const filterParams = {
        customerStatus: searchFilterParams.customerStatus,
        fuzzy: !searchFilterParams.isExact
      };
      if (searchFilterParams.preferredLocation) {
        if (searchFilterParams.preferredLocation === 'none') {
          filterParams.preferredLocation = null;
        } else {
          filterParams.preferredLocation = searchFilterParams.preferredLocation;
        }
      }
      if (searchFilterParams.searchType && searchFilterParams.searchTerm) {
        filterParams[searchFilterParams.searchType] = searchFilterParams.searchTerm;
      }
      return filterParams;
    },
    [searchFilterParams]
  );

  const searchFilter = () => {
    const params = getFilterParams();
    if (!params) {
      return;
    }
    console.log('searchFilter, params:', params);
    hasPerformedFilterSearch.current = true;
    getCustomerList({ page: 1, filter: params, sort: pages });
  };

  const reset = () => {
    setSearchFilterParams({ ...defaultSearchParams });
    setSortField({ ...defaultSortField });
    getCustomerList();
  };

  const pageChangeHandler = page => {
    const params = getFilterParams(true);
    getCustomerList({ page, filter: params, sort: sortField });
  };

  const CustomerNameSortIcon = useMemo(() => {
    if (sortField.field === 'name') {
      // intentionally only sorting A-Z for name for Janis' request
      return <TriangleUpIcon />;
    }
    return null;
  }, [sortField.field]);

  const SignedUpSortIcon = useMemo(() => {
    if (sortField.field === 'created') {
      if (sortField.dir === -1) {
        return <TriangleDownIcon />;
      }
      return <TriangleUpIcon />;
    }
    return null;
  }, [sortField.field, sortField.dir]);

  useEffect(() => {
    if (!listOnLoad.current) {
      listOnLoad.current = true;
      getCustomerList();
    }
  }, [getCustomerList]);

  // DEV NOTE: this is funky, because of the getFilterParams function changes on each change to the actual filter params
  // so we are doing this to get around too many useEffect triggers, and asynchronous state change
  // that race-conditions the sort fields used in getCustomerList
  const updateSortField = field => {
    const newSortField =
      sortField.field === field ? { field, dir: sortField.dir * -1 } : { field, dir: 1 };
    // lock direction for name A-Z only per Janis' request
    if (field === 'name') {
      newSortField.dir = 1;
    }
    // because of the A-Z enforce, stop any re-search trigger if name is clicked again
    if (sortField.field === newSortField.field && sortField.dir === newSortField.dir) {
      return;
    }
    setSortField(newSortField);
    const params = getFilterParams(true);
    getCustomerList({
      page: 1,
      filter: params,
      sort: newSortField
    });
    hasPerformedFilterSearch.current = true;
  };

  const errorDisplay = useMemo(() => {
    if (!customers?.length) {
      const message =
        !listOnLoad.current || isRequesting
          ? 'Loading customers...'
          : 'Customer List: sorry, no customers found';
      return <Heading size='lg'>{message}</Heading>;
    }
    return null;
  }, [customers?.length, isRequesting]);

  return (
    <Box>
      <Heading size='lg' mt='0'>
        Customer List
      </Heading>
      <Text fontSize='sm' m='1em 0'>
        Click the Pending / Enabled button to toggle the customer status.
        <br />
        Click <Text as='strong'>Customer Name</Text> or <Text as='strong'>Signed up</Text> top tabs
        to sort the list.
      </Text>
      <form>
        <HStack m='10px auto'>
          <Flex wrap={['wrap', 'wrap', 'no-wrap']}>
            <HStack>
              <Text pl='0.5em'>Search By:</Text>
              <Select
                name='searchType'
                placeholder='choose'
                w={['80px', '100px', '120px', '120px']}
                onChange={updateSearchFilter}
                value={searchFilterParams.searchType}
                size='sm'
              >
                <option value='name'>name</option>
                {/*<option value='email'>email</option>*/}
                <option value='phone'>phone</option>
              </Select>
              <Input
                name='searchTerm'
                placeholder='enter search term'
                w='200px'
                onChange={updateSearchFilter}
                value={searchFilterParams.searchTerm}
                size='sm'
              />
              <Checkbox
                defaultChecked
                name='isExact'
                value={true}
                isChecked={!!searchFilterParams.isExact}
                onChange={updateSearchFilter}
              >
                Exact
              </Checkbox>
            </HStack>
            <HStack>
              <Text
                ml={['', '', '0.5em']}
                pl='0.5em'
                fontSize='sm'
                borderLeft={`2px solid ${SORT_INDICATOR_COLOR}`}
              >
                Status:
              </Text>
              <Select
                name='customerStatus'
                w='100px'
                onChange={updateSearchFilter}
                value={searchFilterParams.customerStatus}
                size='sm'
              >
                <option value=''>Any</option>
                <option value='active'>Active</option>
                <option value='pending'>Pending</option>
              </Select>
              <Text pl='0.5em' fontSize='sm' borderLeft={`2px solid ${SORT_INDICATOR_COLOR}`}>
                Location:
              </Text>
              <Select
                name='preferredLocation'
                w='100px'
                onChange={updateSearchFilter}
                value={searchFilterParams.preferredLocation}
                size='sm'
              >
                <option value=''>Any</option>
                <option value='none'>None</option>
                {locationOptions}
              </Select>
              <Button
                onClick={searchFilter}
                size='sm'
                isDisabled={!!hasPerformedFilterSearch.current || !getFilterParams()}
              >
                Search
              </Button>
              <Button onClick={reset} size='sm' variant='outline'>
                Reset
              </Button>
            </HStack>
          </Flex>
          <Spacer />
          {count && (
            <Text as='em' fontSize='12px' pt='0.5em' pr='0.5em'>
              <Text as='strong'>{count}</Text> customers found
            </Text>
          )}
        </HStack>
      </form>
      {errorDisplay}
      {!errorDisplay && (
        <TableBox my='12px' border='1px solid #000'>
          <Table size='sm'>
            <Thead>
              <Tr>
                <Th
                  backgroundColor={sortField.field === 'name' && SORT_INDICATOR_COLOR}
                  borderTopLeftRadius='6px'
                  borderTopRightRadius='6px'
                  cursor='pointer'
                  onClick={() => updateSortField('name')}
                >
                  Customer Name {CustomerNameSortIcon}
                </Th>
                <Th>Email</Th>
                <Th>Phone</Th>
                <Th>Preferred Store</Th>
                <Th
                  backgroundColor={sortField.field === 'created' && SORT_INDICATOR_COLOR}
                  borderTopLeftRadius='6px'
                  borderTopRightRadius='6px'
                  cursor='pointer'
                  onClick={() => updateSortField('created')}
                >
                  Signed Up {SignedUpSortIcon}
                </Th>
                <Th>Last Login</Th>
                <Th width='140px'>
                  Status {/*isRequesting && <Spinner size='xs' float='right' />*/}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {customers.map((c, idx) => {
                const location = c.preferredLocation
                  ? `${c.preferredLocation} - ${locationName(c.preferredLocation)?.locationName}`
                  : 'no location picked';
                return (
                  <Tr key={idx}>
                    <Td>{c.name}</Td>
                    <Td>{c._emailIdentity?.[0]?.email}</Td>
                    <Td>{c.phone}</Td>
                    <Td>{location}</Td>
                    <Td>{dayjs(c.created).format(DATETIME_FORMAT_STRING)}</Td>
                    <Td>
                      {dayjs(c._emailIdentity?.[0]?.lastLogin || c.created).format(
                        DATETIME_FORMAT_STRING
                      )}
                    </Td>
                    <Td>
                      <ButtonGroup spacing={2} as={Flex} justifyContent='center' width='100%'>
                        <StatusToggle
                          data={c}
                          toggle={updateStatus}
                          isRequesting={isRequesting}
                          customerUpdating={customerUpdating}
                        />
                        <DeleteCustomer
                          data={c}
                          onDelete={deleteCustomer}
                          isRequesting={isRequesting}
                          customerUpdating={customerUpdating}
                        />
                      </ButtonGroup>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableBox>
      )}
      <ErrorDialogue error={lastUpdateError} />
      {pages > 1 && (
        <PaginationComponent
          numPages={pages}
          page={currentPage}
          pageChangeHandler={pageChangeHandler}
        />
      )}
    </Box>
  );
};

export default CustomerList;
