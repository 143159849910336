import { useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import useProductFilters from 'hooks/useProductFilters';

import PaginationComponent from 'components/segments/Pagination';
import ErrorDialogue from 'components/segments/ErrorDialogue';
import DeleteDialogue from 'components/segments/DeleteDialogue';
import { TableBox } from 'components/segments/StyledComponents';

const List = () => {
  const listOnLoad = useRef();
  const {
    productFilters,
    currentPage,
    pages,
    // count,
    isRequesting,
    lastUpdateError,
    getProductFiltersList,
    // updateStatus,
    deleteProductFilter
  } = useProductFilters();

  const navigate = useNavigate();

  useEffect(() => {
    if (!listOnLoad.current) {
      listOnLoad.current = true;
      getProductFiltersList({ page: currentPage || 1 });
    }
  }, [currentPage, getProductFiltersList, productFilters]);

  const errorDisplay = useMemo(() => {
    if (!productFilters?.length) {
      const message =
        !listOnLoad.current || isRequesting
          ? 'Loading product filters...'
          : 'Sorry, no product filters found';
      return <Heading size='lg'>{message}</Heading>;
    }
    return null;
  }, [productFilters?.length, isRequesting]);

  const pageChangeHandler = page => {
    getProductFiltersList({ page, sort: { field: 'created', dir: -1 } });
  };

  const handleDelete = productFilter => {
    if (productFilter) {
      deleteProductFilter({ productFilter });
    }
  };

  return (
    <Box>
      {errorDisplay}
      {!errorDisplay && (
        <TableBox my='12px' border='1px solid #000'>
          <Table size='sm'>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Value</Th>
                <Th>Type</Th>
                <Th>From Dutchie?</Th>
                <Th>Enabled?</Th>
                <Th>On homepage?</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {productFilters.map((l, idx) => {
                return (
                  <Tr key={idx}>
                    <Td>{l.name}</Td>
                    <Td>{l.value}</Td>
                    <Td>{l.type}</Td>
                    <Td>{l.isDutchieFilter ? 'x' : ''}</Td>
                    <Td>{l.enabled ? 'x' : ''}</Td>
                    <Td>{l.isMainCategory ? `yes, position: ${l.sortOrder}` : ''}</Td>
                    <Td>
                      <ButtonGroup spacing='3' size='xs'>
                        <Button onClick={() => navigate(`/filters/edit/${l._id}`)}>Edit</Button>
                        {!l.isDutchieFilter ? (
                          <DeleteDialogue
                            label='Delete'
                            deleteConfirmLabel='Delete Product Filter'
                            handleDelete={() => handleDelete(l._id)}
                          />
                        ) : null}
                      </ButtonGroup>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableBox>
      )}
      <ErrorDialogue error={lastUpdateError} />
      {pages > 1 && (
        <PaginationComponent
          numPages={pages}
          page={currentPage}
          pageChangeHandler={pageChangeHandler}
        />
      )}
    </Box>
  );
};

export default List;
