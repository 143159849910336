import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  getCmsUsersList as getList,
  getCmsUserById as getById,
  deleteCmsUser as deleteFilter,
  saveData
} from 'redux/actions/cmsUsers';
import { DEFAULT_LIST_LIMIT } from 'constants';

const useCmsUsers = () => {
  const dispatch = useDispatch();

  const cmsUsers = useSelector(state => state.cmsUsers?.list, shallowEqual);
  const isRequesting = useSelector(state => state.cmsUsers?.isRequesting);
  const isUpdating = useSelector(state => state.cmsUsers?.isUpdating);
  const lastGetError = useSelector(state => state.cmsUsers?.lastGetError);
  const lastUpdateError = useSelector(state => state.cmsUsers?.lastUpdateError);
  const currentPage = useSelector(state => state.cmsUsers?.page, shallowEqual);
  const pages = useSelector(state => state.cmsUsers?.pages, shallowEqual);
  const count = useSelector(state => state.cmsUsers?.count, shallowEqual);

  const getCmsUsersList = useCallback(
    (params = {}) => {
      if (!isRequesting) {
        const { page, limit = DEFAULT_LIST_LIMIT, filter, sort } = params;
        dispatch(getList({ page, limit, filter, sort }));
      }
    },
    [dispatch, isRequesting]
  );

  const getCmsUserById = useCallback(
    id => {
      if (id && !isRequesting) {
        const myCmsUser = cmsUsers?.find(loc => loc._id === id);
        if (!myCmsUser) {
          dispatch(getById(id));
        }
      }
    },
    [dispatch, isRequesting, cmsUsers]
  );

  const saveCmsUser = useCallback(
    ({ data, cmsUser }) => {
      dispatch(saveData({ data, cmsUser }));
    },
    [dispatch]
  );

  // const deleteCmsUser = useCallback(
  //   ({ cmsUser }) => {
  //     dispatch(deleteFilter({ id: cmsUser }));
  //   },
  //   [dispatch]
  // );

  return {
    getCmsUsersList,
    getCmsUserById,
    saveCmsUser,
    // deleteCmsUser,
    isRequesting,
    isUpdating,
    lastGetError,
    lastUpdateError,
    cmsUsers,
    currentPage,
    pages,
    count
  };
};

export default useCmsUsers;
