import { useNavigate } from 'react-router-dom';

let redirectTo = '';

const useAuthRedirect = () => {
  const navigate = useNavigate();

  const setRedirect = to => {
    redirectTo = to;
  };

  const redirectOnAuth = () => {
    if (redirectTo) {
      const to = redirectTo;
      setRedirect('');
      return navigate(to);
    }
    navigate('/');
  };

  return {
    setRedirect,
    redirectOnAuth
  };
};

export default useAuthRedirect;
