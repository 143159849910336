import { useEffect, useRef } from 'react';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Link,
  Table,
  Tbody,
  Tr,
  Td,
  // Thead,
  ButtonGroup,
  HStack,
  Spinner
} from '@chakra-ui/react';

import useEditor from 'hooks/useEditor';
import useProducts from 'hooks/useProducts';
import SaveCancelDialogue from 'components/segments/CancelDialogue';
import TagsManager from 'components/segments/TagsManager';
import RichContentBlock from 'components/segments/RichContentBlock';
import useProductFilters from 'hooks/useProductFilters';

const getInitialData = () => {
  return {
    useDefaultHours: true
  };
};

const Editor = () => {
  const { id } = useParams();
  const {
    products,
    isRequesting,
    isUpdating,
    lastGetError,
    lastUpdateError,
    getProduct,
    saveProduct
  } = useProducts();

  const {
    productFilters,
    isRequesting: productFiltersLoading,
    getProductFiltersList,
    getFilterLabelById
  } = useProductFilters();

  const navigate = useNavigate();
  const hasSetData = useRef();
  const isGettingData = useRef();

  useEffect(() => {
    if (!productFilters?.length && !productFiltersLoading) {
      console.log('GO GET FILTERS', productFilters, productFiltersLoading);
      getProductFiltersList({ limit: 1000 });
    }
  }, [getProductFiltersList, productFilters, productFiltersLoading]);

  const {
    data,
    changesDetected,
    handleUpdateEditorData,
    // handleCheckboxClick,
    setFormData,
    handleSave,
    handleStartCancel,

    handleRichContentBlockChange,
    currentTag,
    handleAddEditorTag,
    handleRemoveEditorTag,
    handleUpdateEditorTag
  } = useEditor({
    initialData: getInitialData(),
    isUpdating,
    lastUpdateError,
    saveFunc: data => saveProduct({ data, product: id }),
    saveLabel: 'Product',
    isEditing: !!id
  });

  useEffect(() => {
    if (id && !hasSetData.current && !isRequesting) {
      const editProduct = products?.find(l => l._id === id);
      if (editProduct) {
        hasSetData.current = true;
        isGettingData.current = false;
        console.log('setting form data to:', editProduct);
        setFormData(editProduct);
      } else {
        if (!lastGetError && !isGettingData.current) {
          isGettingData.current = true;
          getProduct(id);
        }
      }
    }
  }, [id, products, getProduct, setFormData, isRequesting, lastGetError]);

  if (isRequesting) {
    return (
      <Box>
        <Heading mb='20px' size='lg'>
          Loading, one moment please...
        </Heading>
        <Spinner size='xl' />
      </Box>
    );
  }

  if (id && !data?.dutchieData) {
    return (
      <Heading mb='20px' size='lg'>
        Error: product not found.
      </Heading>
    );
  }

  return (
    <Box>
      <Heading mb='20px' size='lg'>
        {id ? `Editing Product: ${data.name || data.dutchieData?.name}` : 'Add a new product'}
      </Heading>

      <Flex gap='20px'>
        <Flex flex='1' gap='20px'>
          <Box flex='1' align='flex-start'>
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Override Name
              </FormLabel>
              <Input
                type='text'
                name='name'
                value={data?.name || ''}
                onChange={handleUpdateEditorData}
                size='sm'
                placeholder='Override Dutchie Name'
              />
            </FormControl>
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Filters
              </FormLabel>
              <TagsManager
                currentTag={currentTag}
                handleUpdateEditorTag={handleUpdateEditorTag}
                handleAddEditorTag={handleAddEditorTag}
                handleRemoveEditorTag={handleRemoveEditorTag}
                tags={data?.tags}
                tagSet={productFilters}
                labelResolver={getFilterLabelById}
                addLabel='+ Add Filter'
                placeholder='add shop filters'
              />
            </FormControl>
            <FormControl mt='12px'>
              <FormLabel mb='0' fontSize='sm'>
                Description
              </FormLabel>
              <RichContentBlock
                data={data?.description || data?.dutchieData?.description}
                name='description'
                onChange={handleRichContentBlockChange}
              />
            </FormControl>

            <Box mt='40px'>
              <ButtonGroup>
                <Button onClick={handleSave} disabled={!changesDetected}>
                  Save
                </Button>
                <SaveCancelDialogue
                  handleSave={handleSave}
                  handleStartCancel={handleStartCancel}
                  handleDiscard={() => navigate(-1)}
                  cancelLabel='Back'
                />
              </ButtonGroup>
            </Box>
          </Box>
        </Flex>
        <Box flex='1'>
          <Heading as='h2' size='md'>
            Dutchie Product Info:
          </Heading>
          {data?.dutchieData ? (
            <Box fontSize='sm'>
              <Table>
                <Tbody>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{data.dutchieData?.name}</Td>
                  </Tr>
                  {data.dutchieData?.image ? (
                    <Tr>
                      <Td colSpan={2}>
                        <img
                          src={data.dutchieData.image}
                          alt='product'
                          style={{ maxHeight: '120px' }}
                        />
                      </Td>
                    </Tr>
                  ) : null}
                  <Tr>
                    <Td verticalAlign='top'>Brand</Td>
                    <Td>
                      <HStack spacing='1'>
                        {data.dutchieData?.brand?.imageUrl ? (
                          <img
                            src={data.dutchieData?.brand.imageUrl}
                            alt='brand logo'
                            style={{ maxHeight: '60px' }}
                          />
                        ) : null}
                        <Box></Box>
                        <Box>{data.dutchieData?.brand?.name}</Box>
                      </HStack>
                    </Td>
                  </Tr>
                  {/* <Tr>
                  <Td>Product Slug</Td>
                  <Td>
                    <Link
                      href={`http://localhost:3000/product/all-stores/${data.dutchieData?.slug}`}
                      target='_new'
                    >
                      {data.dutchieData?.slug}
                    </Link>
                  </Td>
                </Tr> */}
                  <Tr>
                    <Td verticalAlign='top'>Category</Td>
                    <Td>{data.dutchieData.category}</Td>
                  </Tr>
                  {data.dutchieData?.subcategory ? (
                    <Tr>
                      <Td verticalAlign='top'>Sub-Category</Td>
                      <Td>{data.dutchieData.subcategory}</Td>
                    </Tr>
                  ) : null}
                  {data.dutchieData?.strainType ? (
                    <Tr>
                      <Td verticalAlign='top'>Strain Type</Td>
                      <Td>{data.dutchieData.strainType}</Td>
                    </Tr>
                  ) : null}
                  {data.dutchieData?.effects ? (
                    <Tr>
                      <Td verticalAlign='top'>Effects</Td>
                      <Td>{data.dutchieData.effects.join(', ')}</Td>
                    </Tr>
                  ) : null}
                  {data.dutchieData?.terpenes ? (
                    <Tr>
                      <Td verticalAlign='top'>Terpenes</Td>
                      <Td>{data.dutchieData.terpenes}</Td>
                    </Tr>
                  ) : null}
                  {data.dutchieData?.cannabinoids ? (
                    <Tr>
                      <Td verticalAlign='top'>Cannabinoids</Td>
                      <Td>{data.dutchieData.cannabinoids}</Td>
                    </Tr>
                  ) : null}
                  {data.dutchieData?.potencyThc?.formatted ? (
                    <Tr>
                      <Td verticalAlign='top'>THC Potency</Td>
                      <Td>{data.dutchieData.potencyThc.formatted}</Td>
                    </Tr>
                  ) : null}
                  {data.dutchieData?.potencyCbd?.formatted ? (
                    <Tr>
                      <Td verticalAlign='top'>CBD Potency</Td>
                      <Td>{data.dutchieData.potencyCbd.formatted}</Td>
                    </Tr>
                  ) : null}
                </Tbody>
              </Table>
            </Box>
          ) : (
            <Heading>Error: no dutchie data found for this product!</Heading>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default Editor;
