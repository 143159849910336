export const SETTINGS_CONTAINER_TYPE = 'settings.container';
export const SETTINGS_GET_REQUEST = 'SETTINGS_GET_REQUEST';
export const SETTINGS_GET_RESPONSE = 'SETTINGS_GET_RESPONSE';
export const SETTINGS_GET_ERROR = 'SETTINGS_GET_ERROR';
export const SETTINGS_INIT_REQUEST = 'SETTINGS_INIT_REQUEST';
export const SETTINGS_INIT_RESPONSE = 'SETTINGS_INIT_RESPONSE';
export const SETTINGS_INIT_ERROR = 'SETTINGS_INIT_ERROR';
export const SETTINGS_UPDATE_REQUEST = 'SETTINGS_UPDATE_REQUEST';
export const SETTINGS_UPDATE_RESPONSE = 'SETTINGS_UPDATE_RESPONSE';
export const SETTINGS_UPDATE_ERROR = 'SETTINGS_UPDATE_ERROR';
export const SETTINGS_UPDATE_LIVE_SITE_REQUEST = 'SETTINGS_UPDATE_LIVE_SITE_REQUEST';
export const SETTINGS_UPDATE_LIVE_SITE_RESPONSE = 'SETTINGS_UPDATE_LIVE_SITE_RESPONSE';
export const SETTINGS_UPDATE_LIVE_SITE_RESET = 'SETTINGS_UPDATE_LIVE_SITE_RESET';
export const SETTINGS_UPDATE_LIVE_SITE_ERROR = 'SETTINGS_UPDATE_LIVE_SITE_ERROR';

const initialState = {
  data: null,
  refreshMsg: '',
  isUpdating: false,
  isRequesting: false,
  isRefreshing: false,
  isInitializing: false,
  lastGetResponse: null,
  lastGetError: null,
  lastInitResponse: null,
  lastInitError: null,
  lastUpdateResponse: null,
  lastUpdateError: null
};

const settings = (state = initialState, action) => {
  switch (action?.type) {
    case SETTINGS_GET_REQUEST:
      return {
        ...state,
        isRequesting: true,
        lastInitResponse: null,
        lastGetResponse: null,
        lastGetError: null
      };
    case SETTINGS_GET_RESPONSE:
      return {
        ...state,
        data: action?.data,
        isRequesting: false,
        lastGetResponse: action?.data,
        lastGetError: null
      };
    case SETTINGS_GET_ERROR:
      return {
        ...state,
        isRequesting: false,
        lastGetResponse: null,
        lastGetError: action?.data
      };

    case SETTINGS_INIT_REQUEST:
      return {
        ...state,
        isInitializing: true,
        lastInitResponse: null,
        lastInitError: null
      };
    case SETTINGS_INIT_RESPONSE:
      return {
        ...state,
        data: action?.data,
        isInitializing: false,
        lastInitResponse: action?.data,
        lastInitError: null
      };
    case SETTINGS_INIT_ERROR:
      return {
        ...state,
        isInitializing: false,
        lastInitResponse: null,
        lastInitError: action?.data
      };

    case SETTINGS_UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        lastUpdateResponse: null,
        lastUpdateError: null
      };
    case SETTINGS_UPDATE_RESPONSE:
      return {
        ...state,
        data: action?.data,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case SETTINGS_UPDATE_ERROR:
      return {
        ...state,
        isUpdating: false,
        lastUpdateResponse: null,
        lastUpdateError: action?.data
      };

    case SETTINGS_UPDATE_LIVE_SITE_REQUEST:
      return {
        ...state,
        isRefreshing: true
      };

    case SETTINGS_UPDATE_LIVE_SITE_RESPONSE:
    case SETTINGS_UPDATE_LIVE_SITE_ERROR:
      return {
        ...state,
        isRefreshing: false,
        refreshMsg: action?.data
      };

    case SETTINGS_UPDATE_LIVE_SITE_RESET:
      return {
        ...state,
        refreshMsg: ''
      };
    default:
      return state;
  }
};

export default settings;
