import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  loginAdminUser,
  loginStoreUser,
  logoutAdminUser,
  refreshAdminUser
} from 'redux/actions/adminUser';
import { CUSTOMERS_LIST_RESET } from 'redux/reducers/customers';
import { useNavigate } from 'react-router-dom';

let isRefreshed = false;

const useAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(state => state.adminUser?.user, shallowEqual);
  const isRequesting = useSelector(state => state.adminUser?.isRequesting);

  useEffect(() => {
    if (!isRefreshed) {
      isRefreshed = true;
      dispatch(refreshAdminUser());
    }
  }, [dispatch]);

  const login = useCallback(
    ({ email, password }) => {
      dispatch(loginAdminUser({ email, password }));
    },
    [dispatch]
  );

  const loginStore = useCallback(
    ({ storecode, password }) => {
      dispatch(loginStoreUser({ storecode, password }));
    },
    [dispatch]
  );

  const logout = useCallback(() => {
    // isRefreshed = false;
    dispatch(logoutAdminUser());
    dispatch({ type: CUSTOMERS_LIST_RESET });
    navigate('/');
  }, [dispatch, navigate]);

  return {
    isRequesting,
    login,
    loginStore,
    logout,
    user
  };
};

export default useAuth;
