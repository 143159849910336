import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  getSpecials,
  getSpecialById,
  saveData,
  deleteSpecial as _deleteSpecial
} from 'redux/actions/specials';
import useProductSearch from './useProductSearch';

const useSpecials = ({ type }) => {
  const dispatch = useDispatch();
  if (!type) {
    throw new Error('specials hook requires a type');
  }

  const specials = useSelector(state => state.specials?.list[type], shallowEqual);
  const isRequesting = useSelector(state => state.specials?.isRequesting);
  const isUpdating = useSelector(state => state.specials?.isUpdating);
  const lastGetError = useSelector(state => state.specials?.lastGetError);
  const lastUpdateError = useSelector(state => state.specials?.lastUpdateError);
  const currentPage = useSelector(state => state.specials?.page[type], shallowEqual);
  const pages = useSelector(state => state.specials?.pages[type], shallowEqual);
  const count = useSelector(state => state.specials?.count[type], shallowEqual);

  console.log('useSpecials hook, type: ', type);

  const getSpecialsList = useCallback(
    (params = {}) => {
      if (!isRequesting) {
        const { page, limit = 25, filter = {}, sort } = params;
        filter.type = type;
        dispatch(getSpecials({ page, limit, filter, sort }));
      }
    },
    [dispatch, isRequesting, type]
  );

  const getSpecial = useCallback(
    id => {
      if (id && !isRequesting) {
        const mySpecial = specials?.find(art => art._id === id);
        if (!mySpecial) {
          dispatch(getSpecialById(id));
        }
      }
    },
    [dispatch, isRequesting, specials]
  );

  const saveSpecial = useCallback(
    ({ data, special }) => {
      data.type = type;
      dispatch(saveData({ data, special }));
    },
    [dispatch, type]
  );

  const deleteSpecial = useCallback(
    ({ special }) => {
      dispatch(_deleteSpecial({ special, type }));
    },
    [dispatch, type]
  );

  // below here, methods and stuff to manage product lists
  const {
    products,
    isRequesting: isSearching,
    lastSearchError,
    count: searchCount,
    doProductSearch
  } = useProductSearch();

  return {
    getSpecialsList,
    getSpecial,
    saveSpecial,
    deleteSpecial,
    isRequesting,
    isUpdating,
    lastGetError,
    lastUpdateError,
    specials,
    currentPage,
    pages,
    count,

    products,
    isSearching,
    lastSearchError,
    searchCount,
    doProductSearch
  };
};

export default useSpecials;
