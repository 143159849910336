import { Box, Heading } from '@chakra-ui/react';
import { Routes, Route } from 'react-router-dom';

import Blog from 'components/blog';
import BlogViewer from 'components/blog/Viewer';
import BlogEditor from 'components/blog/Editor';

import CmsUsers from 'components/cmsUsers';
import CmsUserViewer from 'components/cmsUsers/Viewer';
import CmsUserEditor from 'components/cmsUsers/Editor';

import ContactSubmissions from 'components/contactSubmissions';
// import ContactSubmissionsEditor from 'components/contactSubmissions/Editor';
import ContactSubmissionViewer from 'components/contactSubmissions/Viewer';

import Customers from 'components/customers';

import Locations from 'components/locations';
import LocationEditor from 'components/locations/Editor';

import News from 'components/news';
import NewsViewer from 'components/news/Viewer';
import NewsEditor from 'components/news/Editor';

import Products from 'components/products';
import ProductsReport from 'components/products/Report';
import ProductEditor from 'components/products/Editor';
import ProductViewer from 'components/products/Viewer';

import ProductFilters from 'components/productFilters';
import ProductFilterEditor from 'components/productFilters/Editor';
import ProductFilterViewer from 'components/productFilters/Viewer';

import Specials from 'components/specials';
import SpecialsEditor from 'components/specials/Editor';
import SpecialsViewer from 'components/specials/Viewer';

import Tags from 'components/tags';
import TagEditor from 'components/tags/Editor';
import TagViewer from 'components/tags/Viewer';

import SettingsEditor from 'components/settings/Editor';

import SiteContent from 'components/siteContent';
import SiteContentEditor from 'components/siteContent/Editor';
import SiteContentViewer from 'components/siteContent/Viewer';

const NotFound = () => (
  <Box>
    <Heading size='xl' my={20} textAlign='center'>
      Sorry, that page was not found.
      <br />
      <br />
      Please select another option.
    </Heading>
  </Box>
);

const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/blog' element={<Blog />} />
      <Route path='/blog/add' element={<BlogEditor />} />
      <Route path='/blog/edit/:id' element={<BlogEditor />} />
      <Route path='/blog/:id' element={<BlogViewer />} />

      <Route path='/cms-users' element={<CmsUsers />} />
      <Route path='/cms-users/add' element={<CmsUserEditor />} />
      <Route path='/cms-users/edit/:id' element={<CmsUserEditor />} />
      <Route path='/cms-users/:id' element={<CmsUserViewer />} />

      <Route path='/contact/:type' element={<ContactSubmissions />} />
      <Route path='/contact/:type/:id' element={<ContactSubmissionViewer />} />

      <Route path='/customers' element={<Customers />} />
      <Route path='/customers/:id' element={<Customers />} />

      <Route path='/filters' element={<ProductFilters />} />
      <Route path='/filters/add' element={<ProductFilterEditor />} />
      <Route path='/filters/edit/:id' element={<ProductFilterEditor />} />
      <Route path='/filters/:id' element={<ProductFilterViewer />} />

      <Route path='/locations' element={<Locations />} />
      <Route path='/locations/edit/:id' element={<LocationEditor />} />

      <Route path='/news' element={<News />} />
      <Route path='/news/add' element={<NewsEditor />} />
      <Route path='/news/edit/:id' element={<NewsEditor />} />
      <Route path='/news/:id' element={<NewsViewer />} />

      <Route path='/products' element={<Products />} />
      <Route path='/products/report' element={<ProductsReport />} />
      <Route path='/products/edit/:id' element={<ProductEditor />} />
      <Route path='/products/:id' element={<ProductViewer />} />

      <Route path='/settings' element={<SettingsEditor />} />

      <Route path='/site-content' element={<SiteContent />} />
      <Route path='/site-content/add' element={<SiteContentEditor />} />
      <Route path='/site-content/edit/:id' element={<SiteContentEditor />} />
      <Route path='/site-content/:id' element={<SiteContentViewer />} />

      <Route path='/specials' element={<Specials />} />
      <Route path='/specials/add/:type' element={<SpecialsEditor />} />
      <Route path='/specials/edit/:type/:id' element={<SpecialsEditor />} />
      <Route path='/specials/:id' element={<SpecialsViewer />} />

      <Route path='/tags' element={<Tags />} />
      <Route path='/tags/add' element={<TagEditor />} />
      <Route path='/tags/edit/:id' element={<TagEditor />} />
      <Route path='/tags/:id' element={<TagViewer />} />

      <Route path='/' element={<Heading size='lg'>Please select a navigation option</Heading>} />

      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
