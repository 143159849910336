import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  getArticles,
  getArticleById,
  saveData,
  deleteArticle as _deleteArticle
} from 'redux/actions/articles';

const useNews = () => {
  const dispatch = useDispatch();

  const news = useSelector(state => state.articles?.list.news, shallowEqual);
  const isRequesting = useSelector(state => state.articles?.isRequesting);
  const isUpdating = useSelector(state => state.articles?.isUpdating);
  const lastGetError = useSelector(state => state.articles?.lastGetError);
  const lastUpdateError = useSelector(state => state.articles?.lastUpdateError);
  const currentPage = useSelector(state => state.articles?.page.news, shallowEqual);
  const pages = useSelector(state => state.articles?.pages.news, shallowEqual);
  const count = useSelector(state => state.articles?.count.news, shallowEqual);

  const getNewsList = useCallback(
    (params = {}) => {
      if (!isRequesting) {
        const { page, limit = 25, filter = {}, sort } = params;
        filter.type = 'news';
        dispatch(getArticles({ page, limit, filter, sort }));
      }
    },
    [dispatch, isRequesting]
  );

  const getNews = useCallback(
    id => {
      if (id && !isRequesting) {
        const myArticle = news?.find(art => art._id === id);
        if (!myArticle) {
          dispatch(getArticleById(id));
        }
      }
    },
    [dispatch, isRequesting, news]
  );

  const saveNews = useCallback(
    ({ data, article }) => {
      data.type = 'news';
      dispatch(saveData({ data, article }));
    },
    [dispatch]
  );

  const deleteNews = useCallback(
    ({ article }) => {
      dispatch(_deleteArticle({ article, type: 'news' }));
    },
    [dispatch]
  );

  return {
    getNewsList,
    getNews,
    saveNews,
    deleteNews,
    isRequesting,
    isUpdating,
    lastGetError,
    lastUpdateError,
    news,
    currentPage,
    pages,
    count
  };
};

export default useNews;
