import axios from 'axios';

import {
  SPECIALS_LIST_REQUEST,
  SPECIALS_LIST_RESPONSE,
  SPECIALS_LIST_ERROR,
  SPECIAL_GET_REQUEST,
  SPECIAL_GET_RESPONSE,
  SPECIAL_GET_ERROR,
  SPECIAL_ADD_REQUEST,
  SPECIAL_ADD_RESPONSE,
  SPECIAL_ADD_ERROR,
  SPECIAL_UPDATE_REQUEST,
  SPECIAL_UPDATE_RESPONSE,
  SPECIAL_UPDATE_ERROR,
  SPECIAL_DELETE_REQUEST,
  SPECIAL_DELETE_RESPONSE,
  SPECIAL_DELETE_ERROR
} from 'redux/reducers/specials';

import { getQueryFilter } from 'utils/request';

export const getSpecials = ({
  page = 1,
  limit,
  filter,
  sort = { field: 'startDate', dir: -1 }
}) => {
  return async dispatch => {
    dispatch({ type: SPECIALS_LIST_REQUEST });
    try {
      const params = { page, limit, filter: getQueryFilter(filter) };
      if (sort) {
        params.sort = JSON.stringify(sort);
      }
      const response = await axios.get('/api/data/list/specials', {
        params
      });
      if (response?.data?.list?.length) {
        dispatch({
          type: SPECIALS_LIST_RESPONSE,
          specialType: filter.type,
          data: response?.data?.list,
          pages: response?.data?.pages || 0,
          count: response?.data?.count || 0,
          // response,
          page
        });
      } else {
        dispatch({
          type: SPECIALS_LIST_ERROR,
          specialType: filter.type,
          data: 'No specials found'
        });
      }
    } catch (error) {
      dispatch({ type: SPECIALS_LIST_ERROR, specialType: filter.type, data: error.message });
    }
  };
};

export const getSpecialById = id => {
  return async dispatch => {
    // dispatch, getState
    dispatch({ type: SPECIAL_GET_REQUEST });
    try {
      const response = await axios.get(`/api/data/get/specials/${id}`);
      dispatch({
        type: SPECIAL_GET_RESPONSE,
        data: response?.data
      });
    } catch (error) {
      dispatch({ type: SPECIAL_GET_ERROR, data: error.message });
    }
  };
};

// export const updateSpecialStatus = ({ special, status }) => {
//   return async (dispatch, getState) => {
//     if (!special || typeof status === 'undefined') {
//       console.error('error, missing special or status, cannot update');
//       return;
//     }
//     const specials = getState()?.specials.list;
//     const mySpecial = specials.find(c => c._id === special);
//     dispatch({ type: SPECIAL_UPDATE_REQUEST, special });
//     try {
//       const response = await axios.post('/api/data/update/specials', {
//         id: special,
//         data: { enabled: status }
//       });
//       if (response?.data?.result) {
//         mySpecial.enabled = status;
//       }
//       dispatch({ type: SPECIAL_UPDATE_RESPONSE, data: mySpecial });
//     } catch (error) {
//       dispatch({ type: SPECIAL_UPDATE_ERROR, data: error });
//     }
//   };
// };

export const saveData = ({ data, special }) => {
  return async (dispatch, getState) => {
    if (!data) {
      console.error('error, missing data, cannot update');
      return;
    }

    // if (!data.authorId) {
    //   data.authorId = getState().adminUser.user._id;
    // }

    const params = {
      doc: true,
      data
    };
    let apiPathAction = 'add',
      requestAction = SPECIAL_ADD_REQUEST,
      responseAction = SPECIAL_ADD_RESPONSE,
      errorAction = SPECIAL_ADD_ERROR;
    if (special) {
      params.id = special;
      apiPathAction = 'update';
      requestAction = SPECIAL_UPDATE_REQUEST;
      responseAction = SPECIAL_UPDATE_RESPONSE;
      errorAction = SPECIAL_UPDATE_ERROR;
    }

    dispatch({ type: requestAction, special });

    let error = '';

    if (!data.label || !data.startDate || !data.endDate || !data.type) {
      error =
        'Missing required properties, make sure to fill out the form completely for the special.';
    }

    if (data.startDate > data.endDate) {
      error = 'Start Date must be before the End Date.';
    }

    if (error) {
      // race condition with this error dispatch??? timeout=1 gives a tick
      setTimeout(() => {
        dispatch({
          type: errorAction,
          data: error
        });
      }, 1);
      return;
    }

    try {
      const response = await axios.post(`/api/data/${apiPathAction}/specials`, params);
      if (response?.data?.result) {
        dispatch({ type: responseAction, data: response?.data?.doc });
      } else {
        dispatch({ type: errorAction, data: 'failed to save the special' });
      }
    } catch (error) {
      dispatch({
        type: errorAction,
        data: error?.response?.data?.message || 'unknown error'
      });
    }
  };
};

export const deleteSpecial = ({ special, type }) => {
  return async dispatch => {
    if (!special) {
      console.error('error, missing special, cannot delete');
      return;
    }
    console.log('ok try to delete special', special);
    dispatch({ type: SPECIAL_DELETE_REQUEST, special });
    try {
      const response = await axios.delete(`/api/data/delete/specials/${special}`);
      console.log('delete special response', response?.data);
      dispatch({ type: SPECIAL_DELETE_RESPONSE, id: special, specialType: type, data: response });
    } catch (error) {
      console.warn('error deleting, dispatch the error', error);
      dispatch({ type: SPECIAL_DELETE_ERROR, data: error });
    }
  };
};
