import axios from 'axios';
import { getQueryFilter } from 'utils/request';
import {
  PRODUCT_SEARCH_REQUEST,
  PRODUCT_SEARCH_RESPONSE,
  PRODUCT_SEARCH_ERROR,
  PRODUCT_SEARCH_CLEAR
} from 'redux/reducers/productSearch';

const searchProductRequest = () => ({
  type: PRODUCT_SEARCH_REQUEST
});

const searchProductResponse = results => {
  return {
    type: PRODUCT_SEARCH_RESPONSE,
    payload: results
  };
};

const searchProductError = error => {
  return {
    type: PRODUCT_SEARCH_ERROR,
    payload: error
  };
};

export const clearSearchResults = () => ({
  type: PRODUCT_SEARCH_CLEAR
});

export const searchProducts = searchTerm => {
  return async dispatch => {
    // dispatch, getState
    dispatch(searchProductRequest());
    try {
      const response = await axios.get(`/api/data/list/products`, {
        params: { filter: getQueryFilter({ search: searchTerm, fuzzy: true }), limit: 15 }
      });

      dispatch(searchProductResponse(response?.data));
    } catch (error) {
      dispatch(searchProductError('failed to get products'));
    }
  };
};
