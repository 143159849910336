import axios from 'axios';

import {
  SETTINGS_CONTAINER_TYPE,
  SETTINGS_GET_REQUEST,
  SETTINGS_GET_RESPONSE,
  SETTINGS_GET_ERROR,
  SETTINGS_INIT_REQUEST,
  SETTINGS_INIT_RESPONSE,
  SETTINGS_INIT_ERROR,
  SETTINGS_UPDATE_REQUEST,
  SETTINGS_UPDATE_RESPONSE,
  SETTINGS_UPDATE_ERROR,
  SETTINGS_UPDATE_LIVE_SITE_RESPONSE,
  SETTINGS_UPDATE_LIVE_SITE_RESET,
  SETTINGS_UPDATE_LIVE_SITE_ERROR
} from 'redux/reducers/settings';

import { getQueryFilter } from 'utils/request';

export const updateLiveSiteSettingsReset = () => ({
  type: SETTINGS_UPDATE_LIVE_SITE_RESET
});

export const updateLiveSiteSettings = () => {
  return async dispatch => {
    dispatch({ type: SETTINGS_UPDATE_REQUEST });
    try {
      const response = await axios.get(`/api/services/update-live-site-settings`);
      console.log('update settings response', response.data);
      const msg = response.data?.success
        ? 'Live site settings updated'
        : 'Error updating, try again... If this continues to fail, contact Kent.';
      dispatch({
        type: SETTINGS_UPDATE_LIVE_SITE_RESPONSE,
        data: msg
      });
    } catch (error) {
      dispatch({
        type: SETTINGS_UPDATE_LIVE_SITE_ERROR,
        data: error.message + ' ... If this continues to fail, contact Kent.'
      });
    }
  };
};

export const getSettings = () => {
  return async dispatch => {
    dispatch({ type: SETTINGS_GET_REQUEST });
    try {
      const response = await axios.get(`/api/data/query/content`, {
        params: { filter: getQueryFilter({ type: SETTINGS_CONTAINER_TYPE }) }
      });
      dispatch({
        type: SETTINGS_GET_RESPONSE,
        data: response?.data
      });
    } catch (error) {
      dispatch({ type: SETTINGS_GET_ERROR, data: error.message });
    }
  };
};

export const initSettings = data => {
  return async dispatch => {
    dispatch({ type: SETTINGS_INIT_REQUEST });
    try {
      const response = await axios.post(`/api/data/init/content`, {
        doc: true,
        data: { ...data, type: SETTINGS_CONTAINER_TYPE }
      });
      if (response?.data?.result) {
        dispatch({
          type: SETTINGS_INIT_RESPONSE,
          data: response?.data?.doc
        });
      } else {
        dispatch({ type: SETTINGS_INIT_ERROR, data: 'failed to init the settings container' });
      }
    } catch (error) {
      dispatch({ type: SETTINGS_INIT_ERROR, data: error.message });
    }
  };
};

export const saveData = ({ data, id }) => {
  return async dispatch => {
    if (!data) {
      console.error('error, missing data, cannot update');
      return;
    }

    if (!id) {
      console.error('error, missing settings id for saveData');
      return;
    }

    dispatch({ type: SETTINGS_UPDATE_REQUEST });
    try {
      const response = await axios.post('/api/data/update/content', {
        id,
        doc: true,
        data
      });
      if (response?.data?.result) {
        dispatch({ type: SETTINGS_UPDATE_RESPONSE, data: response?.data?.doc });
      } else {
        dispatch({ type: SETTINGS_UPDATE_ERROR, data: 'failed to update the settings' });
      }
    } catch (error) {
      dispatch({
        type: SETTINGS_UPDATE_ERROR,
        data: error?.response?.data?.message || 'unknown error'
      });
    }
  };
};
