import axios from 'axios';

import {
  CONTACTSUBMISSIONS_LIST_REQUEST,
  CONTACTSUBMISSIONS_LIST_RESPONSE,
  CONTACTSUBMISSIONS_LIST_ERROR,
  CONTACTSUBMISSION_GET_REQUEST,
  CONTACTSUBMISSION_GET_RESPONSE,
  CONTACTSUBMISSION_GET_ERROR,
  CONTACTSUBMISSION_UPDATE_REQUEST,
  CONTACTSUBMISSION_UPDATE_RESPONSE,
  CONTACTSUBMISSION_UPDATE_ERROR,
  CONTACTSUBMISSION_DELETE_REQUEST,
  CONTACTSUBMISSION_DELETE_RESPONSE,
  CONTACTSUBMISSION_DELETE_ERROR
} from 'redux/reducers/contactSubmissions';

import { getQueryFilter } from 'utils/request';

export const getContactSubmissions = ({ page = 1, limit, filter, sort }) => {
  return async dispatch => {
    dispatch({ type: CONTACTSUBMISSIONS_LIST_REQUEST });
    try {
      const params = { page, limit, filter: getQueryFilter(filter) };
      if (sort) {
        params.sort = JSON.stringify(sort);
      }
      const response = await axios.get('/api/data/list/contact-submissions', {
        params
      });
      if (response?.data?.list?.length) {
        dispatch({
          type: CONTACTSUBMISSIONS_LIST_RESPONSE,
          contactSubmissionType: filter.type,
          data: response?.data?.list,
          pages: response?.data?.pages || 0,
          count: response?.data?.count || 0,
          // response,
          page
        });
      } else {
        dispatch({
          type: CONTACTSUBMISSIONS_LIST_ERROR,
          contactSubmissionType: filter.type,
          data: 'No news contactSubmissions found'
        });
      }
    } catch (error) {
      dispatch({
        type: CONTACTSUBMISSIONS_LIST_ERROR,
        contactSubmissionType: filter.type,
        data: error.message
      });
    }
  };
};

export const getContactSubmissionById = id => {
  return async dispatch => {
    // dispatch, getState
    dispatch({ type: CONTACTSUBMISSION_GET_REQUEST });
    try {
      const response = await axios.get(`/api/data/get/contact-submissions/${id}`);
      dispatch({
        type: CONTACTSUBMISSION_GET_RESPONSE,
        data: response?.data
      });
    } catch (error) {
      console.log('error getting submission data', error);
      dispatch({ type: CONTACTSUBMISSION_GET_ERROR, data: error.message });
    }
  };
};

export const updateContactSubmissionStatus = ({ contactSubmission, status }) => {
  return async (dispatch, getState) => {
    if (!contactSubmission || typeof status === 'undefined') {
      console.error('error, missing contactSubmission or status, cannot update');
      return;
    }
    const contactSubmissions = getState()?.contactSubmissions.list;
    const myContactSubmission = contactSubmissions.find(c => c._id === contactSubmission);
    dispatch({ type: CONTACTSUBMISSION_UPDATE_REQUEST, contactSubmission });
    try {
      const response = await axios.post('/api/data/update/contact-submissions', {
        id: contactSubmission,
        data: { enabled: status }
      });
      if (response?.data?.result) {
        myContactSubmission.enabled = status;
      }
      dispatch({ type: CONTACTSUBMISSION_UPDATE_RESPONSE, data: myContactSubmission });
    } catch (error) {
      dispatch({ type: CONTACTSUBMISSION_UPDATE_ERROR, data: error });
    }
  };
};

export const saveData = ({ data, contactSubmission }) => {
  return async dispatch => {
    if (!data) {
      console.error('error, missing data, cannot update');
      return;
    }

    if (!contactSubmission) {
      console.error(
        'error, no contactSubmission id sent with saveData call, contactSubmissions are update-only in the CMS'
      );
      return;
    }

    const params = {
      id: contactSubmission,
      doc: true,
      data
    };

    dispatch({ type: CONTACTSUBMISSION_UPDATE_REQUEST, contactSubmission });

    if (!data.title || !data.body) {
      // race condition with this error dispatch??? timeout=1 gives a tick
      setTimeout(() => {
        dispatch({
          type: CONTACTSUBMISSION_UPDATE_ERROR,
          data: 'Missing required properties, make sure to add at least a title and body for the post.'
        });
      }, 1);
      return;
    }

    try {
      const response = await axios.post(`/api/data/update/contact-submissions`, params);
      if (response?.data?.result) {
        dispatch({ type: CONTACTSUBMISSION_UPDATE_RESPONSE, data: response?.data?.doc });
      } else {
        dispatch({
          type: CONTACTSUBMISSION_UPDATE_ERROR,
          data: 'failed to save the contactSubmission'
        });
      }
    } catch (error) {
      dispatch({
        type: CONTACTSUBMISSION_UPDATE_ERROR,
        data: error?.response?.data?.message || 'unknown error'
      });
    }
  };
};

export const deleteContactSubmission = ({ contactSubmission, type }) => {
  return async dispatch => {
    if (!contactSubmission) {
      console.error('error, missing contactSubmission, cannot delete');
      return;
    }
    console.log('ok try to delete contactSubmission', contactSubmission);
    dispatch({ type: CONTACTSUBMISSION_DELETE_REQUEST, contactSubmission });
    try {
      const response = await axios.delete(
        `/api/data/delete/contact-submissions/${contactSubmission}`
      );
      console.log('delete contactSubmission response', response?.data);
      dispatch({
        type: CONTACTSUBMISSION_DELETE_RESPONSE,
        id: contactSubmission,
        contactSubmissionType: type,
        data: response
      });
    } catch (error) {
      console.warn('error deleting, dispatch the error', error);
      dispatch({ type: CONTACTSUBMISSION_DELETE_ERROR, data: error });
    }
  };
};
