import { Box, Button, Flex, HStack, Input, Select, Text } from '@chakra-ui/react';
import { sortArrayByProp } from 'utils/sort';

const InputManager = ({ currentTag, handleUpdateEditorTag, placeholder, tagSet, tags }) => {
  if (tagSet?.length) {
    const tagList = tagSet
      .filter(tag => !tags?.find(t => t === tag._id))
      .sort(sortArrayByProp(t => t.type || t.name.trim()));
    if (!tagList?.length) {
      return <Text>Nothing left to add.</Text>;
    }
    return (
      <Select
        name='type'
        placeholder='Choose one'
        onChange={handleUpdateEditorTag}
        value={currentTag || ''}
        size='sm'
      >
        {tagList.map((tag, idx) => {
          let { name } = tag;
          if (tag.type) {
            name = `${tag.type}: ${name}`;
          }
          return (
            <option key={idx} value={tag._id}>
              {name}
            </option>
          );
        })}
      </Select>
    );
  } else if (tagSet === undefined) {
    // else, default to string input
    return (
      <Input
        type='text'
        name='add-tag'
        value={currentTag || ''}
        onChange={handleUpdateEditorTag}
        size='sm'
        placeholder={placeholder}
        mb='0'
      />
    );
  } else {
    return <Text>tagSet is empty, nothing to do</Text>;
  }
};

const TagsManager = ({
  addLabel = '+ Add Tag',
  placeholder = 'use to link or share content blocks',
  currentTag,
  handleUpdateEditorTag,
  handleAddEditorTag,
  handleRemoveEditorTag,
  tagSet,
  labelResolver,
  tags
}) => {
  const tagList = tagSet
    ?.filter(tag => !tags?.find(t => t === tag._id))
    .sort(sortArrayByProp(t => t.type || t.name.trim()));

  return (
    <>
      <HStack spacing='2' justifyContent='center' mb='8px'>
        <InputManager
          currentTag={currentTag}
          handleUpdateEditorTag={handleUpdateEditorTag}
          placeholder={placeholder}
          tagSet={tagSet}
          tags={tags}
        />
        {tagList?.length || tagSet === undefined ? (
          <Button size='xs' onClick={handleAddEditorTag} fontWeight='normal' px='14px'>
            {addLabel}
          </Button>
        ) : null}
      </HStack>
      <Flex flexWrap='wrap'>
        {tags?.map((tag, idx) => {
          return (
            <Box
              key={idx}
              border='1px solid #ddd'
              px='4px'
              fontSize='sm'
              borderRadius='4px'
              backgroundColor='#f2f2f2'
              display='inline-block'
              position='relative'
              ml='3px'
              mb='3px'
            >
              <Text as='span' display='inline-block' mr='14px' mb='2px'>
                {labelResolver ? labelResolver(tag) : tag}
              </Text>
              <Box
                position='absolute'
                color='red'
                top='2px'
                right='2px'
                fontWeight='bold'
                p='2px'
                width='12px'
                height='14px'
                lineHeight='8px'
                fontSize='12px'
                cursor='pointer'
                textAlign='center'
                _hover={{
                  backgroundColor: '#fff'
                }}
                onClick={() => handleRemoveEditorTag(tag)}
              >
                x
              </Box>
            </Box>
          );
        })}
      </Flex>
    </>
  );
};

export default TagsManager;
