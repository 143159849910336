import { Button, ButtonGroup, Flex, Heading } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { specialsTypes, getSpecialsLabel } from 'constants';

import List from './List';

const News = () => {
  const navigate = useNavigate();
  const [specialsType, setSpecialsType] = useState('dog');
  const label = getSpecialsLabel(specialsType);
  return (
    <Flex flexDirection='column'>
      <Flex justifyContent='space-between'>
        <Heading>Product Specials</Heading>
        <ButtonGroup>
          {specialsTypes.map((type, idx) => {
            return (
              <Button
                key={idx}
                variant={specialsType === type[1] ? 'solid' : 'ghost'}
                onClick={() => setSpecialsType(type[1])}
              >
                {type[0]}
              </Button>
            );
          })}
          {/* <Button
            variant={specialsType === 'dog' ? 'solid' : 'ghost'}
            onClick={() => setSpecialsType('dog')}
          >
            Deals On Green
          </Button>
          <Button
            variant={specialsType === 'popular' ? 'solid' : 'ghost'}
            onClick={() => setSpecialsType('popular')}
          >
            Popular Products
          </Button> */}
        </ButtonGroup>
        <Flex alignItems='flex-end' gap='10px'>
          <Button onClick={() => navigate(`/specials/add/${specialsType}`)}>
            + Add New {label}
          </Button>
        </Flex>
      </Flex>
      <List type={specialsType} typeLabel={label} />
    </Flex>
  );
};

export default News;
