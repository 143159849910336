export const CMS_USERS_LIST_REQUEST = 'CMS_USERS_LIST_REQUEST';
export const CMS_USERS_LIST_RESPONSE = 'CMS_USERS_LIST_RESPONSE';
export const CMS_USERS_LIST_ERROR = 'CMS_USERS_LIST_ERROR';
export const CMS_USERS_LIST_RESET = 'CMS_USERS_LIST_RESET';
export const CMS_USERS_GET_REQUEST = 'CMS_USERS_GET_REQUEST';
export const CMS_USERS_GET_RESPONSE = 'CMS_USERS_GET_RESPONSE';
export const CMS_USERS_GET_ERROR = 'CMS_USERS_GET_ERROR';
export const CMS_USERS_ADD_REQUEST = 'CMS_USERS_ADD_REQUEST';
export const CMS_USERS_ADD_RESPONSE = 'CMS_USERS_ADD_RESPONSE';
export const CMS_USERS_ADD_ERROR = 'CMS_USERS_ADD_ERROR';
export const CMS_USERS_UPDATE_REQUEST = 'CMS_USERS_UPDATE_REQUEST';
export const CMS_USERS_UPDATE_RESPONSE = 'CMS_USERS_UPDATE_RESPONSE';
export const CMS_USERS_UPDATE_ERROR = 'CMS_USERS_UPDATE_ERROR';
// export const CMS_USERS_DELETE_REQUEST = 'CMS_USERS_DELETE_REQUEST';
// export const CMS_USERS_DELETE_RESPONSE = 'CMS_USERS_DELETE_RESPONSE';
// export const CMS_USERS_DELETE_ERROR = 'CMS_USERS_DELETE_ERROR';

const initialState = {
  isUpdating: false,
  isRequesting: false,
  lastListResponse: null,
  lastListError: null,
  lastGetResponse: null,
  lastGetError: null,
  lastUpdateResponse: null,
  lastUpdateError: null,
  page: 1,
  pages: 0,
  count: 0,
  list: []
};

const cmsUsers = (state = initialState, action) => {
  let list = [...state.list];
  switch (action?.type) {
    case CMS_USERS_LIST_REQUEST:
      return {
        ...state,
        isRequesting: true,
        lastListResponse: null,
        lastListError: null
      };
    case CMS_USERS_LIST_RESPONSE:
      list = action?.data;
      return {
        ...state,
        list,
        page: action?.page || 1,
        pages: action?.pages || 0,
        count: action?.count || 0,
        isRequesting: false,
        lastListResponse: action?.response,
        lastListError: null
      };
    case CMS_USERS_LIST_ERROR:
      return {
        ...state,
        list: [],
        page: 1,
        pages: 0,
        count: 0,
        isRequesting: false,
        lastListResponse: null,
        lastListError: action?.data
      };
    case CMS_USERS_LIST_RESET:
      return {
        ...state,
        isRequesting: false,
        lastListResponse: null,
        lastListError: null,
        list: [],
        page: 1,
        pages: 0,
        count: 0
      };
    case CMS_USERS_GET_REQUEST:
      return {
        ...state,
        isRequesting: true,
        lastGetResponse: null,
        lastGetError: null
      };
    case CMS_USERS_GET_RESPONSE:
      const cmsUsers = action?.data;
      const existsIndex = list.findIndex(loc => loc._id === cmsUsers.id);
      if (existsIndex > -1) {
        list[existsIndex] = cmsUsers;
      } else {
        list.push(cmsUsers);
      }
      return {
        ...state,
        list,
        isRequesting: false,
        lastGetResponse: cmsUsers,
        lastGetError: null
      };
    case CMS_USERS_GET_ERROR:
      return {
        ...state,
        isRequesting: false,
        lastGetResponse: null,
        lastGetError: action?.data
      };

    case CMS_USERS_ADD_REQUEST:
    case CMS_USERS_UPDATE_REQUEST:
      // case CMS_USERS_DELETE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        lastUpdateResponse: null,
        lastUpdateError: null
      };
    case CMS_USERS_ADD_RESPONSE:
      const addedCmsUser = action?.data;
      if (addedCmsUser) {
        list.push(addedCmsUser);
      }
      return {
        ...state,
        list,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case CMS_USERS_UPDATE_RESPONSE:
      const updatedCmsUser = action?.data;
      if (updatedCmsUser) {
        list = list.map(cmsUsers => {
          if (cmsUsers._id === updatedCmsUser._id) {
            return updatedCmsUser;
          }
          return cmsUsers;
        });
      }
      return {
        ...state,
        list,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    // case CMS_USERS_DELETE_RESPONSE:
    //   const { id: deletedContent } = action;
    //   if (deletedContent) {
    //     list = list.filter(cmsUsers => cmsUsers._id !== deletedContent);
    //   } else {
    //     console.error('delete response received, but no cmsUsers in action.data??', action);
    //   }
    //   return {
    //     ...state,
    //     list,
    //     isUpdating: false,
    //     lastUpdateResponse: action?.response,
    //     lastUpdateError: null
    //   };
    case CMS_USERS_ADD_ERROR:
    case CMS_USERS_UPDATE_ERROR:
      // case CMS_USERS_DELETE_ERROR:
      return {
        ...state,
        isUpdating: false,
        lastUpdateResponse: null,
        lastUpdateError: action?.data
      };
    default:
      return state;
  }
};

export default cmsUsers;
