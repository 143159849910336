// borrowed from git: https://gist.github.com/ca0v/73a31f57b397606c9813472f7493a940?permalink_comment_id=3062135#gistcomment-3062135
export const debounce = (func, waitFor) => {
  let timeout;

  return (...args) =>
    new Promise(resolve => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => resolve(func(...args)), waitFor);
    });
};

// borrowed from git: https://gist.github.com/ca0v/73a31f57b397606c9813472f7493a940?permalink_comment_id=3104895#gistcomment-3104895
export const throttle = (func, waitFor) => {
  const now = () => new Date().getTime();
  const resetStartTime = () => (startTime = now());
  let timeout;
  let startTime = now() - waitFor;

  return (...args) =>
    new Promise(resolve => {
      const timeLeft = startTime + waitFor - now();
      if (timeout) {
        clearTimeout(timeout);
      }
      if (startTime + waitFor <= now()) {
        resetStartTime();
        resolve(func(...args));
      } else {
        timeout = setTimeout(() => {
          resetStartTime();
          resolve(func(...args));
        }, timeLeft);
      }
    });
};
