import { useEffect, useRef } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Heading,
  // Text,
  // Checkbox,
  FormControl,
  FormLabel,
  Input,
  // Link,
  // Table,
  // Tbody,
  // Tr,
  // Td,
  // Thead,
  ButtonGroup,
  Spinner,
  Checkbox,
  Select
} from '@chakra-ui/react';

import useEditor from 'hooks/useEditor';
import useSiteContent from 'hooks/useSiteContent';
import SaveCancelDialogue from 'components/segments/CancelDialogue';
import TagsManager from 'components/segments/TagsManager';
import RichContentBlock from 'components/segments/RichContentBlock';
import FileUploadSingleImage from 'components/segments/FileUploadSingleImage';

const getInitialData = () => {
  return {
    enabled: true
  };
};

const Editor = () => {
  const { id } = useParams();
  const {
    siteContent,
    currentCopy,
    isRequesting,
    isUpdating,
    lastGetError,
    lastUpdateError,
    getSiteContent,
    saveSiteContent,
    clearCopyData
  } = useSiteContent();
  const navigate = useNavigate();
  const hasSetData = useRef();
  const isGettingData = useRef();
  const [params] = useSearchParams();

  const {
    data,
    changesDetected,
    handleUpdateEditorData,
    // handleCheckboxClick,
    setFormData,
    handleSave,
    handleStartCancel,
    handleUpdateEditorDataDirect,
    handleRichContentBlockChange,
    handleCheckboxClick,
    handleFileUpload,
    currentTag,
    handleAddEditorTag,
    handleRemoveEditorTag,
    handleUpdateEditorTag
  } = useEditor({
    initialData: getInitialData(),
    isUpdating,
    lastUpdateError,
    saveFunc: data => saveSiteContent({ data, siteContent: id }),
    saveLabel: 'Content Block',
    isEditing: !!id
  });

  console.log('what we got here', params.get('copy'), currentCopy);

  useEffect(() => {
    if (id && !hasSetData.current && !isRequesting) {
      const editSiteContent = siteContent?.find(l => l._id === id);
      if (editSiteContent) {
        hasSetData.current = true;
        isGettingData.current = false;
        console.log('setting form data to:', editSiteContent);
        setFormData(editSiteContent);
      } else {
        if (!lastGetError && !isGettingData.current) {
          isGettingData.current = true;
          getSiteContent(id);
        }
      }
    } else if (!id && params.get('copy') && currentCopy) {
      console.log('ok set the copy....', currentCopy);
      setFormData({ ...currentCopy });
      clearCopyData();
    }
  }, [
    id,
    siteContent,
    getSiteContent,
    setFormData,
    isRequesting,
    lastGetError,
    params,
    currentCopy,
    clearCopyData
  ]);

  if (isRequesting) {
    return (
      <Box>
        <Heading mb='20px' size='lg'>
          Loading, one moment please...
        </Heading>
        <Spinner size='xl' />
      </Box>
    );
  }

  return (
    <Box>
      <Heading mb='20px' size='lg'>
        {id ? `Editing Page Block: ${data.block}` : 'Add a new Page Block'}
      </Heading>

      <Flex gap='20px' mb='20px'>
        <Box flex='1' align='flex-start'>
          <FormControl>
            <FormLabel mb='0' fontSize='sm'>
              Block Name
            </FormLabel>
            <Input
              type='text'
              name='block'
              value={data?.block || ''}
              onChange={handleUpdateEditorData}
              size='sm'
              placeholder='Block Name'
            />
          </FormControl>
          <Flex gap='20px'>
            <FormControl flex='1'>
              <FormLabel mb='0' fontSize='sm'>
                Content Slug
              </FormLabel>
              <Input
                type='text'
                name='slug'
                value={data?.slug || ''}
                onChange={handleUpdateEditorData}
                size='sm'
                placeholder='Content Slug (used to link to site)'
              />
            </FormControl>
            <FormControl flex='1'>
              <FormLabel mb='0' fontSize='sm'>
                Block Order
              </FormLabel>
              <Input
                type='text'
                name='sortOrder'
                value={data?.sortOrder || ''}
                onChange={handleUpdateEditorData}
                size='sm'
                placeholder='display order, optional (1-100)'
              />
            </FormControl>
            <FormControl flex='0.25'>
              <FormLabel mb='0' fontSize='sm'>
                Enabled?
              </FormLabel>
              <Checkbox
                name='enabled'
                isChecked={!!data?.enabled}
                value='true'
                onChange={handleCheckboxClick}
              >
                Yes
              </Checkbox>
            </FormControl>
          </Flex>
          <FormControl>
            <FormLabel mb='0' fontSize='sm'>
              Tags
            </FormLabel>
            <TagsManager
              currentTag={currentTag}
              handleUpdateEditorTag={handleUpdateEditorTag}
              handleAddEditorTag={handleAddEditorTag}
              handleRemoveEditorTag={handleRemoveEditorTag}
              tags={data?.tags}
            />
          </FormControl>

          <Heading as='h2' size='md' mt='12px'>
            Image for this content block: [{data?.imageId}]
          </Heading>
          <FileUploadSingleImage
            data={data}
            handleUpdateEditorDataDirect={handleUpdateEditorDataDirect}
            handleFileUpload={handleFileUpload}
            maxPreviewWidth='300px'
            promptLabel='Select image for the block'
            selectedFileLabel='block image'
            actionLabel='Select image'
            maxFileSize={1024 * 1024 * 2}
          />
          {data?.imageId ? (
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Focus on:
              </FormLabel>
              <Select
                name='imagePos'
                placeholder='choose'
                onChange={handleUpdateEditorData}
                value={data?.imagePos || ''}
                size='sm'
              >
                <option value='top'>Top</option>
                <option value='center'>Center</option>
                <option value='bottom'>Bottom</option>
              </Select>
            </FormControl>
          ) : null}

          <Box mt='20px'>
            <ButtonGroup>
              <Button onClick={handleSave} disabled={!changesDetected}>
                Save
              </Button>
              <SaveCancelDialogue
                handleSave={handleSave}
                handleStartCancel={handleStartCancel}
                handleDiscard={() => navigate(-1)}
                cancelLabel='Back'
              />
            </ButtonGroup>
          </Box>
        </Box>
        <Box flex='1'>
          <FormControl>
            <FormLabel mb='0' fontSize='sm'>
              Display Header / Title (optional, would appear as Title for a text block)
            </FormLabel>
            <Input
              type='text'
              name='header'
              value={data?.header || ''}
              onChange={handleUpdateEditorData}
              size='sm'
              placeholder='Header to show on the content block'
            />
          </FormControl>
          <FormControl>
            <FormLabel mb='0' fontSize='sm'>
              Block Text Content
            </FormLabel>
            <RichContentBlock
              data={data?.blockContent}
              name='blockContent'
              onChange={handleRichContentBlockChange}
            />
          </FormControl>
        </Box>
      </Flex>
    </Box>
  );
};

export default Editor;
