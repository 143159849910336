import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Heading } from '@chakra-ui/react';

const RichContentBlock = ({ data = '', name, onReady, onChange, onBlur, onFocus }) => {
  if (!name || !onChange) {
    return (
      <Heading>config error, RichContentBlock needs a name and onChange handler at minimum</Heading>
    );
  }

  return (
    <CKEditor
      editor={ClassicEditor}
      data={data}
      onReady={editor => {
        onReady && onReady(name, editor);
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        console.log({ event, editor, data });
        onChange && onChange(name, data, event);
      }}
      onBlur={(event, editor) => {
        const data = editor.getData();
        onBlur && onBlur(name, data, event);
      }}
      onFocus={(event, editor) => {
        const data = editor.getData();
        onFocus && onFocus(name, data, event);
      }}
    />
  );
};

export default RichContentBlock;
