import { useEffect, useMemo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  Select,
  ButtonGroup,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react';

import useEditor from 'hooks/useEditor';
import useSpecials from 'hooks/useSpecials';
import SaveCancelDialogue from 'components/segments/CancelDialogue';
import { specialsTypes } from 'constants';
import { getSpecialsLabel } from 'constants';
import SearchWidget from './SearchWidget';
import { TableBox } from 'components/segments/StyledComponents';

const getInitialData = () => {
  return {
    enabled: true
  };
};

const Editor = () => {
  const { id, type } = useParams();
  // const [params] = useSearchParams();
  // const type = params.get('type');

  if (!id && !type) {
    throw new Error('specials editor requires an id or type, but neither found. both is better.');
  }

  const typeLabel = useMemo(() => {
    if (type) {
      return getSpecialsLabel(type);
    }
    return 'need a type';
  }, [type]);

  const {
    specials,
    isRequesting,
    isUpdating,
    lastGetError,
    lastUpdateError,
    getSpecial,
    saveSpecial
  } = useSpecials({ type });
  const navigate = useNavigate();
  const hasSetData = useRef();
  const isGettingData = useRef();

  const {
    data,
    changesDetected,
    handleUpdateEditorData,
    handleUpdateEditorDataDirect,
    handleCheckboxClick,
    setFormData,
    handleSave,
    handleStartCancel
  } = useEditor({
    initialData: { ...getInitialData(), type },
    isUpdating,
    lastUpdateError,
    saveFunc: data => saveSpecial({ data, special: id }),
    saveLabel: typeLabel,
    isEditing: !!id
  });

  useEffect(() => {
    if (id && !hasSetData.current && !isRequesting) {
      const editSpecial = specials?.find(l => l._id === id);
      if (editSpecial) {
        hasSetData.current = true;
        isGettingData.current = false;
        console.log('setting form data to:', editSpecial);
        setFormData(editSpecial);
      } else {
        if (!lastGetError && !isGettingData.current) {
          isGettingData.current = true;
          getSpecial(id);
        }
      }
    }
  }, [id, setFormData, isRequesting, lastGetError, specials, getSpecial]);

  const handleSelectProduct = product => {
    console.log('handleSelectProduct, product: ', product);
    const list = [...(data?.products ?? [])];
    const sortOrder = list.length + 1;
    list.push({
      id: product._id,
      name: product.name,
      variants: product.variants,
      saleVariant: product.variants.length === 1 ? 0 : -1,
      salePrice: '',
      sortOrder
    });
    handleUpdateEditorDataDirect({ products: list });
  };

  const handleUpdateProductData = (id, prop, value) => {
    const list = data.products.map(p => {
      if (p.id === id) {
        return {
          ...p,
          [prop]: value
        };
      }
      return p;
    });
    handleUpdateEditorDataDirect({ products: list });
  };

  const handleRemoveProduct = id => {
    const list = data.products
      .filter(p => !(p.id === id))
      .map((p, idx) => {
        p.sortOrder = idx + 1;
        return p;
      });
    handleUpdateEditorDataDirect({ products: list });
  };

  const getVariantSelector = prod => {
    const { id, variants, saleVariant } = prod;
    if (variants.length === 1) {
      return (
        <Text>
          {variants[0].option}, reg: ${variants[0].price}
        </Text>
      );
    }
    return (
      <Select
        name='variantSelector'
        placeholder='choose'
        onChange={e => {
          const {
            target: { value }
          } = e;
          handleUpdateProductData(id, 'saleVariant', value * 1);
        }}
        value={saleVariant + ''}
        size='sm'
        width='200px'
      >
        {variants.map((v, idx) => {
          return (
            <option value={idx + ''} key={idx}>
              {v.option}, ${v.price}
            </option>
          );
        })}
      </Select>
    );
  };

  if (isRequesting) {
    return (
      <Box>
        <Heading mb='20px' size='lg'>
          Loading, one moment please...
        </Heading>
        <Spinner size='xl' />
      </Box>
    );
  }

  return (
    <Box maxW='1200px'>
      <Heading mb='20px' size='lg'>
        {id ? `Editing special: ${data.label}` : 'Add a new special'}
      </Heading>

      <Flex flex='1' gap='20px'>
        <Box flex='0.5' align='flex-start'>
          {id ? (
            <Text fontSize='sm' mb='2'>
              <Text as='strong'>Type:</Text> {typeLabel}
            </Text>
          ) : (
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Type:
              </FormLabel>
              <Select
                name='type'
                placeholder='choose'
                onChange={handleUpdateEditorData}
                value={data?.type || ''}
                size='sm'
              >
                {specialsTypes.map((sp, idx) => {
                  return (
                    <option key={idx} value={sp[1]}>
                      {sp[0]}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          )}{' '}
          <FormControl>
            <FormLabel mb='0' fontSize='sm'>
              Specials Name
            </FormLabel>
            <Input
              type='text'
              name='label'
              value={data?.label || ''}
              onChange={handleUpdateEditorData}
              size='sm'
              placeholder='Filter name'
            />
          </FormControl>
          <Flex flexDirection='row' gap='20px'>
            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                Start Date
              </FormLabel>
              <Input
                type='date'
                name='startDate'
                value={data?.startDate || ''}
                onChange={handleUpdateEditorData}
                size='sm'
              />
            </FormControl>

            <FormControl>
              <FormLabel mb='0' fontSize='sm'>
                End Date
              </FormLabel>
              <Input
                type='date'
                name='endDate'
                value={data?.endDate || ''}
                onChange={handleUpdateEditorData}
                size='sm'
              />
            </FormControl>
          </Flex>
          <FormControl>
            <FormLabel mb='0' fontSize='sm'>
              Enabled?
            </FormLabel>
            <Checkbox
              name='enabled'
              isChecked={!!data?.enabled}
              value='true'
              onChange={handleCheckboxClick}
            >
              Yes
            </Checkbox>
          </FormControl>
          <Box mt='40px'>
            <ButtonGroup>
              <Button onClick={handleSave} disabled={!changesDetected}>
                Save
              </Button>
              <SaveCancelDialogue
                handleSave={handleSave}
                handleStartCancel={handleStartCancel}
                handleDiscard={() => navigate(-1)}
                cancelLabel='Back'
              />
            </ButtonGroup>
          </Box>
        </Box>
        <Box minW='60%' flex='1'>
          <Heading as='h2' size='md'>
            Manage Products for this Specials list
          </Heading>
          {/* <FormControl>
            <FormLabel mb='0' fontSize='sm'>
              Search
            </FormLabel>
            <Input
              name='search'
              value={productSearch || ''}
              onChange={handleUpdateProductSearchField}
              size='sm'
              maxW='400px'
              placeholder='start typing to search by name'
            />
          </FormControl> */}
          <SearchWidget selectProduct={handleSelectProduct} />

          <Text>Products: </Text>
          {data?.products?.length ? (
            <TableBox my='12px' border='1px solid #000'>
              <Table size='sm'>
                <Thead>
                  <Tr>
                    <Th>Product</Th>
                    <Th>Variant</Th>
                    <Th>Sale Price</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.products.map((l, idx) => {
                    return (
                      <Tr key={idx}>
                        <Td>{l.name}</Td>
                        <Td>{getVariantSelector(l)}</Td>
                        <Td width='140px'>
                          $
                          <Input
                            name='salePrice'
                            value={l.salePrice || ''}
                            onChange={e => {
                              const {
                                target: { name, value }
                              } = e;
                              handleUpdateProductData(l.id, name, value);
                            }}
                            size='sm'
                            width='80px'
                            px='8px'
                            ml='4px'
                          />
                        </Td>
                        <Td>
                          <Button size='xs' onClick={() => handleRemoveProduct(l.id)}>
                            Remove
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableBox>
          ) : (
            <Text fontSize='sm'>
              <Text as='em'>No products added yet, use search to find some</Text>
            </Text>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default Editor;
