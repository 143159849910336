export const PRODUCT_SEARCH_REQUEST = 'PRODUCT_SEARCH_REQUEST';
export const PRODUCT_SEARCH_RESPONSE = 'PRODUCT_SEARCH_RESPONSE';
export const PRODUCT_SEARCH_ERROR = 'PRODUCT_SEARCH_ERROR';
export const PRODUCT_SEARCH_CLEAR = 'PRODUCT_SEARCH_CLEAR';

const initialState = {
  isRequesting: false,
  lastSearchResponse: null,
  lastSearchError: null,
  page: 1,
  pages: 0,
  count: 0,
  list: []
};

const productSearch = (state = initialState, action) => {
  switch (action?.type) {
    case PRODUCT_SEARCH_REQUEST:
      return {
        ...state,
        isRequesting: true,
        lastSearchResponse: null,
        lastSearchError: null
      };
    case PRODUCT_SEARCH_RESPONSE:
      console.log('search response', action.payload);
      return {
        ...state,
        list: action?.payload?.list,
        page: action?.payload?.page || 1,
        pages: action?.payload?.pages || 0,
        count: action?.payload?.count || 0,
        isRequesting: false,
        lastSearchResponse: action?.payload,
        lastSearchError: null
      };
    case PRODUCT_SEARCH_ERROR:
      return {
        ...state,
        list: [],
        page: 1,
        pages: 0,
        count: 0,
        isRequesting: false,
        lastSearchResponse: null,
        lastSearchError: action?.data
      };
    case PRODUCT_SEARCH_CLEAR:
      return {
        ...initialState
      };

    default:
      return state;
  }
};

export default productSearch;
