import { configureStore } from '@reduxjs/toolkit';
// import { createStore, applyMiddleware } from 'redux';
// import thunkMiddleware from 'redux-thunk';
// import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import rootReducer from './reducers';

// const composedEnhancer = composeWithDevToolsDevelopmentOnly(applyMiddleware(thunkMiddleware));

// export default createStore(rootReducer, composedEnhancer);

export default configureStore({
  reducer: rootReducer,
  // work-around for the aggressive immutable check:
  // https://stackoverflow.com/questions/64695464/error-invariant-failed-a-state-mutation-was-detected-between-dispatches-in-th
  // TODO: need to finish the migration to Redux Toolkit!
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    })
});
