import { useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import useSiteContent from 'hooks/useSiteContent';
import { sortArrayByProp } from 'utils/sort';

import { TableBox } from 'components/segments/StyledComponents';
import DeleteDialogue from 'components/segments/DeleteDialogue';

const List = () => {
  const listOnLoad = useRef();
  const { siteContent, isRequesting, getSiteContent, deleteSiteContent, copyData } =
    useSiteContent();

  const navigate = useNavigate();

  useEffect(() => {
    if (!listOnLoad.current) {
      listOnLoad.current = true;
      getSiteContent();
    }
  }, [getSiteContent, siteContent]);

  const errorDisplay = useMemo(() => {
    if (!siteContent?.length) {
      const message =
        !listOnLoad.current || isRequesting
          ? 'Loading Site Content List...'
          : 'Sorry, no blocks found';
      return <Heading size='lg'>{message}</Heading>;
    }
    return null;
  }, [siteContent?.length, isRequesting]);

  const handleCopy = id => {
    const copy = siteContent.find(c => c._id === id);
    if (copy) {
      copyData(copy);
      navigate(`/site-content/add?copy=true`);
    }
  };

  return (
    <Box>
      {errorDisplay}
      {!errorDisplay && (
        <TableBox my='12px' border='1px solid #000'>
          <Table size='sm'>
            <Thead>
              <Tr>
                <Th>Block Name</Th>
                <Th>Site Slug</Th>
                <Th textAlign='center'>Has an Image?</Th>
                <Th textAlign='center'>Sort order</Th>
                <Th>Tags</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {siteContent.sort(sortArrayByProp(l => l.block)).map((l, idx) => {
                return (
                  <Tr key={idx}>
                    <Td>{l.block}</Td>
                    <Td>{l.slug}</Td>
                    <Td textAlign='center'>{l.imageId ? 'x' : ''}</Td>
                    <Td textAlign='center'>{l.sortOrder || ''}</Td>
                    <Td>{l.tags?.join(',') || 'no tags'}</Td>
                    <Td>
                      <ButtonGroup spacing='3' size='xs'>
                        <Button onClick={() => navigate(`/site-content/edit/${l._id}`)}>
                          Edit
                        </Button>
                        <Button onClick={() => navigate(`/site-content/${l._id}`)}>View</Button>
                        <Button onClick={() => handleCopy(l._id)}>Copy</Button>
                        <DeleteDialogue
                          label='Delete'
                          deleteConfirmLabel='Delete Block'
                          handleDelete={() => deleteSiteContent({ siteContent: l._id })}
                        />
                      </ButtonGroup>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableBox>
      )}
    </Box>
  );
};

export default List;
