import { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

const useDutchieSync = ({ type, interval, successAction }) => {
  const [isSyncing, setIsSyncing] = useState(false);
  const [syncStatus, setSyncStatus] = useState(null);
  const [syncError, setSyncError] = useState('');

  const poll = () => {
    axios
      .get('/api/dutchie/poll', { params: { type } })
      .then(response => {
        console.log('poll result: ', response);
        if (response.data?.itemsSynced) {
          setSyncStatus(`In progress, synced ${response.data.itemsSynced} items...`);
        }
        if (!response.data.done) {
          setTimeout(poll, interval);
        } else {
          setIsSyncing(false);
          setSyncError('');
          console.log('what is structure?', response.data.results);
          if (response.data.results?.sync) {
            const added = response.data.results.sync.filter(r => r?.type === 'add').length;
            const updated = response.data.results.sync.length - added;
            const toastMessage = `Sync complete!
Processed ${response.data.itemsSynced} items.
Added: ${added}, Updated: ${updated}`;
            console.log(toastMessage);
            toast.success(toastMessage, {
              duration: 10000
            });
            if (successAction) {
              successAction();
            }
          } else {
            toast.error(
              `Error syncing, ${response.data.error || 'unknown error, please try again later'}`,
              {
                duration: 10000
              }
            );
          }
        }
      })
      .catch(error => {
        setIsSyncing(false);
        setSyncStatus(null);
        console.log('poll error!:: ', error);
        setSyncError(error.message);
      });
  };

  const syncData = () => {
    setIsSyncing(true);
    setSyncError('');
    setSyncStatus(null);
    axios
      .get('/api/dutchie/sync', { params: { type } })
      .then(result => {
        console.log('result for sync start: ', result);
        poll();
      })
      .catch(error => {
        setIsSyncing(false);
        console.log('sync error!:: ', error);
        setSyncError(error.message);
      });
  };

  return {
    isSyncing,
    syncStatus,
    syncError,

    syncData
  };
};

export default useDutchieSync;
