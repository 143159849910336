import { EditIcon } from '@chakra-ui/icons';
import { Flex, Button, FormControl, FormLabel, Text, Image as ChakraImage } from '@chakra-ui/react';
import { useRef } from 'react';
import ErrorDialogue from './ErrorDialogue';

const DEFAULT_MAX_FILE_SIZE = 1024 * 1024; // ~500kb
const MAX_FILENAME_LENGTH = 42;
const truncateName = name => {
  if (name.length > MAX_FILENAME_LENGTH) {
    const parts = name.split('.');
    return `${parts[0].substring(0, MAX_FILENAME_LENGTH - 10)}...${parts[1]}`;
  }
};

const FileUpload = ({
  name,
  actionLabel,
  acceptedFileTypes,
  promptLabel = 'Upload a File',
  selectedFileLabel = 'Selected File:',
  maxFileSize = DEFAULT_MAX_FILE_SIZE,
  warnFileSize = DEFAULT_MAX_FILE_SIZE * 0.85,
  onFileSelect,
  onError,
  selectedImageData = null,
  isHandlingImage = false,
  maxPreviewWidth = '200px',
  selectedFile
}) => {
  const inputRef = useRef(null);

  const handleFileUploadChange = e => {
    const { target } = e;
    const fileToUpload = target.files?.[0];
    if (fileToUpload) {
      console.log(fileToUpload);
      const { name: filename, size, type: mimeType } = fileToUpload;
      if (size > maxFileSize) {
        onError &&
          onError(`The file ${filename} is too big, max file size is ${maxFileSize / 1024}kb`);
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileData = reader.result;
        if (isHandlingImage) {
          function dimCheck() {
            console.log('dims: ', this.width, this.height);
            onFileSelect(
              name,
              { name: filename, size, mimeType, width: this.width, height: this.height },
              fileData
            );
          }
          const img = new Image();
          img.onload = dimCheck;
          img.src = fileData;
        } else {
          onFileSelect(name, { name: filename, size, mimeType }, fileData);
        }
      };
      reader.readAsDataURL(fileToUpload);
    }
  };

  const handleFileClear = () => {
    onFileSelect(name);
  };

  if (selectedFile) {
    return (
      <FormControl>
        <FormLabel htmlFor='writeUpFile' fontSize='sm'>
          {selectedFileLabel}
        </FormLabel>
        <Flex
          p='4px'
          alignItems='center'
          justifyContent='center'
          gap='8px'
          borderRadius='4px'
          border='1px solid #eee'
        >
          <Text fontSize='md'>
            {truncateName(selectedFile.name)} ({(selectedFile.size / 1024).toFixed(2)}
            kb)
          </Text>
          <Button onClick={handleFileClear} color='#ff0000' size='xs'>
            x
          </Button>
        </Flex>
        {isHandlingImage && selectedImageData ? (
          <Flex flexDirection='column' mt='12px'>
            <ChakraImage
              alt='preview uploaded file'
              src={selectedImageData}
              height='auto'
              maxWidth={maxPreviewWidth}
              mx='auto'
            />
            <Text fontSize='md' textAlign='center'>
              {selectedFile.width}px x {selectedFile.height}px
              <Text as='em' fontSize='xs' display='block'>
                (preview might be smaller than actual image)
              </Text>
            </Text>
            {selectedFile.size > warnFileSize ? (
              <ErrorDialogue
                error='Warning, that image is a little large'
                errorTitle=''
                status='warning'
                fontSize='md'
              />
            ) : null}
          </Flex>
        ) : null}
      </FormControl>
    );
  }

  return (
    <FormControl>
      <FormLabel htmlFor='writeUpFile' fontSize='sm'>
        {promptLabel}
      </FormLabel>
      <input
        ref={inputRef}
        name='fileUploadInput'
        style={{ display: 'none' }}
        type='file'
        accept={acceptedFileTypes.join(',')}
        onChange={e => handleFileUploadChange(e)}
      />
      <Flex
        onClick={() => inputRef.current?.click()}
        p='4px'
        alignItems='center'
        justifyContent='center'
        gap='8px'
        borderRadius='4px'
        border='1px solid #eee'
        cursor='pointer'
        role='button'
      >
        <EditIcon />
        <Text>{actionLabel || 'Your file ...'}</Text>
      </Flex>
    </FormControl>
  );
};

export default FileUpload;
