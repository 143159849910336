import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Text,
  FormControl,
  // FormLabel,
  // Input,
  ButtonGroup,
  Spinner
} from '@chakra-ui/react';

import useEditor from 'hooks/useEditor';
import useSettings from 'hooks/useSettings';
import SaveCancelDialogue from 'components/segments/CancelDialogue';
import SetLocationHours from 'components/locations/SetLocationHours';
import { PLC_MID_GREEN } from 'constants/styles';

const getInitialData = () => {
  return {
    maintenanceMode: false,
    hideCannabisImagery: false
  };
};

const Editor = () => {
  const {
    settings,
    refreshMsg,
    isRequesting,
    isInitializing,
    isUpdating,
    isRefreshing,
    lastGetError,
    lastUpdateError,
    getSettings,
    initSettings,
    saveSettings,
    handleUpdateLiveSite,
    resetUpdateLiveSiteMsg
  } = useSettings();
  const navigate = useNavigate();
  const hasSetData = useRef();
  const isGettingData = useRef();
  const initializeRequested = useRef();

  const needsInit = !settings && !isRequesting && !initializeRequested.current;

  const {
    data,
    changesDetected,
    handleUpdateEditorData,
    handleCheckboxClick,
    setFormData,
    handleSave,
    handleStartCancel
  } = useEditor({
    initialData: settings || getInitialData(),
    isUpdating,
    lastUpdateError,
    saveFunc: data => saveSettings({ data }),
    saveLabel: 'Settings',
    noRedirectOnSave: true
  });

  useEffect(() => {
    if (!hasSetData.current && !isRequesting) {
      if (settings) {
        hasSetData.current = true;
        initializeRequested.current = false;
        console.log('setting form data to:', settings);
        setFormData(settings);
        isGettingData.current = false;
      } else {
        if (!lastGetError && !isGettingData.current) {
          isGettingData.current = true;
          getSettings();
        }
      }
    }
  }, [getSettings, isRequesting, lastGetError, setFormData, settings]);

  useEffect(() => {
    let timeout;
    if (refreshMsg) {
      timeout = setTimeout(resetUpdateLiveSiteMsg, 10000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [refreshMsg, resetUpdateLiveSiteMsg]);

  const handleInitSettings = () => {
    if (data) {
      console.log('initializing settings....');
      initializeRequested.current = true;
      initSettings(data);
      return;
    }
    console.error('no data to init with!');
  };

  if (isRequesting) {
    return (
      <Box>
        <Heading mb='20px' size='lg'>
          Loading, one moment please...
        </Heading>
        <Spinner size='xl' />
      </Box>
    );
  }

  return (
    <Box>
      <Heading mb='20px' size='lg'>
        Site Settings
      </Heading>

      {needsInit ? (
        <Box>
          <Heading as='h2' size='md'>
            First run
          </Heading>
          <Text>No settings document found, may need to initialize the settings container.</Text>
          <Button onClick={handleInitSettings} disabled={isRequesting}>
            Init Settings Container
          </Button>
        </Box>
      ) : settings && !isGettingData.current ? (
        <Flex gap='20px'>
          <Flex flex='1' gap='20px'>
            <Box flex='1' align='flex-start'>
              <FormControl mb='12px'>
                <Text fontSize='sm'>Temporarily turn off the site:</Text>
                <Checkbox
                  name='maintenanceMode'
                  isChecked={!!data?.maintenanceMode}
                  value='true'
                  onChange={handleCheckboxClick}
                >
                  Maintenance Mode
                </Checkbox>
              </FormControl>

              <FormControl mb='12px'>
                <Text fontSize='sm'>Hides cannabis imagery for non-logged-in users:</Text>
                <Checkbox
                  name='hideCannabisImagery'
                  isChecked={!!data?.hideCannabisImagery}
                  value='true'
                  onChange={handleCheckboxClick}
                >
                  Don't show weed
                </Checkbox>
              </FormControl>

              <Box mt='40px'>
                <ButtonGroup>
                  <Button onClick={handleSave} disabled={!changesDetected}>
                    Save
                  </Button>
                  <Button onClick={handleUpdateLiveSite} disabled={isRefreshing}>
                    Update Live Site
                  </Button>
                  <SaveCancelDialogue
                    handleSave={handleSave}
                    handleStartCancel={handleStartCancel}
                    handleDiscard={() => navigate(-1)}
                    cancelLabel='Back'
                  />
                </ButtonGroup>
                <Box mt='20px'>
                  {refreshMsg && (
                    <Text
                      as='em'
                      fontSize='sm'
                      color={refreshMsg.includes('failed') ? 'red' : PLC_MID_GREEN}
                    >
                      {refreshMsg}
                    </Text>
                  )}
                </Box>
              </Box>
            </Box>
          </Flex>
          <Box flex='0.6'>
            <Heading as='h2' size='md'>
              Store Default Hours:
            </Heading>
            <Text fontSize='sm' mb='12px'>
              Set default hours for all locations (you can override on a per-location basis if you
              want to).
            </Text>
            <Flex gap='10px'>
              <Box>
                <Text fontSize='sm' height='30px' lineHeight='26px'>
                  Monday:
                </Text>
                <Text fontSize='sm' height='30px' lineHeight='26px'>
                  Tuesday:
                </Text>
                <Text fontSize='sm' height='30px' lineHeight='26px'>
                  Wednesday:
                </Text>
                <Text fontSize='sm' height='30px' lineHeight='26px'>
                  Thursday:
                </Text>
                <Text fontSize='sm' height='30px' lineHeight='26px'>
                  Friday:
                </Text>
                <Text fontSize='sm' height='30px' lineHeight='26px'>
                  Saturday:
                </Text>
                <Text fontSize='sm' height='30px' lineHeight='26px'>
                  Sunday:
                </Text>
              </Box>
              <Box>
                <SetLocationHours
                  baseName='hrs_mon'
                  data={data}
                  handler={handleUpdateEditorData}
                  defaultInit
                />
                <SetLocationHours
                  baseName='hrs_tue'
                  data={data}
                  handler={handleUpdateEditorData}
                  defaultInit
                />
                <SetLocationHours
                  baseName='hrs_wed'
                  data={data}
                  handler={handleUpdateEditorData}
                  defaultInit
                />
                <SetLocationHours
                  baseName='hrs_thu'
                  data={data}
                  handler={handleUpdateEditorData}
                  defaultInit
                />
                <SetLocationHours
                  baseName='hrs_fri'
                  data={data}
                  handler={handleUpdateEditorData}
                  defaultInit
                />
                <SetLocationHours
                  baseName='hrs_sat'
                  data={data}
                  handler={handleUpdateEditorData}
                  defaultInit
                />
                <SetLocationHours
                  baseName='hrs_sun'
                  data={data}
                  handler={handleUpdateEditorData}
                  defaultInit
                />
              </Box>
            </Flex>
          </Box>
        </Flex>
      ) : (
        <>
          {isRequesting || isInitializing ? (
            <Box>
              <Heading as='h2'>One moment please....</Heading>
              <Spinner size='xl' />
            </Box>
          ) : (
            <Box>
              <Heading as='h2'>Something went wrong</Heading>
              <Text>Seems we don't have settings and it cannot be initialized.</Text>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Editor;
