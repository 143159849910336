export const SITECONTENT_TYPE = 'site.content.block';
export const SITECONTENT_LIST_REQUEST = 'SITECONTENT_LIST_REQUEST';
export const SITECONTENT_LIST_RESPONSE = 'SITECONTENT_LIST_RESPONSE';
export const SITECONTENT_LIST_ERROR = 'SITECONTENT_LIST_ERROR';
export const SITECONTENT_LIST_RESET = 'SITECONTENT_LIST_RESET';
export const SITECONTENT_GET_REQUEST = 'SITECONTENT_GET_REQUEST';
export const SITECONTENT_GET_RESPONSE = 'SITECONTENT_GET_RESPONSE';
export const SITECONTENT_GET_ERROR = 'SITECONTENT_GET_ERROR';
export const SITECONTENT_ADD_REQUEST = 'SITECONTENT_ADD_REQUEST';
export const SITECONTENT_ADD_RESPONSE = 'SITECONTENT_ADD_RESPONSE';
export const SITECONTENT_ADD_ERROR = 'SITECONTENT_ADD_ERROR';
export const SITECONTENT_UPDATE_REQUEST = 'SITECONTENT_UPDATE_REQUEST';
export const SITECONTENT_UPDATE_RESPONSE = 'SITECONTENT_UPDATE_RESPONSE';
export const SITECONTENT_UPDATE_ERROR = 'SITECONTENT_UPDATE_ERROR';
export const SITECONTENT_DELETE_REQUEST = 'SITECONTENT_DELETE_REQUEST';
export const SITECONTENT_DELETE_RESPONSE = 'SITECONTENT_DELETE_RESPONSE';
export const SITECONTENT_DELETE_ERROR = 'SITECONTENT_DELETE_ERROR';

export const STASH_COPY_DATA = 'STASH_COPY_DATA';
export const CLEAR_COPY_DATA = 'CLEAR_COPY_DATA';

const initialState = {
  isUpdating: false,
  isRequesting: false,
  lastListResponse: null,
  lastListError: null,
  lastGetResponse: null,
  lastGetError: null,
  lastUpdateResponse: null,
  lastUpdateError: null,
  copyData: null,
  page: 1,
  pages: 0,
  count: 0,
  list: []
};

const siteContent = (state = initialState, action) => {
  let list = [...state.list];
  switch (action?.type) {
    case SITECONTENT_LIST_REQUEST:
      return {
        ...state,
        isRequesting: true,
        lastListResponse: null,
        lastListError: null
      };
    case SITECONTENT_LIST_RESPONSE:
      list = action?.data;
      return {
        ...state,
        list,
        page: action?.page || 1,
        pages: action?.pages || 0,
        count: action?.count || 0,
        isRequesting: false,
        lastListResponse: action?.response,
        lastListError: null
      };
    case SITECONTENT_LIST_ERROR:
      return {
        ...state,
        list: [],
        page: 1,
        pages: 0,
        count: 0,
        isRequesting: false,
        lastListResponse: null,
        lastListError: action?.data
      };
    case SITECONTENT_LIST_RESET:
      return {
        ...state,
        isRequesting: false,
        lastListResponse: null,
        lastListError: null,
        list: [],
        page: 1,
        pages: 0,
        count: 0
      };
    case SITECONTENT_GET_REQUEST:
      return {
        ...state,
        isRequesting: true,
        lastGetResponse: null,
        lastGetError: null
      };
    case SITECONTENT_GET_RESPONSE:
      const siteContent = action?.data;
      const existsIndex = list.findIndex(loc => loc._id === siteContent.id);
      if (existsIndex > -1) {
        list[existsIndex] = siteContent;
      } else {
        list.push(siteContent);
      }
      return {
        ...state,
        list,
        isRequesting: false,
        lastGetResponse: siteContent,
        lastGetError: null
      };
    case SITECONTENT_GET_ERROR:
      return {
        ...state,
        isRequesting: false,
        lastGetResponse: null,
        lastGetError: action?.data
      };

    case SITECONTENT_ADD_REQUEST:
    case SITECONTENT_UPDATE_REQUEST:
    case SITECONTENT_DELETE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        lastUpdateResponse: null,
        lastUpdateError: null
      };
    case SITECONTENT_ADD_RESPONSE:
      const addedSiteContent = action?.data;
      if (addedSiteContent) {
        list.push(addedSiteContent);
      }
      return {
        ...state,
        list,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case SITECONTENT_UPDATE_RESPONSE:
      const updatedSiteContent = action?.data;
      if (updatedSiteContent) {
        list = list.map(siteContent => {
          if (siteContent._id === updatedSiteContent._id) {
            return updatedSiteContent;
          }
          return siteContent;
        });
      }
      return {
        ...state,
        list,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case SITECONTENT_DELETE_RESPONSE:
      const { id: deletedContent } = action;
      if (deletedContent) {
        list = list.filter(siteContent => siteContent._id !== deletedContent);
      } else {
        console.error('delete response received, but no siteContent in action.data??', action);
      }
      return {
        ...state,
        list,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case SITECONTENT_ADD_ERROR:
    case SITECONTENT_UPDATE_ERROR:
    case SITECONTENT_DELETE_ERROR:
      return {
        ...state,
        isUpdating: false,
        lastUpdateResponse: null,
        lastUpdateError: action?.data
      };
    case STASH_COPY_DATA:
      return {
        ...state,
        copyData: action?.data
      };
    case CLEAR_COPY_DATA:
      return {
        ...state,
        copyData: null
      };
    default:
      return state;
  }
};

export default siteContent;
