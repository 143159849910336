import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  getProductFiltersList as getList,
  getProductFilterById as getById,
  deleteProductFilter as deleteFilter,
  saveData
} from 'redux/actions/productFilters';
import { DEFAULT_LIST_LIMIT } from 'constants';

const useProductFilters = () => {
  const dispatch = useDispatch();

  const productFilters = useSelector(state => state.productFilters?.list, shallowEqual);
  const isRequesting = useSelector(state => state.productFilters?.isRequesting);
  const isUpdating = useSelector(state => state.productFilters?.isUpdating);
  const lastGetError = useSelector(state => state.productFilters?.lastGetError);
  const lastUpdateError = useSelector(state => state.productFilters?.lastUpdateError);
  const currentPage = useSelector(state => state.productFilters?.page, shallowEqual);
  const pages = useSelector(state => state.productFilters?.pages, shallowEqual);
  const count = useSelector(state => state.productFilters?.count, shallowEqual);

  const getProductFiltersList = useCallback(
    (params = {}) => {
      if (!isRequesting) {
        const { page, limit = DEFAULT_LIST_LIMIT, filter, sort } = params;
        dispatch(getList({ page, limit, filter, sort }));
      }
    },
    [dispatch, isRequesting]
  );

  const getProductFilterById = useCallback(
    id => {
      if (id && !isRequesting) {
        const myProductFilter = productFilters?.find(loc => loc._id === id);
        if (!myProductFilter) {
          dispatch(getById(id));
        }
      }
    },
    [dispatch, isRequesting, productFilters]
  );

  const saveProductFilter = useCallback(
    ({ data, productFilter }) => {
      dispatch(saveData({ data, productFilter }));
    },
    [dispatch]
  );

  const deleteProductFilter = useCallback(
    ({ productFilter }) => {
      dispatch(deleteFilter({ id: productFilter }));
    },
    [dispatch]
  );

  const getFilterLabelById = id => {
    if (productFilters?.length) {
      const pf = productFilters.find(pf => pf._id === id);
      return pf?.name || id; // 'nada';
    }
    return id;
  };

  return {
    getProductFiltersList,
    getProductFilterById,
    saveProductFilter,
    deleteProductFilter,
    getFilterLabelById,
    isRequesting,
    isUpdating,
    lastGetError,
    lastUpdateError,
    productFilters,
    currentPage,
    pages,
    count
  };
};

export default useProductFilters;
