// background colors
export const PLC_LIGHT_GREY_GREEN = '#E1E7D4';
export const PLC_MID_GREEN = '#2E832B';
export const PLC_HIGHLIGHT_GREEN = '#D0FFA9';
export const STAFF_PICK_BLUE = '#3E80A9';
export const TOP_NAV_LIGHTGREEN = '#E0E7D3';
export const PLC_DARKGREY_BG = '#242F29';
export const PLC_DARKGREEN_BG = '#313E37';
export const PLC_MEDIUMGREY_BG = '#464646';
export const CBD_BLUE = '#3E80A9';
export const SATIVA_ORANGE = '#F0691D';
export const INDICA_PURPLE = '#704583';
export const HYBRID_GREEN = '#C0DB6A';

// text colors
export const PLC_DARKGREY_TEXT = '#25302A';
