export const PRODUCTFILTERS_LIST_REQUEST = 'PRODUCTFILTERS_LIST_REQUEST';
export const PRODUCTFILTERS_LIST_RESPONSE = 'PRODUCTFILTERS_LIST_RESPONSE';
export const PRODUCTFILTERS_LIST_ERROR = 'PRODUCTFILTERS_LIST_ERROR';
export const PRODUCTFILTERS_LIST_RESET = 'PRODUCTFILTERS_LIST_RESET';
export const PRODUCTFILTERS_GET_REQUEST = 'PRODUCTFILTERS_GET_REQUEST';
export const PRODUCTFILTERS_GET_RESPONSE = 'PRODUCTFILTERS_GET_RESPONSE';
export const PRODUCTFILTERS_GET_ERROR = 'PRODUCTFILTERS_GET_ERROR';
export const PRODUCTFILTERS_ADD_REQUEST = 'PRODUCTFILTERS_ADD_REQUEST';
export const PRODUCTFILTERS_ADD_RESPONSE = 'PRODUCTFILTERS_ADD_RESPONSE';
export const PRODUCTFILTERS_ADD_ERROR = 'PRODUCTFILTERS_ADD_ERROR';
export const PRODUCTFILTERS_UPDATE_REQUEST = 'PRODUCTFILTERS_UPDATE_REQUEST';
export const PRODUCTFILTERS_UPDATE_RESPONSE = 'PRODUCTFILTERS_UPDATE_RESPONSE';
export const PRODUCTFILTERS_UPDATE_ERROR = 'PRODUCTFILTERS_UPDATE_ERROR';
export const PRODUCTFILTERS_DELETE_REQUEST = 'PRODUCTFILTERS_DELETE_REQUEST';
export const PRODUCTFILTERS_DELETE_RESPONSE = 'PRODUCTFILTERS_DELETE_RESPONSE';
export const PRODUCTFILTERS_DELETE_ERROR = 'PRODUCTFILTERS_DELETE_ERROR';

const initialState = {
  isUpdating: false,
  isRequesting: false,
  lastListResponse: null,
  lastListError: null,
  lastGetResponse: null,
  lastGetError: null,
  lastUpdateResponse: null,
  lastUpdateError: null,
  page: 1,
  pages: 0,
  count: 0,
  list: []
};

const productFilters = (state = initialState, action) => {
  let list = [...state.list];
  switch (action?.type) {
    case PRODUCTFILTERS_LIST_REQUEST:
      return {
        ...state,
        isRequesting: true,
        lastListResponse: null,
        lastListError: null
      };
    case PRODUCTFILTERS_LIST_RESPONSE:
      list = action?.data;
      return {
        ...state,
        list,
        page: action?.page || 1,
        pages: action?.pages || 0,
        count: action?.count || 0,
        isRequesting: false,
        lastListResponse: action?.response,
        lastListError: null
      };
    case PRODUCTFILTERS_LIST_ERROR:
      return {
        ...state,
        list: [],
        page: 1,
        pages: 0,
        count: 0,
        isRequesting: false,
        lastListResponse: null,
        lastListError: action?.data
      };
    case PRODUCTFILTERS_LIST_RESET:
      return {
        ...state,
        isRequesting: false,
        lastListResponse: null,
        lastListError: null,
        list: [],
        page: 1,
        pages: 0,
        count: 0
      };
    case PRODUCTFILTERS_GET_REQUEST:
      return {
        ...state,
        isRequesting: true,
        lastGetResponse: null,
        lastGetError: null
      };
    case PRODUCTFILTERS_GET_RESPONSE:
      const productFilters = action?.data;
      const existsIndex = list.findIndex(loc => loc._id === productFilters.id);
      if (existsIndex > -1) {
        list[existsIndex] = productFilters;
      } else {
        list.push(productFilters);
      }
      return {
        ...state,
        list,
        isRequesting: false,
        lastGetResponse: productFilters,
        lastGetError: null
      };
    case PRODUCTFILTERS_GET_ERROR:
      return {
        ...state,
        isRequesting: false,
        lastGetResponse: null,
        lastGetError: action?.data
      };

    case PRODUCTFILTERS_ADD_REQUEST:
    case PRODUCTFILTERS_UPDATE_REQUEST:
    case PRODUCTFILTERS_DELETE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        lastUpdateResponse: null,
        lastUpdateError: null
      };
    case PRODUCTFILTERS_ADD_RESPONSE:
      const addedProductFilter = action?.data;
      if (addedProductFilter) {
        list.push(addedProductFilter);
      }
      return {
        ...state,
        list,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case PRODUCTFILTERS_UPDATE_RESPONSE:
      const updatedProductFilter = action?.data;
      if (updatedProductFilter) {
        list = list.map(productFilters => {
          if (productFilters._id === updatedProductFilter._id) {
            return updatedProductFilter;
          }
          return productFilters;
        });
      }
      return {
        ...state,
        list,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case PRODUCTFILTERS_DELETE_RESPONSE:
      const { id: deletedContent } = action;
      if (deletedContent) {
        list = list.filter(productFilters => productFilters._id !== deletedContent);
      } else {
        console.error('delete response received, but no productFilters in action.data??', action);
      }
      return {
        ...state,
        list,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case PRODUCTFILTERS_ADD_ERROR:
    case PRODUCTFILTERS_UPDATE_ERROR:
    case PRODUCTFILTERS_DELETE_ERROR:
      return {
        ...state,
        isUpdating: false,
        lastUpdateResponse: null,
        lastUpdateError: action?.data
      };
    default:
      return state;
  }
};

export default productFilters;
