import { Flex, Button, Spinner, Text } from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';

import useDutchieSync from 'hooks/useDutchieSync';
import { PLC_MID_GREEN } from 'constants/styles';

const DutchieSync = ({ type, label, interval = 1000, successAction }) => {
  const { isSyncing, syncStatus, syncError, syncData } = useDutchieSync({
    type,
    interval,
    successAction
  });

  return (
    <>
      {isSyncing ? (
        <Flex alignItems='flex-start'>
          <Spinner
            thickness='3px'
            speed='0.65s'
            emptyColor='gray.200'
            color={PLC_MID_GREEN}
            size='md'
            mr='10px'
          />
          <Text fontSize='sm'>Dutchie Sync: {syncStatus}</Text>
        </Flex>
      ) : (
        <>
          <Button onClick={syncData}>
            <RepeatIcon mr='10px' /> Sync Dutchie {label}
          </Button>
          {syncError && (
            <Text as='span' fontSize='sm' color='red'>
              {syncError}
            </Text>
          )}
        </>
      )}
    </>
  );
};

export default DutchieSync;
