import { useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import useSpecials from 'hooks/useSpecials';

import PaginationComponent from 'components/segments/Pagination';
import ErrorDialogue from 'components/segments/ErrorDialogue';
import DeleteDialogue from 'components/segments/DeleteDialogue';
import { TableBox } from 'components/segments/StyledComponents';

const List = ({ type, typeLabel }) => {
  const listOnLoad = useRef(false);
  const lastType = useRef(type);
  const {
    specials,
    currentPage,
    pages,
    // count,
    isRequesting,
    lastUpdateError,
    getSpecialsList,
    // updateStatus,
    deleteSpecial
  } = useSpecials({ type });

  const navigate = useNavigate();

  useEffect(() => {
    if (!listOnLoad.current) {
      listOnLoad.current = true;
      getSpecialsList({ page: currentPage || 1 });
    }
  }, [currentPage, getSpecialsList, specials, type]);

  useEffect(() => {
    if (type && lastType.current !== type) {
      lastType.current = type;
      getSpecialsList({ page: currentPage || 1 });
    }
  }, [currentPage, getSpecialsList, type]);

  const errorDisplay = useMemo(() => {
    if (!specials?.length) {
      const message =
        !listOnLoad.current || isRequesting ? 'Loading specials...' : 'Sorry, no specials found';
      return <Heading size='lg'>{message}</Heading>;
    }
    return null;
  }, [specials?.length, isRequesting]);

  const pageChangeHandler = page => {
    getSpecialsList({ page, sort: { field: 'created', dir: -1 } });
  };

  const handleDelete = special => {
    if (special) {
      deleteSpecial({ special });
    }
  };

  return (
    <Box>
      {errorDisplay}
      {!errorDisplay && (
        <TableBox my='12px' border='1px solid #000'>
          <Table size='sm'>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Type</Th>
                <Th>From / To</Th>
                <Th>Enabled?</Th>
                <Th>Products</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {specials.map((l, idx) => {
                return (
                  <Tr key={idx}>
                    <Td>{l.label}</Td>
                    <Td>{l.type}</Td>
                    <Td>{l.startDate + ' to ' + l.endDate}</Td>
                    <Td>{l.enabled ? 'x' : ''}</Td>
                    <Td>{l.products?.length || 'no products added yet'}</Td>
                    <Td>
                      <ButtonGroup spacing='3' size='xs'>
                        <Button onClick={() => navigate(`/specials/edit/${type}/${l._id}`)}>
                          Edit
                        </Button>
                        <DeleteDialogue
                          label='Delete'
                          deleteConfirmLabel={`Delete ${typeLabel}`}
                          handleDelete={() => handleDelete(l._id)}
                        />
                      </ButtonGroup>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableBox>
      )}
      <ErrorDialogue error={lastUpdateError} />
      {pages > 1 && (
        <PaginationComponent
          numPages={pages}
          page={currentPage}
          pageChangeHandler={pageChangeHandler}
        />
      )}
    </Box>
  );
};

export default List;
