import { Link as RouterLink } from 'react-router-dom';
import { Heading, Link, VStack, Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { PLC_HIGHLIGHT_GREEN, PLC_MID_GREEN } from 'constants/styles';

const NavLink = styled(Link)`
  font-size: 14px;
  color: ${PLC_HIGHLIGHT_GREEN};
  width: 100%;
  text-align: left;
  padding-left: 8px;
  display: block;
`;

const Divider = () => {
  return <Box h='1px' w='100%' borderBottom={`1px solid ${PLC_MID_GREEN}`} />;
};

const MainNav = () => {
  return (
    <VStack align='flex-start' ml={1}>
      <Heading size='xs' fontWeight='bold' lineHeight='1' mt={1} color='#fff'>
        Shop Data
      </Heading>

      <NavLink as={RouterLink} to='/locations'>
        Locations
      </NavLink>
      <NavLink as={RouterLink} to='/products'>
        Products
      </NavLink>
      <NavLink as={RouterLink} to='/filters'>
        Categories + Filters
      </NavLink>
      <NavLink as={RouterLink} to='/specials'>
        Specials
      </NavLink>

      <Divider />
      <Heading size='xs' fontWeight='bold' lineHeight='1' mt={1} color='#fff'>
        Shop Activity
      </Heading>

      <NavLink as={RouterLink} to='/customers'>
        Customers
      </NavLink>
      <NavLink as={RouterLink} to='/transactions'>
        Transactions
      </NavLink>

      <Divider />
      <Heading size='xs' fontWeight='bold' lineHeight='1' mt={1} color='#fff'>
        Site Content
      </Heading>

      {/* <NavLink as={RouterLink} to='/news'>
        News
      </NavLink> */}
      <NavLink as={RouterLink} to='/blog'>
        Blog
      </NavLink>
      <NavLink as={RouterLink} to='/site-content'>
        Page Blocks
      </NavLink>
      <NavLink as={RouterLink} to='/image-bank'>
        Image Bank
      </NavLink>
      <NavLink as={RouterLink} to='/tags'>
        Tags
      </NavLink>

      <Divider />
      <Heading size='xs' fontWeight='bold' lineHeight='1' mt={1} color='#fff'>
        Contact Submissions
      </Heading>

      <NavLink as={RouterLink} to='/contact/general'>
        General
      </NavLink>
      <NavLink as={RouterLink} to='/contact/career'>
        Career
      </NavLink>
      <NavLink as={RouterLink} to='/contact/education'>
        Education Requests
      </NavLink>

      <Divider />
      <Heading size='xs' fontWeight='bold' lineHeight='1' mt={1} color='#fff'>
        Configuration
      </Heading>

      <NavLink as={RouterLink} to='/settings'>
        Site Settings
      </NavLink>
      <NavLink as={RouterLink} to='/cms-users'>
        CMS Users
      </NavLink>
    </VStack>
  );
};

export default MainNav;
