import { useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import useBlog from 'hooks/useBlog';

import PaginationComponent from 'components/segments/Pagination';
import ErrorDialogue from 'components/segments/ErrorDialogue';
import DeleteDialogue from 'components/segments/DeleteDialogue';
import { TableBox } from 'components/segments/StyledComponents';

const List = () => {
  const listOnLoad = useRef();
  const {
    blog,
    currentPage,
    pages,
    // count,
    isRequesting,
    lastUpdateError,
    getBlogList,
    // updateStatus,
    deleteBlog
  } = useBlog();

  const navigate = useNavigate();

  useEffect(() => {
    if (!listOnLoad.current) {
      listOnLoad.current = true;
      getBlogList({ page: currentPage || 1, sort: { field: 'created', dir: -1 } });
    }
  }, [getBlogList, blog, currentPage]);

  const errorDisplay = useMemo(() => {
    if (!blog?.length) {
      const message =
        !listOnLoad.current || isRequesting ? 'Loading blog...' : 'Sorry, no blog posts found';
      return <Heading size='lg'>{message}</Heading>;
    }
    return null;
  }, [blog?.length, isRequesting]);

  const pageChangeHandler = page => {
    getBlogList({ page, sort: { field: 'created', dir: -1 } });
  };

  const handleDelete = article => {
    if (article) {
      deleteBlog({ article });
    }
  };

  return (
    <Box>
      {errorDisplay}
      {!errorDisplay && (
        <TableBox my='12px' border='1px solid #000'>
          <Table size='sm'>
            <Thead>
              <Tr>
                <Th>Title</Th>
                <Th>Created</Th>
                <Th>Published</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {blog.map((l, idx) => {
                return (
                  <Tr key={idx}>
                    <Td>{l.title}</Td>
                    <Td>{l.created}</Td>
                    <Td>{l.publishDate}</Td>
                    <Td>
                      <ButtonGroup spacing='3' size='xs'>
                        <Button onClick={() => navigate(`/blog/edit/${l._id}`)}>Edit</Button>
                        <Button onClick={() => navigate(`/blog/${l._id}`)}>View</Button>
                        <DeleteDialogue
                          label='Delete'
                          deleteConfirmLabel='Delete Blog Post'
                          handleDelete={() => handleDelete(l._id)}
                        />
                      </ButtonGroup>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableBox>
      )}
      <ErrorDialogue error={lastUpdateError} />
      {pages > 1 && (
        <PaginationComponent
          numPages={pages}
          page={currentPage}
          pageChangeHandler={pageChangeHandler}
        />
      )}
    </Box>
  );
};

export default List;
