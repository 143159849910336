export const PRODUCTS_LIST_REQUEST = 'PRODUCTS_LIST_REQUEST';
export const PRODUCTS_LIST_RESPONSE = 'PRODUCTS_LIST_RESPONSE';
export const PRODUCTS_LIST_ERROR = 'PRODUCTS_LIST_ERROR';
export const PRODUCTS_LIST_RESET = 'PRODUCTS_LIST_RESET';
export const PRODUCT_GET_REQUEST = 'PRODUCT_GET_REQUEST';
export const PRODUCT_GET_RESPONSE = 'PRODUCT_GET_RESPONSE';
export const PRODUCT_GET_ERROR = 'PRODUCT_GET_ERROR';
export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST';
export const PRODUCT_UPDATE_RESPONSE = 'PRODUCT_UPDATE_RESPONSE';
export const PRODUCT_UPDATE_ERROR = 'PRODUCT_UPDATE_ERROR';
export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_RESPONSE = 'PRODUCT_DELETE_RESPONSE';
export const PRODUCT_DELETE_ERROR = 'PRODUCT_DELETE_ERROR';

const initialState = {
  isUpdating: false,
  isRequesting: false,
  lastListResponse: null,
  lastListError: null,
  lastGetResponse: null,
  lastGetError: null,
  lastUpdateResponse: null,
  lastUpdateError: null,
  page: 1,
  pages: 0,
  count: 0,
  list: []
};

const products = (state = initialState, action) => {
  let list = [...state.list];
  switch (action?.type) {
    case PRODUCTS_LIST_REQUEST:
      return {
        ...state,
        isRequesting: true,
        lastListResponse: null,
        lastListError: null
      };
    case PRODUCTS_LIST_RESPONSE:
      list = action?.data;
      return {
        ...state,
        list,
        page: action?.page || 1,
        pages: action?.pages || 0,
        count: action?.count || 0,
        isRequesting: false,
        lastListResponse: action?.response,
        lastListError: null
      };
    case PRODUCTS_LIST_ERROR:
      return {
        ...state,
        list: [],
        page: 1,
        pages: 0,
        count: 0,
        isRequesting: false,
        lastListResponse: null,
        lastListError: action?.data
      };
    case PRODUCTS_LIST_RESET:
      return {
        ...state,
        isRequesting: false,
        lastListResponse: null,
        lastListError: null,
        list: [],
        page: 1,
        pages: 0,
        count: 0
      };
    case PRODUCT_GET_REQUEST:
      return {
        ...state,
        isRequesting: true,
        lastGetResponse: null,
        lastGetError: null
      };
    case PRODUCT_GET_RESPONSE:
      const product = action?.data;
      const existsIndex = list.findIndex(loc => loc._id === product.id);
      if (existsIndex > -1) {
        list[existsIndex] = product;
      } else {
        list.push(product);
      }
      return {
        ...state,
        list,
        isRequesting: false,
        lastGetResponse: product,
        lastGetError: null
      };
    case PRODUCT_GET_ERROR:
      return {
        ...state,
        isRequesting: false,
        lastGetResponse: null,
        lastGetError: action?.data
      };

    case PRODUCT_UPDATE_REQUEST:
    case PRODUCT_DELETE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        lastUpdateResponse: null,
        lastUpdateError: null
      };
    case PRODUCT_UPDATE_RESPONSE:
      const updatedProduct = action?.data;
      if (updatedProduct) {
        list = list.map(product => {
          if (product._id === updatedProduct._id) {
            return updatedProduct;
          }
          return product;
        });
      }
      return {
        ...state,
        list,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case PRODUCT_DELETE_RESPONSE:
      const deletedProduct = action?.data;
      if (deletedProduct) {
        list = list.filter(product => product._id !== deletedProduct);
      } else {
        console.error('delete response received, but no product in action.data??', action);
      }
      return {
        ...state,
        list,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case PRODUCT_UPDATE_ERROR:
    case PRODUCT_DELETE_ERROR:
      return {
        ...state,
        isUpdating: false,
        lastUpdateResponse: null,
        lastUpdateError: action?.data
      };
    default:
      return state;
  }
};

export default products;
