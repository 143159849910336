export const CUSTOMERS_LIST_REQUEST = 'CUSTOMERS_LIST_REQUEST';
export const CUSTOMERS_LIST_RESPONSE = 'CUSTOMERS_LIST_RESPONSE';
export const CUSTOMERS_LIST_ERROR = 'CUSTOMERS_LIST_ERROR';
export const CUSTOMERS_LIST_RESET = 'CUSTOMERS_LIST_RESET';
export const CUSTOMER_UPDATE_REQUEST = 'CUSTOMER_UPDATE_REQUEST';
export const CUSTOMER_UPDATE_RESPONSE = 'CUSTOMER_UPDATE_RESPONSE';
export const CUSTOMER_UPDATE_ERROR = 'CUSTOMER_UPDATE_ERROR';
export const CUSTOMER_DELETE_REQUEST = 'CUSTOMER_DELETE_REQUEST';
export const CUSTOMER_DELETE_RESPONSE = 'CUSTOMER_DELETE_RESPONSE';
export const CUSTOMER_DELETE_ERROR = 'CUSTOMER_DELETE_ERROR';

const initialState = {
  page: 1,
  isRequesting: false,
  lastListResponse: null,
  lastListError: null,
  lastUpdateResponse: null,
  lastUpdateError: null,
  customerUpdating: null,
  pages: 0,
  customers: [],
  currentCustomerId: null
};

const customers = (state = initialState, action) => {
  let customers = [...state.customers];
  switch (action?.type) {
    case CUSTOMERS_LIST_REQUEST:
      return {
        ...state,
        customerUpdating: null,
        isRequesting: true,
        lastListResponse: null,
        lastListError: null
      };
    case CUSTOMERS_LIST_RESPONSE:
      customers = action?.data;
      return {
        ...state,
        customerUpdating: null,
        customers,
        page: action?.page || 1,
        pages: action?.pages || 0,
        count: action?.count || 0,
        isRequesting: false,
        lastListResponse: action?.response,
        lastListError: null
      };
    case CUSTOMERS_LIST_ERROR:
      return {
        ...state,
        customerUpdating: null,
        customers: [],
        page: 0,
        pages: 0,
        count: 0,
        isRequesting: false,
        lastListResponse: null,
        lastListError: action?.data
      };
    case CUSTOMERS_LIST_RESET:
      return {
        ...state,
        customerUpdating: null,
        isRequesting: false,
        lastListResponse: null,
        lastListError: null,
        customers: [],
        page: 0,
        pages: 0,
        count: 0
      };
    case CUSTOMER_UPDATE_REQUEST:
    case CUSTOMER_DELETE_REQUEST:
      return {
        ...state,
        isRequesting: true,
        customerUpdating: action?.customer,
        lastUpdateResponse: null,
        lastUpdateError: null
      };
    case CUSTOMER_UPDATE_RESPONSE:
      const updatedCustomer = action?.data;
      if (updatedCustomer) {
        customers = customers.map(customer => {
          if (customer._id === updatedCustomer._id) {
            return updatedCustomer;
          }
          return customer;
        });
      }
      return {
        ...state,
        customers,
        customerUpdating: null,
        isRequesting: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case CUSTOMER_DELETE_RESPONSE:
      const deletedCustomer = action?.data;
      if (deletedCustomer) {
        customers = customers.filter(customer => customer._id !== deletedCustomer);
      } else {
        console.error('delete response received, but no customer in action.data??', action);
      }
      return {
        ...state,
        customers,
        customerUpdating: null,
        isRequesting: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case CUSTOMER_UPDATE_ERROR:
    case CUSTOMER_DELETE_ERROR:
      return {
        ...state,
        customerUpdating: null,
        isRequesting: false,
        lastUpdateResponse: null,
        lastUpdateError: action?.data
      };
    default:
      return state;
  }
};

export default customers;
