import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  initSettings as _init,
  getSettings as _getSettings,
  updateLiveSiteSettings,
  updateLiveSiteSettingsReset,
  saveData
} from 'redux/actions/settings';

const useSettings = () => {
  const dispatch = useDispatch();

  const settings = useSelector(state => state.settings?.data, shallowEqual);
  const isRequesting = useSelector(state => state.settings?.isRequesting);
  const isInitializing = useSelector(state => state.settings?.isInitializing);
  const isUpdating = useSelector(state => state.settings?.isUpdating);
  const lastGetError = useSelector(state => state.settings?.lastGetError);
  const lastInitError = useSelector(state => state.settings?.lastInitError);
  const lastUpdateError = useSelector(state => state.settings?.lastUpdateError);
  const refreshMsg = useSelector(state => state.settings?.refreshMsg);
  const isRefreshing = useSelector(state => state.settings?.isRefreshing);

  const getSettings = useCallback(() => {
    if (!isRequesting) {
      dispatch(_getSettings());
    }
  }, [dispatch, isRequesting]);

  const saveSettings = useCallback(
    ({ data }) => {
      if (settings) {
        dispatch(saveData({ data, id: settings._id }));
      } else {
        console.warn('settings not loaded, cannot save them');
      }
    },
    [dispatch, settings]
  );

  // creates the settings doc for use (first time run)
  const initSettings = useCallback(
    data => {
      dispatch(_init(data));
    },
    [dispatch]
  );

  const handleUpdateLiveSite = () => {
    dispatch(updateLiveSiteSettings());
  };

  const resetUpdateLiveSiteMsg = () => {
    dispatch(updateLiveSiteSettingsReset());
  };

  return {
    getSettings,
    saveSettings,
    initSettings,
    handleUpdateLiveSite,
    resetUpdateLiveSiteMsg,
    isInitializing,
    isRequesting,
    isUpdating,
    isRefreshing,
    lastGetError,
    lastInitError,
    lastUpdateError,
    settings,
    refreshMsg
  };
};

export default useSettings;
