import { Flex, Heading } from '@chakra-ui/react';

import DutchieSync from 'components/segments/DutchieSync';
import useLocations from 'hooks/useLocations';
import List from './List';

const Locations = () => {
  const { getLocationList } = useLocations();
  return (
    <Flex flexDirection='column'>
      <Flex justifyContent='space-between'>
        <Heading>Plantlife Locations</Heading>
        <Flex alignItems='flex-end' gap='10px'>
          <DutchieSync type='locations' label='Location Data' successAction={getLocationList} />
        </Flex>
      </Flex>
      <List />
    </Flex>
  );
};

export default Locations;
