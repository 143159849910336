import { extendTheme } from '@chakra-ui/react';
import { PLC_MID_GREEN } from 'constants/styles';

const theme = extendTheme({
  // fonts: {
  //     heading: `'Bebas Neue', sans-serif`,
  //     body: `'Nunito Sans', sans-serif`
  // },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: 'normal',
        color: PLC_MID_GREEN,
        textAlign: 'left'
      }
    },
    Input: {
      baseStyle: {
        field: {
          bg: 'white',
          color: 'gray.700',
          mb: '10px',
          _hover: {
            bg: 'gray.100'
          },
          _focus: {
            bg: 'white'
          },
          _autofill: {
            // border: '1px solid transparent',
            // textFillColor: '#c6c6c6',
            boxShadow: '0 0 0px 1000px white inset',
            transition: 'background-color 5000s ease-in-out 0s'
          }
        }
      }
    }
  }
  // not quite working yet (likely my config or something)
  // https://chakra-ui.com/docs/styled-system/text-and-layer-styles
  // use above approach instead, works fine...
  // textStyles: {
  //     h1: {
  //         fontWeight: "normal",
  //         color: "green"
  //     },
  //     h2: {
  //         fontWeight: "normal"
  //     },
  //     h3: {
  //         fontWeight: "normal"
  //     },
  //     h4: {
  //         fontWeight: "normal"
  //     },
  //     h5: {
  //         fontWeight: "normal"
  //     },
  //     h6: {
  //         fontWeight: "normal"
  //     },
  //     h7: {
  //         fontWeight: "normal"
  //     }
  // }
});

export default theme;
