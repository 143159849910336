export const TAGS_LIST_REQUEST = 'TAGS_LIST_REQUEST';
export const TAGS_LIST_RESPONSE = 'TAGS_LIST_RESPONSE';
export const TAGS_LIST_ERROR = 'TAGS_LIST_ERROR';
export const TAGS_LIST_RESET = 'TAGS_LIST_RESET';
export const TAGS_GET_REQUEST = 'TAGS_GET_REQUEST';
export const TAGS_GET_RESPONSE = 'TAGS_GET_RESPONSE';
export const TAGS_GET_ERROR = 'TAGS_GET_ERROR';
export const TAGS_ADD_REQUEST = 'TAGS_ADD_REQUEST';
export const TAGS_ADD_RESPONSE = 'TAGS_ADD_RESPONSE';
export const TAGS_ADD_ERROR = 'TAGS_ADD_ERROR';
export const TAGS_UPDATE_REQUEST = 'TAGS_UPDATE_REQUEST';
export const TAGS_UPDATE_RESPONSE = 'TAGS_UPDATE_RESPONSE';
export const TAGS_UPDATE_ERROR = 'TAGS_UPDATE_ERROR';
export const TAGS_DELETE_REQUEST = 'TAGS_DELETE_REQUEST';
export const TAGS_DELETE_RESPONSE = 'TAGS_DELETE_RESPONSE';
export const TAGS_DELETE_ERROR = 'TAGS_DELETE_ERROR';

const initialState = {
  isUpdating: false,
  isRequesting: false,
  lastListResponse: null,
  lastListError: null,
  lastGetResponse: null,
  lastGetError: null,
  lastUpdateResponse: null,
  lastUpdateError: null,
  page: 1,
  pages: 0,
  count: 0,
  list: []
};

const tags = (state = initialState, action) => {
  let list = [...state.list];
  switch (action?.type) {
    case TAGS_LIST_REQUEST:
      return {
        ...state,
        isRequesting: true,
        lastListResponse: null,
        lastListError: null
      };
    case TAGS_LIST_RESPONSE:
      list = action?.data;
      return {
        ...state,
        list,
        page: action?.page || 1,
        pages: action?.pages || 0,
        count: action?.count || 0,
        isRequesting: false,
        lastListResponse: action?.response,
        lastListError: null
      };
    case TAGS_LIST_ERROR:
      return {
        ...state,
        list: [],
        page: 1,
        pages: 0,
        count: 0,
        isRequesting: false,
        lastListResponse: null,
        lastListError: action?.data
      };
    case TAGS_LIST_RESET:
      return {
        ...state,
        isRequesting: false,
        lastListResponse: null,
        lastListError: null,
        list: [],
        page: 1,
        pages: 0,
        count: 0
      };
    case TAGS_GET_REQUEST:
      return {
        ...state,
        isRequesting: true,
        lastGetResponse: null,
        lastGetError: null
      };
    case TAGS_GET_RESPONSE:
      const tags = action?.data;
      const existsIndex = list.findIndex(loc => loc._id === tags.id);
      if (existsIndex > -1) {
        list[existsIndex] = tags;
      } else {
        list.push(tags);
      }
      return {
        ...state,
        list,
        isRequesting: false,
        lastGetResponse: tags,
        lastGetError: null
      };
    case TAGS_GET_ERROR:
      return {
        ...state,
        isRequesting: false,
        lastGetResponse: null,
        lastGetError: action?.data
      };

    case TAGS_ADD_REQUEST:
    case TAGS_UPDATE_REQUEST:
    case TAGS_DELETE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        lastUpdateResponse: null,
        lastUpdateError: null
      };
    case TAGS_ADD_RESPONSE:
      const addedTag = action?.data;
      if (addedTag) {
        list.push(addedTag);
      }
      return {
        ...state,
        list,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case TAGS_UPDATE_RESPONSE:
      const updatedTag = action?.data;
      if (updatedTag) {
        list = list.map(tags => {
          if (tags._id === updatedTag._id) {
            return updatedTag;
          }
          return tags;
        });
      }
      return {
        ...state,
        list,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case TAGS_DELETE_RESPONSE:
      const { id: deletedContent } = action;
      if (deletedContent) {
        list = list.filter(tags => tags._id !== deletedContent);
      } else {
        console.error('delete response received, but no tags in action.data??', action);
      }
      return {
        ...state,
        list,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case TAGS_ADD_ERROR:
    case TAGS_UPDATE_ERROR:
    case TAGS_DELETE_ERROR:
      return {
        ...state,
        isUpdating: false,
        lastUpdateResponse: null,
        lastUpdateError: action?.data
      };
    default:
      return state;
  }
};

export default tags;
