export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_RESPONSE = 'LOGIN_RESPONSE';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const REFRESH_RESPONSE = 'REFRESH_RESPONSE';
export const LOGOUT = 'LOGOUT';

const initialState = {
  isRequesting: false,
  authorized: false,
  error: null,
  user: null
};

const adminUser = (state = initialState, action) => {
  switch (action?.type) {
    case LOGIN_REQUEST:
      return { ...state, isRequesting: true, authorized: false, user: null, error: null };
    case LOGIN_RESPONSE:
      return { ...state, isRequesting: false, authorized: !!action?.data, user: action?.data };
    case LOGIN_ERROR:
      return { ...state, isRequesting: false, error: action?.data };
    case REFRESH_RESPONSE:
      return { ...state, isRequesting: false, authorized: !!action?.data, user: action?.data };
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};

export default adminUser;
