import axios from 'axios';

import {
  PRODUCTFILTERS_LIST_REQUEST,
  PRODUCTFILTERS_LIST_RESPONSE,
  PRODUCTFILTERS_LIST_ERROR,
  PRODUCTFILTERS_GET_REQUEST,
  PRODUCTFILTERS_GET_RESPONSE,
  PRODUCTFILTERS_GET_ERROR,
  PRODUCTFILTERS_ADD_REQUEST,
  PRODUCTFILTERS_ADD_RESPONSE,
  PRODUCTFILTERS_ADD_ERROR,
  PRODUCTFILTERS_UPDATE_REQUEST,
  PRODUCTFILTERS_UPDATE_RESPONSE,
  PRODUCTFILTERS_UPDATE_ERROR,
  PRODUCTFILTERS_DELETE_REQUEST,
  PRODUCTFILTERS_DELETE_RESPONSE,
  PRODUCTFILTERS_DELETE_ERROR
} from 'redux/reducers/productFilters';

import { getQueryFilter } from 'utils/request';

export const getProductFiltersList = ({ page = 1, limit, filter = {}, sort }) => {
  return async dispatch => {
    // dispatch, getState
    dispatch({ type: PRODUCTFILTERS_LIST_REQUEST });
    try {
      const params = { page, limit, filter: getQueryFilter(filter) };
      if (sort) {
        params.sort = JSON.stringify(sort);
      }
      const response = await axios.get('/api/data/list/product-filters', {
        params
      });
      dispatch({
        type: PRODUCTFILTERS_LIST_RESPONSE,
        data: response?.data?.list,
        pages: response?.data?.pages || 0,
        count: response?.data?.count || 0,
        // response,
        page
      });
    } catch (error) {
      dispatch({ type: PRODUCTFILTERS_LIST_ERROR, data: error.message });
    }
  };
};

export const getProductFilterById = id => {
  return async dispatch => {
    // dispatch, getState
    dispatch({ type: PRODUCTFILTERS_GET_REQUEST });
    try {
      const response = await axios.get(`/api/data/get/product-filters/${id}`);
      dispatch({
        type: PRODUCTFILTERS_GET_RESPONSE,
        data: response?.data
      });
    } catch (error) {
      dispatch({ type: PRODUCTFILTERS_GET_ERROR, data: error.message });
    }
  };
};

export const saveData = ({ data, productFilter }) => {
  return async dispatch => {
    if (!data) {
      console.error('error, missing data, cannot update');
      return;
    }

    const params = {
      doc: true,
      data
    };
    let apiPathAction = 'add',
      requestAction = PRODUCTFILTERS_ADD_REQUEST,
      responseAction = PRODUCTFILTERS_ADD_RESPONSE,
      errorAction = PRODUCTFILTERS_ADD_ERROR;
    if (productFilter) {
      params.id = productFilter;
      apiPathAction = 'update';
      requestAction = PRODUCTFILTERS_UPDATE_REQUEST;
      responseAction = PRODUCTFILTERS_UPDATE_RESPONSE;
      errorAction = PRODUCTFILTERS_UPDATE_ERROR;
    }

    dispatch({ type: requestAction, productFilter });

    if (!data.name || !data.value || !data.type) {
      // race condition with this error dispatch??? timeout=1 gives a tick
      setTimeout(() => {
        dispatch({
          type: errorAction,
          data: 'Missing required properties, make sure to add a name, value and type.'
        });
      }, 1);
      return;
    }

    try {
      const response = await axios.post(`/api/data/${apiPathAction}/product-filters`, params);
      if (response?.data?.result) {
        dispatch({ type: responseAction, data: response?.data?.doc });
      } else {
        dispatch({ type: errorAction, data: 'failed to save the productFilters' });
      }
    } catch (error) {
      dispatch({
        type: errorAction,
        data: error?.response?.data?.message || 'unknown error'
      });
    }
  };
};

export const deleteProductFilter = ({ id }) => {
  return async dispatch => {
    if (!id) {
      console.error('error, missing id, cannot delete');
      return;
    }
    console.log('ok try to delete productFilters', id);
    dispatch({ type: PRODUCTFILTERS_DELETE_REQUEST, id });
    try {
      const response = await axios.delete(`/api/data/delete/product-filters/${id}`);
      console.log('delete productFilters response', response?.data);
      dispatch({ type: PRODUCTFILTERS_DELETE_RESPONSE, id, data: response });
    } catch (error) {
      console.warn('error deleting, dispatch the error', error);
      dispatch({ type: PRODUCTFILTERS_DELETE_ERROR, data: error });
    }
  };
};
