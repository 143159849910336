/**
 * @param sortArrayByProp
 * @returns T[] array
 *
 * // example usage: sort by the "name" property on a Person type
 * // allThePeople is Person[]
 * allThePeople.sort(sortByStringProp((person: Person) => person.name))
 */
export const sortArrayByProp =
  //  <T>(getProperty: (object: T) => String | Number) =>
  //  (objectA: T, objectB: T) => {
  getProperty => (objectA, objectB) => {
    let upperA = getProperty(objectA);
    let upperB = getProperty(objectB);
    if (typeof upperA === 'string') {
      upperA = upperA.toUpperCase();
    }
    if (typeof upperB === 'string') {
      upperB = upperB.toUpperCase();
    }
    if (upperA < upperB) {
      return -1;
    }
    if (upperA > upperB) {
      return 1;
    }
    return 0;
  };
