import { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  ButtonGroup,
  useDisclosure
} from '@chakra-ui/react';

const DeleteDialogue = ({
  handleDelete,
  label = 'Delete Item',
  msg = `Are you sure? You can't undo this action afterwards.`,
  deleteLabel = 'Delete',
  cancelLabel = 'Cancel',
  deleteConfirmLabel = 'Delete Item'
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  return (
    <>
      <Button onClick={onOpen} variant='outline' colorScheme='red'>
        {deleteLabel}
      </Button>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {label}
            </AlertDialogHeader>

            <AlertDialogBody>{msg}</AlertDialogBody>

            <AlertDialogFooter>
              <ButtonGroup spacing='4'>
                <Button
                  ref={cancelRef}
                  onClick={() => {
                    onClose();
                  }}
                >
                  {cancelLabel}
                </Button>
                <Button
                  colorScheme='red'
                  onClick={() => {
                    onClose();
                    handleDelete && handleDelete();
                  }}
                >
                  {deleteConfirmLabel}
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DeleteDialogue;
