import { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  ButtonGroup,
  useDisclosure
} from '@chakra-ui/react';

const SaveCancelDialogue = ({
  handleSave,
  handleStartCancel,
  handleDiscard,
  label = 'Discard Changes',
  msg = `Are you sure? You can't undo this action afterwards.`,
  cancelLabel = 'Cancel',
  keepLabel = 'Keep Editing',
  discardLabel = 'Discard',
  saveLabel = 'Save Changes'
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  return (
    <>
      <Button onClick={() => handleStartCancel(onOpen)} variant='outline'>
        {cancelLabel}
      </Button>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {label}
            </AlertDialogHeader>

            <AlertDialogBody>{msg}</AlertDialogBody>

            <AlertDialogFooter>
              <ButtonGroup spacing='4'>
                <Button ref={cancelRef} onClick={onClose}>
                  {keepLabel}
                </Button>
                {handleSave ? (
                  <Button
                    ref={cancelRef}
                    onClick={() => {
                      onClose();
                      handleSave();
                    }}
                  >
                    {saveLabel}
                  </Button>
                ) : null}
                <Button
                  colorScheme='red'
                  onClick={() => {
                    onClose();
                    handleDiscard && handleDiscard();
                  }}
                >
                  {discardLabel}
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default SaveCancelDialogue;
