import { combineReducers } from 'redux';
import adminUser from './adminUser';
import articles from './articles';
import cmsUsers from './cmsUsers';
import contactSubmissions from './contactSubmissions';
import customers from './customers';
import locations from './locations';
import products from './products';
import productFilters from './productFilters';
import productSearch from './productSearch';
import settings from './settings';
import siteContent from './siteContent';
import specials from './specials';
import tags from './tags';

export default combineReducers({
  adminUser,
  articles,
  cmsUsers,
  contactSubmissions,
  customers,
  locations,
  products,
  productFilters,
  productSearch,
  settings,
  siteContent,
  specials,
  tags
});
