export const ARTICLE_ADD_REQUEST = 'ARTICLE_ADD_REQUEST';
export const ARTICLE_ADD_RESPONSE = 'ARTICLE_ADD_RESPONSE';
export const ARTICLE_ADD_ERROR = 'ARTICLE_ADD_ERROR';
export const ARTICLES_LIST_REQUEST = 'ARTICLES_LIST_REQUEST';
export const ARTICLES_LIST_RESPONSE = 'ARTICLES_LIST_RESPONSE';
export const ARTICLES_LIST_ERROR = 'ARTICLES_LIST_ERROR';
export const ARTICLES_LIST_RESET = 'ARTICLES_LIST_RESET';
export const ARTICLE_GET_REQUEST = 'ARTICLE_GET_REQUEST';
export const ARTICLE_GET_RESPONSE = 'ARTICLE_GET_RESPONSE';
export const ARTICLE_GET_ERROR = 'ARTICLE_GET_ERROR';
export const ARTICLE_UPDATE_REQUEST = 'ARTICLE_UPDATE_REQUEST';
export const ARTICLE_UPDATE_RESPONSE = 'ARTICLE_UPDATE_RESPONSE';
export const ARTICLE_UPDATE_ERROR = 'ARTICLE_UPDATE_ERROR';
export const ARTICLE_DELETE_REQUEST = 'ARTICLE_DELETE_REQUEST';
export const ARTICLE_DELETE_RESPONSE = 'ARTICLE_DELETE_RESPONSE';
export const ARTICLE_DELETE_ERROR = 'ARTICLE_DELETE_ERROR';

const initialState = {
  isUpdating: false,
  isRequesting: false,
  lastListResponse: null,
  lastListError: null,
  lastGetResponse: null,
  lastGetError: null,
  lastUpdateResponse: null,
  lastUpdateError: null,
  page: {
    news: 1,
    blog: 1
  },
  count: {
    news: 0,
    blog: 0
  },
  pages: {
    news: 0,
    blog: 0
  },
  list: {
    news: [],
    blog: []
  }
};

const articles = (state = initialState, action) => {
  let list = { ...state.list };
  let page = { ...state.page };
  let pages = { ...state.pages };
  let count = { ...state.count };
  switch (action?.type) {
    case ARTICLES_LIST_REQUEST:
      return {
        ...state,
        isRequesting: true,
        lastListResponse: null,
        lastListError: null
      };
    case ARTICLES_LIST_RESPONSE: {
      const { articleType } = action;
      if (articleType) {
        list[articleType] = action?.data;
        page[articleType] = action?.page || 1;
        pages[articleType] = action?.pages || 0;
        count[articleType] = action?.count || 0;
        return {
          ...state,
          list,
          page,
          pages,
          count,
          isRequesting: false,
          lastListResponse: action?.response,
          lastListError: null
        };
      }
      return state;
    }
    case ARTICLES_LIST_ERROR: {
      const { articleType } = action;
      if (articleType) {
        list[articleType] = [];
        page[articleType] = 1;
        pages[articleType] = 0;
        count[articleType] = 0;
        return {
          ...state,
          list: [],
          page,
          pages,
          count,
          isRequesting: false,
          lastListResponse: null,
          lastListError: action?.data
        };
      }
      return state;
    }
    case ARTICLES_LIST_RESET: {
      const { articleType } = action;
      if (articleType) {
        list[articleType] = [];
        page[articleType] = 1;
        pages[articleType] = 0;
        count[articleType] = 0;
        return {
          ...state,
          isRequesting: false,
          lastListResponse: null,
          lastListError: null,
          list,
          page,
          pages,
          count
        };
      }
      return state;
    }
    case ARTICLE_GET_REQUEST:
      return {
        ...state,
        isRequesting: true,
        lastGetResponse: null,
        lastGetError: null
      };
    case ARTICLE_GET_RESPONSE: {
      const article = action?.data;
      const { type } = article;
      const existsIndex = list[type].findIndex(loc => loc._id === article.id);
      if (existsIndex > -1) {
        list[type][existsIndex] = article;
      } else {
        list[type].push(article);
        count[type] += 1;
      }
      return {
        ...state,
        list,
        count,
        isRequesting: false,
        lastGetResponse: article,
        lastGetError: null
      };
    }
    case ARTICLE_GET_ERROR:
      return {
        ...state,
        isRequesting: false,
        lastGetResponse: null,
        lastGetError: action?.data
      };

    case ARTICLE_ADD_REQUEST:
    case ARTICLE_UPDATE_REQUEST:
    case ARTICLE_DELETE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        lastUpdateResponse: null,
        lastUpdateError: null
      };
    case ARTICLE_ADD_RESPONSE:
      const newArticle = action?.data;
      if (newArticle) {
        const { type } = newArticle;
        list[type].push(newArticle);
        count[type] += 1;
      }
      return {
        ...state,
        list,
        count,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case ARTICLE_UPDATE_RESPONSE:
      const updatedArticle = action?.data;
      if (updatedArticle) {
        const { type } = updatedArticle;
        list[type] = list[type].map(article => {
          if (article._id === updatedArticle._id) {
            return updatedArticle;
          }
          return article;
        });
      }
      return {
        ...state,
        list,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case ARTICLE_DELETE_RESPONSE:
      const { id: deletedArticle, articleType } = action;
      if (deletedArticle) {
        list[articleType] = list[articleType].filter(article => article._id !== deletedArticle);
        count[articleType] -= 1;
        if (count[articleType] < 0) {
          count[articleType] = 0;
        }
      } else {
        console.error('delete response received, but no article in action.data??', action);
      }
      return {
        ...state,
        list,
        count,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case ARTICLE_ADD_ERROR:
    case ARTICLE_UPDATE_ERROR:
    case ARTICLE_DELETE_ERROR:
      return {
        ...state,
        isUpdating: false,
        lastUpdateResponse: null,
        lastUpdateError: action?.data
      };
    default:
      return state;
  }
};

export default articles;
