import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  // Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import useProducts from 'hooks/useProducts';
import { sortArrayByProp } from 'utils/sort';

import PaginationComponent from 'components/segments/Pagination';
import ErrorDialogue from 'components/segments/ErrorDialogue';
import { TableBox } from 'components/segments/StyledComponents';
import { getQueryFilter } from 'utils/request';
import SearchWidget from 'components/specials/SearchWidget';

const List = () => {
  const listOnLoad = useRef();
  const { products, currentPage, pages, isRequesting, lastUpdateError, getProductList } =
    useProducts();

  const [searchTerm, setSearchTerm] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (!listOnLoad.current) {
      listOnLoad.current = true;
      getProductList({ page: currentPage || 1 });
    }
  }, [currentPage, getProductList, products]);

  const errorDisplay = useMemo(() => {
    if (!products?.length) {
      const message =
        !listOnLoad.current || isRequesting ? 'Loading products...' : 'Sorry, no products found';
      return <Heading size='lg'>{message}</Heading>;
    }
    return null;
  }, [products?.length, isRequesting]);

  const handleGetProducts = (page, searchTerm) => {
    const params = { page, sort: { field: 'dutchieData.name', dir: 1 } };
    if (searchTerm) {
      params.filter = getQueryFilter({ search: searchTerm, fuzzy: true });
    }
    getProductList(params);
  };

  const pageChangeHandler = page => {
    handleGetProducts(page, searchTerm);
  };

  const searchTermChangeHandler = searchTerm => {
    handleGetProducts(1, searchTerm);
    setSearchTerm(searchTerm);
  };

  return (
    <Box>
      <Flex justifyContent='flex-end'>
        <Box width='260px' mt='8px'>
          <SearchWidget setSearchTerm={searchTermChangeHandler} />
        </Box>
      </Flex>
      {errorDisplay}
      {!errorDisplay && (
        <TableBox my='12px' border='1px solid #000'>
          <Table size='sm'>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Brand</Th>
                <Th>Category</Th>
                <Th>Pricing</Th>
                <Th>Enabled?</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {products
                .sort(sortArrayByProp(l => l.name || l.dutchieData.name.trim()))
                .map((l, idx) => {
                  const data = l.dutchieData;
                  return (
                    <Tr key={idx}>
                      <Td>{l.name || data.name}</Td>
                      <Td>{data.brand?.name || 'brand not listed'}</Td>
                      <Td>{data.category}</Td>
                      <Td>
                        {l.variants?.map((v, idx) => {
                          return (
                            <Box key={idx}>
                              {v.option}: ${v.price}
                            </Box>
                          );
                        })}
                      </Td>
                      <Td>{l.enabled ? 'x' : ''}</Td>
                      <Td>
                        <ButtonGroup spacing='3' size='xs'>
                          <Button onClick={() => navigate(`/products/edit/${l._id}`)}>Edit</Button>
                          <Button onClick={() => navigate(`/products/${l._id}`)}>View</Button>
                        </ButtonGroup>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </TableBox>
      )}
      <ErrorDialogue error={lastUpdateError} />
      {pages > 1 && (
        <PaginationComponent
          numPages={pages}
          page={currentPage}
          pageChangeHandler={pageChangeHandler}
        />
      )}
    </Box>
  );
};

export default List;
