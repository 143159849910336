export const SPECIAL_ADD_REQUEST = 'SPECIAL_ADD_REQUEST';
export const SPECIAL_ADD_RESPONSE = 'SPECIAL_ADD_RESPONSE';
export const SPECIAL_ADD_ERROR = 'SPECIAL_ADD_ERROR';
export const SPECIALS_LIST_REQUEST = 'SPECIALS_LIST_REQUEST';
export const SPECIALS_LIST_RESPONSE = 'SPECIALS_LIST_RESPONSE';
export const SPECIALS_LIST_ERROR = 'SPECIALS_LIST_ERROR';
export const SPECIALS_LIST_RESET = 'SPECIALS_LIST_RESET';
export const SPECIAL_GET_REQUEST = 'SPECIAL_GET_REQUEST';
export const SPECIAL_GET_RESPONSE = 'SPECIAL_GET_RESPONSE';
export const SPECIAL_GET_ERROR = 'SPECIAL_GET_ERROR';
export const SPECIAL_UPDATE_REQUEST = 'SPECIAL_UPDATE_REQUEST';
export const SPECIAL_UPDATE_RESPONSE = 'SPECIAL_UPDATE_RESPONSE';
export const SPECIAL_UPDATE_ERROR = 'SPECIAL_UPDATE_ERROR';
export const SPECIAL_DELETE_REQUEST = 'SPECIAL_DELETE_REQUEST';
export const SPECIAL_DELETE_RESPONSE = 'SPECIAL_DELETE_RESPONSE';
export const SPECIAL_DELETE_ERROR = 'SPECIAL_DELETE_ERROR';

const initialState = {
  isUpdating: false,
  isRequesting: false,
  lastListResponse: null,
  lastListError: null,
  lastGetResponse: null,
  lastGetError: null,
  lastUpdateResponse: null,
  lastUpdateError: null,
  page: {
    dog: 1,
    popular: 1
  },
  count: {
    dog: 0,
    popular: 0
  },
  pages: {
    dog: 0,
    popular: 0
  },
  list: {
    dog: [],
    popular: []
  }
};

const specials = (state = initialState, action) => {
  let list = { ...state.list };
  let page = { ...state.page };
  let pages = { ...state.pages };
  let count = { ...state.count };
  switch (action?.type) {
    case SPECIALS_LIST_REQUEST:
      return {
        ...state,
        isRequesting: true,
        lastListResponse: null,
        lastListError: null
      };
    case SPECIALS_LIST_RESPONSE: {
      const { specialType } = action;
      if (specialType) {
        list[specialType] = action?.data;
        page[specialType] = action?.page || 1;
        pages[specialType] = action?.pages || 0;
        count[specialType] = action?.count || 0;
        return {
          ...state,
          list,
          page,
          pages,
          count,
          isRequesting: false,
          lastListResponse: action?.response,
          lastListError: null
        };
      }
      return state;
    }
    case SPECIALS_LIST_ERROR: {
      const { specialType } = action;
      if (specialType) {
        list[specialType] = [];
        page[specialType] = 1;
        pages[specialType] = 0;
        count[specialType] = 0;
        return {
          ...state,
          list: [],
          page,
          pages,
          count,
          isRequesting: false,
          lastListResponse: null,
          lastListError: action?.data
        };
      }
      return state;
    }
    case SPECIALS_LIST_RESET: {
      const { specialType } = action;
      if (specialType) {
        list[specialType] = [];
        page[specialType] = 1;
        pages[specialType] = 0;
        count[specialType] = 0;
        return {
          ...state,
          isRequesting: false,
          lastListResponse: null,
          lastListError: null,
          list,
          page,
          pages,
          count
        };
      }
      return state;
    }
    case SPECIAL_GET_REQUEST:
      return {
        ...state,
        isRequesting: true,
        lastGetResponse: null,
        lastGetError: null
      };
    case SPECIAL_GET_RESPONSE: {
      const special = action?.data;
      const { type } = special;
      const existsIndex = list[type].findIndex(loc => loc._id === special.id);
      if (existsIndex > -1) {
        list[type][existsIndex] = special;
      } else {
        list[type].push(special);
        count[type] += 1;
      }
      return {
        ...state,
        list,
        count,
        isRequesting: false,
        lastGetResponse: special,
        lastGetError: null
      };
    }
    case SPECIAL_GET_ERROR:
      return {
        ...state,
        isRequesting: false,
        lastGetResponse: null,
        lastGetError: action?.data
      };

    case SPECIAL_ADD_REQUEST:
    case SPECIAL_UPDATE_REQUEST:
    case SPECIAL_DELETE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        lastUpdateResponse: null,
        lastUpdateError: null
      };
    case SPECIAL_ADD_RESPONSE:
      const newSpecial = action?.data;
      if (newSpecial) {
        const { type } = newSpecial;
        list[type].push(newSpecial);
        count[type] += 1;
      }
      return {
        ...state,
        list,
        count,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case SPECIAL_UPDATE_RESPONSE:
      const updatedSpecial = action?.data;
      if (updatedSpecial) {
        const { type } = updatedSpecial;
        list[type] = list[type].map(special => {
          if (special._id === updatedSpecial._id) {
            return updatedSpecial;
          }
          return special;
        });
      }
      return {
        ...state,
        list,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case SPECIAL_DELETE_RESPONSE:
      const { id: deletedSpecial, specialType } = action;
      if (deletedSpecial) {
        list[specialType] = list[specialType].filter(special => special._id !== deletedSpecial);
        count[specialType] -= 1;
        if (count[specialType] < 0) {
          count[specialType] = 0;
        }
      } else {
        console.error('delete response received, but no special in action.data??', action);
      }
      return {
        ...state,
        list,
        count,
        isUpdating: false,
        lastUpdateResponse: action?.response,
        lastUpdateError: null
      };
    case SPECIAL_ADD_ERROR:
    case SPECIAL_UPDATE_ERROR:
    case SPECIAL_DELETE_ERROR:
      return {
        ...state,
        isUpdating: false,
        lastUpdateResponse: null,
        lastUpdateError: action?.data
      };
    default:
      return state;
  }
};

export default specials;
