import axios from 'axios';

import {
  LOGIN_REQUEST,
  LOGIN_RESPONSE,
  REFRESH_RESPONSE,
  LOGIN_ERROR,
  LOGOUT
} from '../reducers/adminUser';

export const loginAdminUser = ({ email, password }) => {
  return async dispatch => {
    if (!email || !password) {
      console.error('missing email or password, cannot login');
      return;
    }
    dispatch({ type: LOGIN_REQUEST });
    try {
      const response = await axios.post('/auth/user/login', {
        email,
        password
      });
      dispatch({ type: LOGIN_RESPONSE, data: response?.data?.user || null });
    } catch (error) {
      dispatch({ type: LOGIN_ERROR, data: error });
    }
  };
};

export const loginStoreUser = ({ storecode, password }) => {
  return async dispatch => {
    if (!storecode || !password) {
      console.error('missing email or password, cannot login');
      return;
    }
    dispatch({ type: LOGIN_REQUEST });
    try {
      const response = await axios.post('/auth/user/login-store', {
        storecode,
        password
      });
      dispatch({ type: LOGIN_RESPONSE, data: response?.data?.user || null });
    } catch (error) {
      dispatch({ type: LOGIN_ERROR, data: error });
    }
  };
};

export const logoutAdminUser = () => {
  // just simple call back out for now to kill the session
  axios.post('/auth/user/logout');
  return { type: LOGOUT };
};

export const refreshAdminUser = () => {
  return async (dispatch, getState) => {
    console.log('user::refresh');
    // const state = getState().user;
    // if (state.isAuthorized || state.user) {
    //   return false;
    // }
    // if (state.isRequesting) {
    //   return false;
    // }
    dispatch({ type: LOGIN_REQUEST });

    try {
      const response = await axios.post('/auth/user/refresh');
      const { user /*, authorized*/ } = response?.data;
      dispatch({ type: REFRESH_RESPONSE, data: user });
    } catch (error) {
      dispatch({ type: LOGIN_ERROR, data: error });
    }

    // dispatch(userRequestEnd());

    // if (error) {
    // 	return dispatch(userLoadError(error));
    // }

    // const { user, authorized } = response?.data;

    // if (authorized && user) {
    // 	dispatch(userRefreshed({ user }));
    // } else {
    // 	dispatch(userLoadError('no user found after authorization'));
    // }
  };
};
