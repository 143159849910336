import axios from 'axios';

import {
  TAGS_LIST_REQUEST,
  TAGS_LIST_RESPONSE,
  TAGS_LIST_ERROR,
  TAGS_GET_REQUEST,
  TAGS_GET_RESPONSE,
  TAGS_GET_ERROR,
  TAGS_ADD_REQUEST,
  TAGS_ADD_RESPONSE,
  TAGS_ADD_ERROR,
  TAGS_UPDATE_REQUEST,
  TAGS_UPDATE_RESPONSE,
  TAGS_UPDATE_ERROR,
  TAGS_DELETE_REQUEST,
  TAGS_DELETE_RESPONSE,
  TAGS_DELETE_ERROR
} from 'redux/reducers/tags';

import { getQueryFilter } from 'utils/request';

export const getTagsList = ({ page = 1, limit, filter = {}, sort }) => {
  return async dispatch => {
    // dispatch, getState
    dispatch({ type: TAGS_LIST_REQUEST });
    try {
      const params = { page, limit, filter: getQueryFilter(filter) };
      if (sort) {
        params.sort = JSON.stringify(sort);
      }
      const response = await axios.get('/api/data/list/tags', {
        params
      });
      dispatch({
        type: TAGS_LIST_RESPONSE,
        data: response?.data?.list,
        pages: response?.data?.pages || 0,
        count: response?.data?.count || 0,
        // response,
        page
      });
    } catch (error) {
      dispatch({ type: TAGS_LIST_ERROR, data: error.message });
    }
  };
};

export const getTagById = id => {
  return async dispatch => {
    // dispatch, getState
    dispatch({ type: TAGS_GET_REQUEST });
    try {
      const response = await axios.get(`/api/data/get/tags/${id}`);
      dispatch({
        type: TAGS_GET_RESPONSE,
        data: response?.data
      });
    } catch (error) {
      dispatch({ type: TAGS_GET_ERROR, data: error.message });
    }
  };
};

export const saveData = ({ data, tag }) => {
  return async dispatch => {
    if (!data) {
      console.error('error, missing data, cannot update');
      return;
    }

    const params = {
      doc: true,
      data
    };
    let apiPathAction = 'add',
      requestAction = TAGS_ADD_REQUEST,
      responseAction = TAGS_ADD_RESPONSE,
      errorAction = TAGS_ADD_ERROR;
    if (tag) {
      params.id = tag;
      apiPathAction = 'update';
      requestAction = TAGS_UPDATE_REQUEST;
      responseAction = TAGS_UPDATE_RESPONSE;
      errorAction = TAGS_UPDATE_ERROR;
    }

    dispatch({ type: requestAction, tag });

    if (!data.name || !data.value) {
      // race condition with this error dispatch??? timeout=1 gives a tick
      setTimeout(() => {
        dispatch({
          type: errorAction,
          data: 'Missing required properties, make sure to add a name and a value.'
        });
      }, 1);
      return;
    }

    try {
      const response = await axios.post(`/api/data/${apiPathAction}/tags`, params);
      if (response?.data?.result) {
        dispatch({ type: responseAction, data: response?.data?.doc });
      } else {
        dispatch({ type: errorAction, data: 'failed to save the tags' });
      }
    } catch (error) {
      dispatch({
        type: errorAction,
        data: error?.response?.data?.message || 'unknown error'
      });
    }
  };
};

export const deleteTag = ({ id }) => {
  return async dispatch => {
    if (!id) {
      console.error('error, missing id, cannot delete');
      return;
    }
    console.log('ok try to delete tags', id);
    dispatch({ type: TAGS_DELETE_REQUEST, id });
    try {
      const response = await axios.delete(`/api/data/delete/tags/${id}`);
      console.log('delete tags response', response?.data);
      dispatch({ type: TAGS_DELETE_RESPONSE, id, data: response });
    } catch (error) {
      console.warn('error deleting, dispatch the error', error);
      dispatch({ type: TAGS_DELETE_ERROR, data: error });
    }
  };
};
