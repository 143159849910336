import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  getContentList,
  getSiteContentById,
  deleteContent,
  saveData,
  stashCopy,
  clearCopy
} from 'redux/actions/siteContent';
import { DEFAULT_LIST_LIMIT } from 'constants';

const useSiteContent = () => {
  const dispatch = useDispatch();

  const siteContent = useSelector(state => state.siteContent?.list, shallowEqual);
  const isRequesting = useSelector(state => state.siteContent?.isRequesting);
  const isUpdating = useSelector(state => state.siteContent?.isUpdating);
  const lastGetError = useSelector(state => state.siteContent?.lastGetError);
  const lastUpdateError = useSelector(state => state.siteContent?.lastUpdateError);
  const currentPage = useSelector(state => state.siteContent?.page, shallowEqual);
  const pages = useSelector(state => state.siteContent?.pages, shallowEqual);
  const count = useSelector(state => state.siteContent?.count, shallowEqual);

  const currentCopy = useSelector(state => state.siteContent?.copyData, shallowEqual);

  const getSiteContent = useCallback(
    (params = {}) => {
      if (!isRequesting) {
        const { page, limit = DEFAULT_LIST_LIMIT, filter, sort } = params;
        dispatch(getContentList({ page, limit, filter, sort }));
      }
    },
    [dispatch, isRequesting]
  );

  const getContentById = useCallback(
    id => {
      if (id && !isRequesting) {
        const mySiteContent = siteContent?.find(loc => loc._id === id);
        if (!mySiteContent) {
          dispatch(getSiteContentById(id));
        }
      }
    },
    [dispatch, isRequesting, siteContent]
  );

  const saveSiteContent = useCallback(
    ({ data, siteContent }) => {
      dispatch(saveData({ data, siteContent }));
    },
    [dispatch]
  );

  const copyData = useCallback(
    data => {
      const { _id, ...rest } = data;
      dispatch(stashCopy(rest));
    },
    [dispatch]
  );

  const clearCopyData = useCallback(() => {
    dispatch(clearCopy());
  }, [dispatch]);

  const deleteSiteContent = useCallback(
    ({ siteContent }) => {
      dispatch(deleteContent({ id: siteContent }));
    },
    [dispatch]
  );

  return {
    getSiteContent,
    getContentById,
    saveSiteContent,
    deleteSiteContent,
    copyData,
    clearCopyData,
    currentCopy,
    isRequesting,
    isUpdating,
    lastGetError,
    lastUpdateError,
    siteContent,
    currentPage,
    pages,
    count
  };
};

export default useSiteContent;
